import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { DataStore } from "aws-amplify";
import { School } from "../../models";
import { Alert } from "@mui/lab";
import { Cancel, WarningOutlined } from "@mui/icons-material";
import { tdb } from "../Services/translateJSON";

function SchoolDelete({ onClose, open, id }) {
  const { t, i18n } = useTranslation();
  const [school, setSchool] = useState("");

  const fetchSchool = async () => {
    const school = await DataStore.query(School, id);
    setSchool(school);
  };
  const [error, setError] = useState(false);

  useEffect(() => {
    if (id) {
      fetchSchool();
    }
  }, [id]);

  const handleDelete = () => {
    try {
      if (school) {
        DataStore.delete(school);
      }
      if (onClose) onClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{t("school.delete-school")}</DialogTitle>
      <DialogContent>
        {t("school.delete-info")} {school && tdb(school.title)}
        {error && (
          <Alert severity="error" square>
            {t("school.delete-error")}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<Cancel />}
          variant="contained"
          onClick={onClose}
          color="secondary"
        >
          {t("generic.Cancel")}
        </Button>
        <Button
          disabled={error}
          startIcon={<WarningOutlined />}
          onClick={handleDelete}
          variant="contained"
          color="warning"
        >
          {t("generic.del")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SchoolDelete;
