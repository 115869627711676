import React, { Fragment, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Snackbar,
  Switch,
  TextField,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useTranslation } from "react-i18next";
import { Cancel, Save } from "@mui/icons-material";
import { DataStore, Storage } from "aws-amplify";
import { Price,Product} from "../../models";
import { tdb } from "../Services/translateJSON";
import { t } from "i18next";

function PricesCreate({ open, onClose }) {
  const { t, i18n } = useTranslation();
  
  const [products, setProducts] = useState([]);
  const fetchProducts = async () => {
    setProducts(await DataStore.query(Product));
  };
  useEffect(() => {
    fetchProducts();
  }, [])
  
  const [form, setForm] = useState({
    title: "",
    description: "",
    amount: "",
    product:"",
  });
  const handleChange = (field, value) => {
    setForm((state) => ({ ...state, [field]: value }));
  };

  //SAVE
  const handleSave = async () => {
    const _form = {
      title: form.title,
      amount: parseInt(form.amount),
      description: form.description,
      ProductID: form.product,
    };
    try {
      const __form = new Price(_form);
      await DataStore.save(__form);
      console.log("Price saved successfully!");
      if (onClose) onClose();
    } catch (e) {
      console.log(e);
      console.log("Price Not  Saved!");
    }
  };
  return (
    <Fragment>
      <Dialog maxWidth={"lg"} open={true} onClose={onClose}>
        <DialogTitle>{t("Price.Add")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                select
                variant={"outlined"}
                label={t("service.title")}
                fullWidth
                value={form.product}
                onChange={(event) => handleChange("product", event.target.value)}
              >
                {products.map((prod) => (
                  <MenuItem value={prod.id}>{tdb(prod.title)}</MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t("generic.Titre")}
                variant="outlined"
                value={form.title}
                //   error={!!formErrors.title}
                //   helperText={formErrors.title}
                onChange={(event) => handleChange("title", event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                inputProps={{ type: "number" }}
                label={t("generic.Montant")}
                variant="outlined"
                value={form.amount}
                //   error={!!formErrors.title}
                //   helperText={formErrors.title}
                onChange={(event) => handleChange("amount", event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                value={form.description}
                label={t("generic.Desciption")}
                variant="outlined"
                id="standard-multiline-static"
                multiline
                rows={4}
                onChange={(event) =>
                  handleChange("description", event.target.value)
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<Cancel />}
            variant="contained"
            onClick={onClose}
            //onClick={()=>{window.location.reload(false)}}
            color="secondary"
          >
            {t("generic.Cancel")}
          </Button>
          <Button
            startIcon={<Save />}
            onClick={handleSave}
            variant="contained"
            color="primary"
          >
            {t("generic.Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default PricesCreate;
