import { API, graphqlOperation } from "aws-amplify";
import { getDraft, getDraftContent } from "../graphql/queries";
import {
  updateDraft as updateDraftMutation,
  deleteDraft as deleteDraftMutation,
  createDraftContent as createDraftContentMutation,
} from "../graphql/mutations";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";

export function useDraft(draftId) {
  const [loading, setLoading] = useState(false);
  const [draft, setDraft] = useState(null);

  const fetchDraft = useCallback(async (draftId) => {
    setLoading(true);
    const result = await API.graphql(
      graphqlOperation(getDraft, {
        id: draftId,
      })
    );
    setDraft(result.data.getDraft);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (draftId) {
      fetchDraft(draftId);
    }
  }, [draftId, fetchDraft]);

  const updateDraft = async (input) => {
    const response = await API.graphql({
      query: updateDraftMutation,
      variables: {
        input: {
          id: draftId,
          _version: draft._version,
          ...input,
        },
      },
    });
    setDraft(response.data.updateDraft);
  };

  const deleteDraft = async () => {
    const response = await API.graphql({
      query: deleteDraftMutation,
      variables: {
        input: {
          id: draftId,
          _version: draft._version,
        },
      },
    });
    console.log(response);
    setDraft(null);
  };

  const createDraftContent = async (input) => {
    const response = await API.graphql({
      query: createDraftContentMutation,
      variables: {
        input: {
          id: draftId,
        },
      },
    });
    console.log(response);
  };

  return {
    draft,
    loading,
    updateDraft,
    deleteDraft,
    createDraftContent,
  };
}
