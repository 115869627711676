import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataStore } from "aws-amplify";
import { Product } from "../../models";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import {
  Add,
  AddCircle,
  Close,
  DeleteForever,
  Edit,
  Search,
  VisibilitySharp,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
  Typography,
} from "@mui/material";
import { Route, Routes, useNavigate } from "react-router-dom";

import ProductsCreate from "./ProductsCreate";
import ProductsEdit from "./ProductsEdit";
import ProductsDelete from "./ProductsDelete";
import {tdb} from "../Services/translateJSON";
function ProductsList() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  //********Liste service********////
  const [Products, setProducts] = useState([]);
  const fetchProducts = async () => {
    setProducts(await DataStore.query(Product));
  };
  useEffect(() => {
    fetchProducts();
    // const productsSubscribes = DataStore.observe(Products).subscribe(() =>fetchProducts())
    // return () => {
    //   productsSubscribes.unsubscribe();
    // }
  }, [])

 

  const [deleteProduct, setDeleteProduct] = useState(false);
  const [editProduct, setEditProduct] = useState("");
  const [productId, setProductId] = useState("");

  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const handleCreate = () => setCreateMode(true);
  const handleCancelCreate = () => setCreateMode(false);

  const handleCancelEdit = () => {
    setEditProduct(null);
    setEditMode(false);
  };
  const handleDelete = (id) => {
    setProductId(id);
    setDeleteProduct(true);
  };

  const handleEdit = (id) => {
    setEditProduct(id);
    setEditMode(true);
  };

  //GRID PARAMS
  const _renderActions = (params) => {
    return (
      (1 || params.row === 0) && (
        <>
          <IconButton size={"small"} onClick={() => handleEdit(params.row.id)}>
            <Edit />
          </IconButton>
          <IconButton
            size={"small"}
            onClick={() => handleDelete(params.row.id)}
          >
            <DeleteForever />
          </IconButton>
        </>
      )
    );
  };
  const columns = [
    { field: "title", headerName: "service", flex: 1, hide: false },
    { field: "description", headerName: "Description", flex: 1 },
    {
      field: "action",
      headerName: "Actions",
      width: 100,
      align: "right",
      renderCell: _renderActions,
      disableColumnMenu: true,
    },
  ];
  return (
    <Fragment>
      <Card>
        <CardHeader
          title={<Typography variant={"h5"}>{t("service.list")}</Typography>}
        />
        <CardContent>
          <DataGrid
            density={"compact"}
            autoHeight
            rows={Products}
            columns={columns}
            onRowDoubleClick={(params) => navigate(params.row.id)}
          />
        </CardContent>
        <CardActions>
          <Button
            startIcon={<AddCircle />}
            className={classes.mt}
            variant="contained"
            onClick={() => setCreateMode(true)}
          >
            {t("generic.Add")}
          </Button>
          
          {createMode && (
            <ProductsCreate onClose={() => setCreateMode(false)} />
          )}
          {editMode && (
            <ProductsEdit onClose={handleCancelEdit} productId={editProduct} />
          )}
          <ProductsDelete
            open={deleteProduct}
            onClose={() => setDeleteProduct(false)}
            id={productId}
          />
        </CardActions>
      </Card>
    </Fragment>
  );
}
const useStyles = makeStyles(() => ({
  mt: {
    marginTop: 10,
  },
}));
export default ProductsList;
