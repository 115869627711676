import { API, graphqlOperation } from "aws-amplify";
import { getBookContent } from "../graphql/queries";

import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import localStorage from "local-storage";

const CACHE_KEY = "BookContent_";

export function useBookContent(contentId) {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(null);

  const loadExistingData = (id) => {
    const value = localStorage.get(`${CACHE_KEY}${id}`);
    return value ? JSON.parse(value) : null;
  };

  const fetchDraftContent = useCallback(async (id) => {
    setLoading(true);
    let data = await loadExistingData(id);
    setContent(data);
    const result = await API.graphql(
      graphqlOperation(getBookContent, {
        id,
      })
    );
    data = result.data.getBookContent;
    localStorage.set(`${CACHE_KEY}${id}`, JSON.stringify(data));
    setContent(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (contentId) {
      fetchDraftContent(contentId);
    }
  }, [contentId, fetchDraftContent]);

  return {
    content,
    loading,
  };
}
