import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DataStore} from "aws-amplify";
import {Book, Category, Family, SubCategory} from "../../models"
import {DataGrid} from "@mui/x-data-grid";
import {AddCircle, DeleteForever, Edit, VisibilitySharp} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {Box, Button, Card, CardActions, CardContent, CardHeader, MenuItem, TextField, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import CategoryCreate from "./CategoryCreate";
import {tdb} from "../Services/translateJSON";
import CategoryEdit from "./CategoryEdit";
import CategoryDelete from "./CategoryDelete";
import SubCategoryCreate from "./SubCategoryCreate";
import SubCategoryEdit from "./SubCategoryEdit";

export default function SubCategoryList() {
    const {t, i18n} = useTranslation()
    const classes = useStyles();
    
    
    //MODE
    const [createMode, setCreateMode] = useState(false);
    const handleCreate = () => setCreateMode(true);
    const handleCancelCreate = () => setCreateMode(false);
    
    const [editMode, setEditMode] = useState(false);
    const [editCat, setEditCat] = useState("");
    const handleEdit = (id) => {
        setEditCat(id)
        setEditMode(true);
    }
    const handleCancelEdit = () => {
        setEditCat(null);
        setEditMode(false)
    }
    
    const [deleteCat, setDeleteCat] = useState(false);
    const [catId, setCatId] = useState("");
    
    const handleDelete= (id) => {
        setCatId(id);
        setDeleteCat(true)
    }
    
    // BOOKS
    const [books, setBooks] = useState([]);
    const fetchBooks = async () => setBooks((await DataStore.query(Book)))
    // FAMILIES
    const [families, setFamilies] = useState([]);
    const fetchFamilies = async () => setFamilies(await DataStore.query(Family))
    //CATEGORIES
    const [categories, setCategories] = useState([]);
    const fetchCategories = async () => setCategories(await DataStore.query(Category));
    //SUBCATEGORIES
    const [subCategories, setSubCategories] = useState([]);
    const fetchSubCategories = async () => setSubCategories(await DataStore.query(SubCategory));
    useEffect(()=>{
        fetchBooks();
        fetchFamilies();
        fetchCategories();
        fetchSubCategories();
        const obsBook = DataStore.observe(Book).subscribe(()=>fetchBooks())
        const obsFamily = DataStore.observe(Family).subscribe(()=>fetchFamilies())
        const obsCategory = DataStore.observe(Category).subscribe(()=>fetchCategories())
        const obsSubCategory = DataStore.observe(SubCategory).subscribe(()=>fetchSubCategories())
        return () => {
            obsBook.unsubscribe();
            obsFamily.unsubscribe();
            obsCategory.unsubscribe();
            obsSubCategory.unsubscribe();
        }
    }, [])
    
    //GRID PARAMS
    const _renderActions = (params) => {
        return (1 || params.row === 0) && <>
            <IconButton size={"small"} onClick={() => handleEdit(params.row.id)}><Edit /></IconButton>
            <IconButton size={"small"} onClick={() => handleDelete(params.row.id)}><DeleteForever/></IconButton>
        </>;
    }
    
    const _renderBooks = (params) => {
        return books.filter(book=>book.category.id===params.row.id).length || ""
    }
    
    const columns = [
        {field:'id', headerName: "id", flex:1, hide: true },
        {field:'title', headerName: "titre", flex:1,  renderCell: (params => (tdb(params.value.default)))  },
        {field:'description', headerName: "description", flex:1, hide: true, renderCell: (params => (tdb(params.value)))  },
        {field:'family', headerName: "famille",  flex:1, renderCell: (params => (tdb(params.row.family.title)))   },
        {field:'category', headerName: "category",  flex:1, renderCell: (params => (tdb(params.row.category.title)))   },
        {field:'books', headerName: "Books",  width:70, renderCell: _renderBooks   },
        {field:'active', headerName: "Activé", width: 70, renderCell: (params)=>(params.row.active === true ? `${t('generic.Yes')}` : `${t('generic.No')}`)},
        {field:'action', headerName: 'Actions', width: 100, align: 'right', renderCell: _renderActions, disableColumnMenu: true }
    ]
    
    const [rows, setRows] = useState([]);
    const [family, setFamily] = useState("none");
    const [category, setCategory] = useState("none");
    
    useEffect(()=>{
        setRows(subCategories
            .filter(c=>family!=="none" ? c.family.id===family : true)
            .filter(c=>category!=="none" ? c.category.id===category : true)
        )
    }, [subCategories, family, category])
    
    useEffect(()=>{
        if (family === "none") {
            setCategory("none")
        }
        else if (category) {
            const _cat = categories.find(c=>c.id===category);
            if (_cat && _cat.family && _cat.family.id !== family) {
                setCategory("none")
            }
        }
    }, [family])
    
    return  <Card>
        <CardHeader
            title={<Typography variant={"h5"}>{t("subcategory.List")}</Typography> }
            action={
                <Box justifyContent={"center"} display={"flex"} flexDirection={"row"} >
                    <TextField sx={{minWidth: 250}} label={"famille"} size={"small"} value={family} onChange={event=>setFamily(event.target.value)} select>
                        <MenuItem value={"none"} key={"none"}>{t("subcategory.AllFamilies")}</MenuItem>
                        {families.map(family=><MenuItem value={family.id} key={family.id}>{tdb(family.title)}</MenuItem>)}
                    </TextField>
                    <Typography p={1}>/</Typography>
                    <TextField sx={{minWidth: 250}} label={"categorie"} size={"small"} value={category} onChange={event=>setCategory(event.target.value)} select>
                        <MenuItem value={"none"} key={"none"}>{t("subcategory.AllCategories")}</MenuItem>
                        {categories
                            .filter(c=>{
                                return (family ? c.family.id === family : true)
                            })
                            .map(category=><MenuItem key={category.id} value={category.id}>{tdb(category.title)}</MenuItem>)}
                    </TextField>
                </Box>}
        />
        <CardContent>
            <DataGrid
                density={"compact"}
                autoHeight
                rows={rows}
                columns={columns}
            />
        </CardContent>
        <CardActions>
            <Button startIcon={<AddCircle/>} className={classes.mt} variant="contained" onClick={()=>setCreateMode(true)}>{t("generic.Add")}</Button>
            {createMode && <SubCategoryCreate onClose={()=>setCreateMode(false)} />}
            {editMode && <SubCategoryEdit onClose={handleCancelEdit} subCatId={editCat}/>}
            <CategoryDelete open={deleteCat} onClose={()=>setDeleteCat(false)} id={catId} />
        </CardActions>
    </Card>
}

const useStyles = makeStyles(() => ({
    mt: {
        marginTop: 10,
    }
}))
