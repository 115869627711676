import React, { useEffect, useState } from "react";
import Amplify, { DataStore, Storage } from "aws-amplify";
import { AmplifyS3Album } from "@aws-amplify/ui-react/legacy";
import { AmplifyS3Image } from "@aws-amplify/ui-react/legacy";
import { AmplifyS3ImagePicker } from "@aws-amplify/ui-react/legacy";
import awsconfig from "../../../aws-exports";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import FieldSet from "../../Misc/FieldSet";
import { InsertPhoto, Upload } from "@mui/icons-material";
import prettySize from "../../Services/prettySize";
import { makeStyles, styled } from "@mui/styles";
import { Draft } from "../../../models";
import awsExports from "../../../aws-exports";

Amplify.configure(awsconfig);

export default function BookCoverUpload({ open, onClose, draftId }) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [draft, setDraft] = useState("");

  const [fileReading, setFileReading] = useState("");
  const handleFile = async (e) => setFileReading(e.target.files[0]);

  const fetchDraft = async () => {
    setDraft(await DataStore.query(Draft, draftId));
  };

  useEffect(() => {
    if (draftId) fetchDraft();
  }, [draftId]);

  const handleUpload = async () => {
    if (fileReading) {
      const name = draftId;
      const result = await Storage.put(name, fileReading, {
        level: "public",
        contentType: fileReading.type,
      });
      try {
        DataStore.save(
          Draft.copyOf(draft, (updater) => {
            updater.cover = JSON.stringify({ default: fileReading.name });
            updater.coverFile = {
              region: awsExports.aws_user_files_s3_bucket_region,
              bucket: awsExports.aws_user_files_s3_bucket,
              key: result.key,
            };
          })
        );
      } catch (e) {
        console.log(e);
      }
      if (onClose) onClose();
    }
  };
  return (
    <Dialog open={open} maxWidth={"md"} fullWidth onClose={onClose}>
      <DialogTitle>{t("book.upload.coverSection")}</DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <FieldSet title={t("book.content.fileds.cover")}>
            <Typography variant={"body1"}>
              {t("book.content.fields.cover-file")}
            </Typography>
            <Grid container flexDirection={"row"} mt={1}>
              <Grid>
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    onChange={handleFile}
                  />
                  <Button
                    variant="contained"
                    color={"secondary"}
                    component="span"
                    startIcon={<InsertPhoto />}
                    fullWidth
                  >
                    {fileReading
                      ? t("book.content.operations.file-change")
                      : t("book.content.operations.file-choose")}
                  </Button>
                </label>
              </Grid>
              {fileReading && (
                <Grid item flexGrow={1} p={1}>
                  {fileReading.name}
                </Grid>
              )}
              {fileReading && (
                <Grid item p={1}>
                  {prettySize(fileReading.size)}
                </Grid>
              )}
            </Grid>
          </FieldSet>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Button
            className={classes.marginTopBtn}
            variant="contained"
            color="primary"
            onClick={handleUpload}
            startIcon={<Upload />}
          >
            {t("book.content.operations.upload")}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

const Input = styled("input")({
  display: "none",
});

const useStyles = makeStyles(() => ({
  marginTopBtn: {
    marginTop: 10,
  },
}));
