import React, {useState} from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ProductsList from "./ProductsList";
import PricesList from "../Prices/PricesList";

export default function ProductsLayout() {

    const [value, setValue] = useState('services');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Services" value="services" />
                        <Tab label="Tarifs" value="tarifs" />
                    </TabList>
                </Box>
                <TabPanel value="services">
                    <ProductsList />
                </TabPanel>
                <TabPanel value="tarifs">
                    <PricesList/>
                </TabPanel>
            </TabContext>
        </Box>
    );
}