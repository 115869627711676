import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Button,
    RadioGroup, FormControlLabel, Radio, Checkbox
} from "@mui/material";
import {Cancel, Save} from "@mui/icons-material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {AuthorUser} from "../../models";
import moment from "moment";
import {Auth, DataStore} from "aws-amplify";


export default function AuthorUserEdit({onClose, authorUserId}) {
    const {t, i18n} = useTranslation();
    
    const [authorUser, setAuthorUser] = useState();
    const fetchAuthorUser = async () => {
        setAuthorUser(await DataStore.query(AuthorUser, authorUserId))
    }
    useEffect(()=>{
        if (authorUserId) {
            fetchAuthorUser()
            const authorUserSubscribe = DataStore.observe(AuthorUser, authorUserId).subscribe(msg=>fetchAuthorUser())
            return () => authorUserSubscribe.unsubscribe();
        }
    }, [authorUserId])
    
    const [form, setForm] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        cellPhone: "",
        address: "",
        city: "",
        zip: "",
        gender:"",
        email:"",
        validated: false,
        accessDocumentation: false,
        setOtherAuthor: false,
        unsubscribe: false
    });

    const [birthday, setBirthday] = useState("");

    useEffect(()=>{
        if(authorUser) {
            setForm({
                firstName: authorUser.firstName,
                lastName: authorUser.lastName,
                phone: authorUser.phone,
                address: authorUser.address,
                city: authorUser.city,
                zip: authorUser.zip,
                gender: authorUser.gender,
                email: authorUser.email,
                validated: authorUser.validated,
                accessDocumentation: authorUser.accessDocumentation,
                setOtherAuthor: authorUser.setOtherAuthor,
                unsubscribe: authorUser.unsubscribe,
            });
            // setBirthday(moment(authorUser.birthday).toISOString());
            setBirthday(authorUser.birthday);
        }
    }, [authorUser])

    const handleUpdate = async () => {
        try {
            await DataStore.save(AuthorUser.copyOf(authorUser, updated => {
                updated.firstName = form.firstName;
                updated.lastName = form.lastName;
                updated.address = form.address;
                updated.phone = form.phone;
                updated.city = form.city;
                updated.zip = form.zip;
                updated.gender = form.gender;
                updated.validated = form.validated;
                updated.accessDocumentation = form.accessDocumentation;
                updated.setOtherAuthor = form.setOtherAuthor;
                updated.unsubscribe = form.unsubscribe;
                updated.status = form.unsubscribe ? "Unsubscribe" : (form.validated ? "Validated" : "");
                updated.birthday = moment(birthday).toISOString();
            }))
            if(onClose) onClose()
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <Dialog open={true} onClose={onClose} fullWidth maxWidth="lg">
            <DialogTitle>{t('authorUser.editUser')}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{pt: 2}}>
                    <Grid item xs={12} sm={4}>
                        {authorUser && authorUser.status}
                        <TextField
                            fullWidth
                            value={form && form.firstName}
                            label={t('authorUser.form.firstname')}
                            variant="outlined"
                            onChange={event => setForm( state => ({...state, firstName: event.target.value}))}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            value={form && form.lastName}
                            label={t('authorUser.form.lastname')}
                            variant="outlined"
                            onChange={event => setForm( state => ({...state, lastName: event.target.value}))}
                            />
                    </Grid>
                    <Grid item xs={12} sm={4} alignItems={"center"} justifyContent={"center"}>
                        <RadioGroup sx={{padding: 2}} row value={form.gender} defaultValue={form && form.gender} onChange={event => setForm( state => ({...state, gender: event.target.value}))}>
                            <FormControlLabel control={<Radio sx={{paddingY: 0}} />} value={t('male')} label={t('authorUser.form.male')} />
                            <FormControlLabel control={<Radio sx={{paddingY: 0}} />} value={t('female')} label={t('authorUser.form.female')} />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                            <DatePicker
                                label={t("authorUser.form.birthday")}
                                views={["day", "month", "year"]}
                                value={birthday}
                                onChange={(newValue) => {
                                    setBirthday(newValue);
                                }}
                                renderInput={(params) => <TextField fullWidth {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            value={form && form.phone}
                            label={t('authorUser.form.phone')}
                            variant="outlined"
                            onChange={event => setForm( state => ({...state, phone: event.target.value}))}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            disabled
                            value={form && form.email}
                            label={t('authorUser.form.email')}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            value={form && form.Address}
                            label={t('authorUser.form.address')}
                            variant="outlined"
                            onChange={event => setForm( state => ({...state, address: event.target.value}))}
                        />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <TextField
                            fullWidth
                            value={form && form.zip}
                            label={t('authorUser.form.zip')}
                            variant="outlined"
                            onChange={event => setForm( state => ({...state, zip: event.target.value}))}
                        />
                    </Grid>
                    <Grid item xs={8} sm={4}>
                        <TextField
                            fullWidth
                            value={form && form.city}
                            label={t('authorUser.form.city')}
                            variant="outlined"
                            onChange={event => setForm( state => ({...state, city: event.target.value}))}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            onChange={event => setForm(state => ({...state, validated: event.target.checked}))}
                            control={<Checkbox checked={form && form.validated} />}
                            label={"Validé"}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            
                            onChange={event => setForm(state => ({...state, accessDocumentation: event.target.checked}))}
                            control={<Checkbox checked={form && form.accessDocumentation} />}
                            label={"Accède aux catégories documentation"}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            onChange={event => setForm(state => ({...state, setOtherAuthor: event.target.checked}))}
                            control={<Checkbox checked={form && form.setOtherAuthor} />}
                            label={"Accède à tous les auteurs publics"}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            onChange={event => setForm(state => ({...state, unsubscribe: event.target.checked}))}
                            control={<Checkbox checked={form && form.unsubscribe} />}
                            label={"Désinscrit"}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button startIcon={<Cancel />} variant="contained"onClick={onClose} color={"secondary"}>{t("generic.cancel")}</Button>
                <Button startIcon={<Save />} onClick={handleUpdate} variant="contained">{t("generic.update")}</Button>
            </DialogActions>
        </Dialog>
    )
}
;