import React, {useState} from "react";
import {Route, Routes, useParams} from "react-router-dom";
import ReaderUserList from "./ReaderUserList";
import ReaderUserDetail from "./ReaderUserDetail";


export default function ReaderUsers() {
    return (
        <Routes>
            <Route path={""} element={<ReaderUserList />} />
            <Route path={":readerId/*"} element={<ReaderUserDetail />} />
        </Routes>
    )
}