/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAuthorUser = /* GraphQL */ `
  query GetAuthorUser($id: ID!) {
    getAuthorUser(id: $id) {
      id
      validated
      sub
      firstName
      lastName
      birthday
      email
      accessDocumentation
      setOtherAuthor
      unsubscribe
      unsubscribeData
      identity
      phone
      cellPhone
      gender
      address
      allowDoc
      city
      zip
      status
      authors {
        items {
          id
          sub
          name
          firstName
          email
          gender
          birthdate
          avatar
          avatarKeys
          avatarTemp
          avatarData
          biography
          homeWeight
          familyWeight
          categoryWeight
          followCount
          status
          authorUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      drafts {
        items {
          id
          sub
          title
          description
          summary
          cover
          coverKeys
          coverTemp
          coverData
          epub
          epubTempURL
          freeEpub
          epubData
          freeEpubData
          status
          bookType
          history
          price
          isbn
          content
          audioPreview
          authorUserID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAuthorUsers = /* GraphQL */ `
  query ListAuthorUsers(
    $id: ID
    $filter: ModelAuthorUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAuthorUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        validated
        sub
        firstName
        lastName
        birthday
        email
        accessDocumentation
        setOtherAuthor
        unsubscribe
        unsubscribeData
        identity
        phone
        cellPhone
        gender
        address
        allowDoc
        city
        zip
        status
        authors {
          nextToken
          startedAt
        }
        drafts {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAuthorUsers = /* GraphQL */ `
  query SyncAuthorUsers(
    $filter: ModelAuthorUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAuthorUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        validated
        sub
        firstName
        lastName
        birthday
        email
        accessDocumentation
        setOtherAuthor
        unsubscribe
        unsubscribeData
        identity
        phone
        cellPhone
        gender
        address
        allowDoc
        city
        zip
        status
        authors {
          nextToken
          startedAt
        }
        drafts {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAuthor = /* GraphQL */ `
  query GetAuthor($id: ID!) {
    getAuthor(id: $id) {
      id
      sub
      name
      firstName
      email
      gender
      birthdate
      avatar
      avatarKeys
      avatarTemp
      avatarData
      biography
      homeWeight
      familyWeight
      categoryWeight
      followCount
      status
      authorUserID
      authorUser {
        id
        validated
        sub
        firstName
        lastName
        birthday
        email
        accessDocumentation
        setOtherAuthor
        unsubscribe
        unsubscribeData
        identity
        phone
        cellPhone
        gender
        address
        allowDoc
        city
        zip
        status
        authors {
          nextToken
          startedAt
        }
        drafts {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      drafts {
        items {
          id
          sub
          title
          description
          summary
          cover
          coverKeys
          coverTemp
          coverData
          epub
          epubTempURL
          freeEpub
          epubData
          freeEpubData
          status
          bookType
          history
          price
          isbn
          content
          audioPreview
          authorUserID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      books {
        items {
          id
          sub
          title
          description
          summary
          homeWeight
          familyWeight
          categoryWeight
          cover
          coverKeys
          coverData
          isbn
          epub
          freeEpub
          epubData
          freeEpubData
          adminEpub
          adminEpubData
          status
          bookType
          rating
          ratingCount
          likesCount
          purchasedCount
          purchasedAmount
          price
          discount
          contents
          audioPreview
          draftID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      followAuthors {
        items {
          id
          sub
          authorSub
          like
          status
          readerUserID
          authorID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      notifyFollowers {
        items {
          id
          sub
          authorSub
          status
          readerUserID
          authorID
          bookID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAuthors = /* GraphQL */ `
  query ListAuthors(
    $id: ID
    $filter: ModelAuthorFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAuthors(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sub
        name
        firstName
        email
        gender
        birthdate
        avatar
        avatarKeys
        avatarTemp
        avatarData
        biography
        homeWeight
        familyWeight
        categoryWeight
        followCount
        status
        authorUserID
        authorUser {
          id
          validated
          sub
          firstName
          lastName
          birthday
          email
          accessDocumentation
          setOtherAuthor
          unsubscribe
          unsubscribeData
          identity
          phone
          cellPhone
          gender
          address
          allowDoc
          city
          zip
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        drafts {
          nextToken
          startedAt
        }
        books {
          nextToken
          startedAt
        }
        followAuthors {
          nextToken
          startedAt
        }
        notifyFollowers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAuthors = /* GraphQL */ `
  query SyncAuthors(
    $filter: ModelAuthorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAuthors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sub
        name
        firstName
        email
        gender
        birthdate
        avatar
        avatarKeys
        avatarTemp
        avatarData
        biography
        homeWeight
        familyWeight
        categoryWeight
        followCount
        status
        authorUserID
        authorUser {
          id
          validated
          sub
          firstName
          lastName
          birthday
          email
          accessDocumentation
          setOtherAuthor
          unsubscribe
          unsubscribeData
          identity
          phone
          cellPhone
          gender
          address
          allowDoc
          city
          zip
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        drafts {
          nextToken
          startedAt
        }
        books {
          nextToken
          startedAt
        }
        followAuthors {
          nextToken
          startedAt
        }
        notifyFollowers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDraft = /* GraphQL */ `
  query GetDraft($id: ID!) {
    getDraft(id: $id) {
      id
      sub
      title
      description
      summary
      cover
      coverFile {
        bucket
        region
        key
      }
      coverKeys
      coverTemp
      coverData
      epub
      epubFile {
        bucket
        region
        key
      }
      epubTempURL
      freeEpub
      epubData
      freeEpubData
      freeEpubFile {
        bucket
        region
        key
      }
      status
      bookType
      history
      price
      isbn
      content
      audioPreview
      authorUserID
      authorUser {
        id
        validated
        sub
        firstName
        lastName
        birthday
        email
        accessDocumentation
        setOtherAuthor
        unsubscribe
        unsubscribeData
        identity
        phone
        cellPhone
        gender
        address
        allowDoc
        city
        zip
        status
        authors {
          nextToken
          startedAt
        }
        drafts {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      authorID
      author {
        id
        sub
        name
        firstName
        email
        gender
        birthdate
        avatar
        avatarKeys
        avatarTemp
        avatarData
        biography
        homeWeight
        familyWeight
        categoryWeight
        followCount
        status
        authorUserID
        authorUser {
          id
          validated
          sub
          firstName
          lastName
          birthday
          email
          accessDocumentation
          setOtherAuthor
          unsubscribe
          unsubscribeData
          identity
          phone
          cellPhone
          gender
          address
          allowDoc
          city
          zip
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        drafts {
          nextToken
          startedAt
        }
        books {
          nextToken
          startedAt
        }
        followAuthors {
          nextToken
          startedAt
        }
        notifyFollowers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      draftContents {
        items {
          id
          sub
          order
          label
          title
          freeText
          freeAudio
          freeAudioData
          adminAudioData
          text
          audio
          audioData
          audioTempURL
          draftID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      familyID
      family {
        id
        name
        title
        description
        icon
        iconKeys
        content
        parameters
        appVersion
        active
        drafts {
          nextToken
          startedAt
        }
        books {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      categoryID
      category {
        id
        name
        title
        description
        content
        order
        parameters
        familyID
        family {
          id
          name
          title
          description
          icon
          iconKeys
          content
          parameters
          appVersion
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        active
        drafts {
          nextToken
          startedAt
        }
        books {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subCategoryID
      subCategory {
        id
        name
        title
        description
        content
        order
        parameters
        active
        familyID
        family {
          id
          name
          title
          description
          icon
          iconKeys
          content
          parameters
          appVersion
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryID
        category {
          id
          name
          title
          description
          content
          order
          parameters
          familyID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        drafts {
          nextToken
          startedAt
        }
        books {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      books {
        items {
          id
          sub
          title
          description
          summary
          homeWeight
          familyWeight
          categoryWeight
          cover
          coverKeys
          coverData
          isbn
          epub
          freeEpub
          epubData
          freeEpubData
          adminEpub
          adminEpubData
          status
          bookType
          rating
          ratingCount
          likesCount
          purchasedCount
          purchasedAmount
          price
          discount
          contents
          audioPreview
          draftID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDrafts = /* GraphQL */ `
  query ListDrafts(
    $id: ID
    $filter: ModelDraftFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDrafts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sub
        title
        description
        summary
        cover
        coverFile {
          bucket
          region
          key
        }
        coverKeys
        coverTemp
        coverData
        epub
        epubFile {
          bucket
          region
          key
        }
        epubTempURL
        freeEpub
        epubData
        freeEpubData
        freeEpubFile {
          bucket
          region
          key
        }
        status
        bookType
        history
        price
        isbn
        content
        audioPreview
        authorUserID
        authorUser {
          id
          validated
          sub
          firstName
          lastName
          birthday
          email
          accessDocumentation
          setOtherAuthor
          unsubscribe
          unsubscribeData
          identity
          phone
          cellPhone
          gender
          address
          allowDoc
          city
          zip
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        authorID
        author {
          id
          sub
          name
          firstName
          email
          gender
          birthdate
          avatar
          avatarKeys
          avatarTemp
          avatarData
          biography
          homeWeight
          familyWeight
          categoryWeight
          followCount
          status
          authorUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        draftContents {
          nextToken
          startedAt
        }
        familyID
        family {
          id
          name
          title
          description
          icon
          iconKeys
          content
          parameters
          appVersion
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryID
        category {
          id
          name
          title
          description
          content
          order
          parameters
          familyID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subCategoryID
        subCategory {
          id
          name
          title
          description
          content
          order
          parameters
          active
          familyID
          categoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        books {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDrafts = /* GraphQL */ `
  query SyncDrafts(
    $filter: ModelDraftFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDrafts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sub
        title
        description
        summary
        cover
        coverFile {
          bucket
          region
          key
        }
        coverKeys
        coverTemp
        coverData
        epub
        epubFile {
          bucket
          region
          key
        }
        epubTempURL
        freeEpub
        epubData
        freeEpubData
        freeEpubFile {
          bucket
          region
          key
        }
        status
        bookType
        history
        price
        isbn
        content
        audioPreview
        authorUserID
        authorUser {
          id
          validated
          sub
          firstName
          lastName
          birthday
          email
          accessDocumentation
          setOtherAuthor
          unsubscribe
          unsubscribeData
          identity
          phone
          cellPhone
          gender
          address
          allowDoc
          city
          zip
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        authorID
        author {
          id
          sub
          name
          firstName
          email
          gender
          birthdate
          avatar
          avatarKeys
          avatarTemp
          avatarData
          biography
          homeWeight
          familyWeight
          categoryWeight
          followCount
          status
          authorUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        draftContents {
          nextToken
          startedAt
        }
        familyID
        family {
          id
          name
          title
          description
          icon
          iconKeys
          content
          parameters
          appVersion
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryID
        category {
          id
          name
          title
          description
          content
          order
          parameters
          familyID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subCategoryID
        subCategory {
          id
          name
          title
          description
          content
          order
          parameters
          active
          familyID
          categoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        books {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDraftContent = /* GraphQL */ `
  query GetDraftContent($id: ID!) {
    getDraftContent(id: $id) {
      id
      sub
      order
      label
      title
      freeText
      freeAudio
      freeAudioData
      adminAudioData
      text
      audio
      audioFile {
        bucket
        region
        key
      }
      audioData
      audioTempURL
      draftID
      draft {
        id
        sub
        title
        description
        summary
        cover
        coverFile {
          bucket
          region
          key
        }
        coverKeys
        coverTemp
        coverData
        epub
        epubFile {
          bucket
          region
          key
        }
        epubTempURL
        freeEpub
        epubData
        freeEpubData
        freeEpubFile {
          bucket
          region
          key
        }
        status
        bookType
        history
        price
        isbn
        content
        audioPreview
        authorUserID
        authorUser {
          id
          validated
          sub
          firstName
          lastName
          birthday
          email
          accessDocumentation
          setOtherAuthor
          unsubscribe
          unsubscribeData
          identity
          phone
          cellPhone
          gender
          address
          allowDoc
          city
          zip
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        authorID
        author {
          id
          sub
          name
          firstName
          email
          gender
          birthdate
          avatar
          avatarKeys
          avatarTemp
          avatarData
          biography
          homeWeight
          familyWeight
          categoryWeight
          followCount
          status
          authorUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        draftContents {
          nextToken
          startedAt
        }
        familyID
        family {
          id
          name
          title
          description
          icon
          iconKeys
          content
          parameters
          appVersion
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryID
        category {
          id
          name
          title
          description
          content
          order
          parameters
          familyID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subCategoryID
        subCategory {
          id
          name
          title
          description
          content
          order
          parameters
          active
          familyID
          categoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        books {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDraftContents = /* GraphQL */ `
  query ListDraftContents(
    $id: ID
    $filter: ModelDraftContentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDraftContents(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sub
        order
        label
        title
        freeText
        freeAudio
        freeAudioData
        adminAudioData
        text
        audio
        audioFile {
          bucket
          region
          key
        }
        audioData
        audioTempURL
        draftID
        draft {
          id
          sub
          title
          description
          summary
          cover
          coverKeys
          coverTemp
          coverData
          epub
          epubTempURL
          freeEpub
          epubData
          freeEpubData
          status
          bookType
          history
          price
          isbn
          content
          audioPreview
          authorUserID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDraftContents = /* GraphQL */ `
  query SyncDraftContents(
    $filter: ModelDraftContentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDraftContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sub
        order
        label
        title
        freeText
        freeAudio
        freeAudioData
        adminAudioData
        text
        audio
        audioFile {
          bucket
          region
          key
        }
        audioData
        audioTempURL
        draftID
        draft {
          id
          sub
          title
          description
          summary
          cover
          coverKeys
          coverTemp
          coverData
          epub
          epubTempURL
          freeEpub
          epubData
          freeEpubData
          status
          bookType
          history
          price
          isbn
          content
          audioPreview
          authorUserID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBook = /* GraphQL */ `
  query GetBook($id: ID!) {
    getBook(id: $id) {
      id
      sub
      title
      description
      summary
      homeWeight
      familyWeight
      categoryWeight
      cover
      coverKeys
      coverData
      isbn
      coverFile {
        bucket
        region
        key
      }
      epub
      freeEpub
      epubData
      freeEpubData
      adminEpub
      adminEpubData
      status
      bookType
      rating
      ratingCount
      likesCount
      purchasedCount
      purchasedAmount
      price
      discount
      contents
      audioPreview
      draftID
      draft {
        id
        sub
        title
        description
        summary
        cover
        coverFile {
          bucket
          region
          key
        }
        coverKeys
        coverTemp
        coverData
        epub
        epubFile {
          bucket
          region
          key
        }
        epubTempURL
        freeEpub
        epubData
        freeEpubData
        freeEpubFile {
          bucket
          region
          key
        }
        status
        bookType
        history
        price
        isbn
        content
        audioPreview
        authorUserID
        authorUser {
          id
          validated
          sub
          firstName
          lastName
          birthday
          email
          accessDocumentation
          setOtherAuthor
          unsubscribe
          unsubscribeData
          identity
          phone
          cellPhone
          gender
          address
          allowDoc
          city
          zip
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        authorID
        author {
          id
          sub
          name
          firstName
          email
          gender
          birthdate
          avatar
          avatarKeys
          avatarTemp
          avatarData
          biography
          homeWeight
          familyWeight
          categoryWeight
          followCount
          status
          authorUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        draftContents {
          nextToken
          startedAt
        }
        familyID
        family {
          id
          name
          title
          description
          icon
          iconKeys
          content
          parameters
          appVersion
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryID
        category {
          id
          name
          title
          description
          content
          order
          parameters
          familyID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subCategoryID
        subCategory {
          id
          name
          title
          description
          content
          order
          parameters
          active
          familyID
          categoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        books {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      authorID
      author {
        id
        sub
        name
        firstName
        email
        gender
        birthdate
        avatar
        avatarKeys
        avatarTemp
        avatarData
        biography
        homeWeight
        familyWeight
        categoryWeight
        followCount
        status
        authorUserID
        authorUser {
          id
          validated
          sub
          firstName
          lastName
          birthday
          email
          accessDocumentation
          setOtherAuthor
          unsubscribe
          unsubscribeData
          identity
          phone
          cellPhone
          gender
          address
          allowDoc
          city
          zip
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        drafts {
          nextToken
          startedAt
        }
        books {
          nextToken
          startedAt
        }
        followAuthors {
          nextToken
          startedAt
        }
        notifyFollowers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      bookContents {
        items {
          id
          sub
          order
          label
          title
          freeText
          freeAudio
          freeAudioData
          adminAudioData
          text
          audio
          audioData
          bookID
          draftContentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      purchases {
        items {
          id
          sub
          authorSub
          status
          unused
          rating
          current
          result
          identity
          buyer
          epub
          bookVersion
          bookUpdate
          amount
          readerUserID
          bookID
          paymentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      bookLikes {
        items {
          id
          sub
          authorSub
          bookVersion
          bookUpdate
          like
          status
          readerUserID
          bookID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      bookRatings {
        items {
          id
          sub
          authorSub
          bookVersion
          bookUpdate
          rating
          readerUserID
          bookID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      notifyFollowers {
        items {
          id
          sub
          authorSub
          status
          readerUserID
          authorID
          bookID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      familyID
      family {
        id
        name
        title
        description
        icon
        iconKeys
        content
        parameters
        appVersion
        active
        drafts {
          nextToken
          startedAt
        }
        books {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      categoryID
      category {
        id
        name
        title
        description
        content
        order
        parameters
        familyID
        family {
          id
          name
          title
          description
          icon
          iconKeys
          content
          parameters
          appVersion
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        active
        drafts {
          nextToken
          startedAt
        }
        books {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      subCategoryID
      subCategory {
        id
        name
        title
        description
        content
        order
        parameters
        active
        familyID
        family {
          id
          name
          title
          description
          icon
          iconKeys
          content
          parameters
          appVersion
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryID
        category {
          id
          name
          title
          description
          content
          order
          parameters
          familyID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        drafts {
          nextToken
          startedAt
        }
        books {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBooks = /* GraphQL */ `
  query ListBooks(
    $id: ID
    $filter: ModelBookFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBooks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sub
        title
        description
        summary
        homeWeight
        familyWeight
        categoryWeight
        cover
        coverKeys
        coverData
        isbn
        coverFile {
          bucket
          region
          key
        }
        epub
        freeEpub
        epubData
        freeEpubData
        adminEpub
        adminEpubData
        status
        bookType
        rating
        ratingCount
        likesCount
        purchasedCount
        purchasedAmount
        price
        discount
        contents
        audioPreview
        draftID
        draft {
          id
          sub
          title
          description
          summary
          cover
          coverKeys
          coverTemp
          coverData
          epub
          epubTempURL
          freeEpub
          epubData
          freeEpubData
          status
          bookType
          history
          price
          isbn
          content
          audioPreview
          authorUserID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        authorID
        author {
          id
          sub
          name
          firstName
          email
          gender
          birthdate
          avatar
          avatarKeys
          avatarTemp
          avatarData
          biography
          homeWeight
          familyWeight
          categoryWeight
          followCount
          status
          authorUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bookContents {
          nextToken
          startedAt
        }
        purchases {
          nextToken
          startedAt
        }
        bookLikes {
          nextToken
          startedAt
        }
        bookRatings {
          nextToken
          startedAt
        }
        notifyFollowers {
          nextToken
          startedAt
        }
        familyID
        family {
          id
          name
          title
          description
          icon
          iconKeys
          content
          parameters
          appVersion
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryID
        category {
          id
          name
          title
          description
          content
          order
          parameters
          familyID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subCategoryID
        subCategory {
          id
          name
          title
          description
          content
          order
          parameters
          active
          familyID
          categoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBooks = /* GraphQL */ `
  query SyncBooks(
    $filter: ModelBookFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBooks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sub
        title
        description
        summary
        homeWeight
        familyWeight
        categoryWeight
        cover
        coverKeys
        coverData
        isbn
        coverFile {
          bucket
          region
          key
        }
        epub
        freeEpub
        epubData
        freeEpubData
        adminEpub
        adminEpubData
        status
        bookType
        rating
        ratingCount
        likesCount
        purchasedCount
        purchasedAmount
        price
        discount
        contents
        audioPreview
        draftID
        draft {
          id
          sub
          title
          description
          summary
          cover
          coverKeys
          coverTemp
          coverData
          epub
          epubTempURL
          freeEpub
          epubData
          freeEpubData
          status
          bookType
          history
          price
          isbn
          content
          audioPreview
          authorUserID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        authorID
        author {
          id
          sub
          name
          firstName
          email
          gender
          birthdate
          avatar
          avatarKeys
          avatarTemp
          avatarData
          biography
          homeWeight
          familyWeight
          categoryWeight
          followCount
          status
          authorUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bookContents {
          nextToken
          startedAt
        }
        purchases {
          nextToken
          startedAt
        }
        bookLikes {
          nextToken
          startedAt
        }
        bookRatings {
          nextToken
          startedAt
        }
        notifyFollowers {
          nextToken
          startedAt
        }
        familyID
        family {
          id
          name
          title
          description
          icon
          iconKeys
          content
          parameters
          appVersion
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryID
        category {
          id
          name
          title
          description
          content
          order
          parameters
          familyID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subCategoryID
        subCategory {
          id
          name
          title
          description
          content
          order
          parameters
          active
          familyID
          categoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBookContent = /* GraphQL */ `
  query GetBookContent($id: ID!) {
    getBookContent(id: $id) {
      id
      sub
      order
      label
      title
      freeText
      freeAudio
      freeAudioData
      adminAudioData
      text
      audio
      audioData
      bookID
      book {
        id
        sub
        title
        description
        summary
        homeWeight
        familyWeight
        categoryWeight
        cover
        coverKeys
        coverData
        isbn
        coverFile {
          bucket
          region
          key
        }
        epub
        freeEpub
        epubData
        freeEpubData
        adminEpub
        adminEpubData
        status
        bookType
        rating
        ratingCount
        likesCount
        purchasedCount
        purchasedAmount
        price
        discount
        contents
        audioPreview
        draftID
        draft {
          id
          sub
          title
          description
          summary
          cover
          coverKeys
          coverTemp
          coverData
          epub
          epubTempURL
          freeEpub
          epubData
          freeEpubData
          status
          bookType
          history
          price
          isbn
          content
          audioPreview
          authorUserID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        authorID
        author {
          id
          sub
          name
          firstName
          email
          gender
          birthdate
          avatar
          avatarKeys
          avatarTemp
          avatarData
          biography
          homeWeight
          familyWeight
          categoryWeight
          followCount
          status
          authorUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bookContents {
          nextToken
          startedAt
        }
        purchases {
          nextToken
          startedAt
        }
        bookLikes {
          nextToken
          startedAt
        }
        bookRatings {
          nextToken
          startedAt
        }
        notifyFollowers {
          nextToken
          startedAt
        }
        familyID
        family {
          id
          name
          title
          description
          icon
          iconKeys
          content
          parameters
          appVersion
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryID
        category {
          id
          name
          title
          description
          content
          order
          parameters
          familyID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subCategoryID
        subCategory {
          id
          name
          title
          description
          content
          order
          parameters
          active
          familyID
          categoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      draftContentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBookContents = /* GraphQL */ `
  query ListBookContents(
    $id: ID
    $filter: ModelBookContentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBookContents(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sub
        order
        label
        title
        freeText
        freeAudio
        freeAudioData
        adminAudioData
        text
        audio
        audioData
        bookID
        book {
          id
          sub
          title
          description
          summary
          homeWeight
          familyWeight
          categoryWeight
          cover
          coverKeys
          coverData
          isbn
          epub
          freeEpub
          epubData
          freeEpubData
          adminEpub
          adminEpubData
          status
          bookType
          rating
          ratingCount
          likesCount
          purchasedCount
          purchasedAmount
          price
          discount
          contents
          audioPreview
          draftID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        draftContentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBookContents = /* GraphQL */ `
  query SyncBookContents(
    $filter: ModelBookContentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBookContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sub
        order
        label
        title
        freeText
        freeAudio
        freeAudioData
        adminAudioData
        text
        audio
        audioData
        bookID
        book {
          id
          sub
          title
          description
          summary
          homeWeight
          familyWeight
          categoryWeight
          cover
          coverKeys
          coverData
          isbn
          epub
          freeEpub
          epubData
          freeEpubData
          adminEpub
          adminEpubData
          status
          bookType
          rating
          ratingCount
          likesCount
          purchasedCount
          purchasedAmount
          price
          discount
          contents
          audioPreview
          draftID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        draftContentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getReaderUser = /* GraphQL */ `
  query GetReaderUser($id: ID!) {
    getReaderUser(id: $id) {
      id
      sub
      firstName
      lastName
      avatar
      avatarKeys
      avatarData
      birthday
      email
      phone
      cellPhone
      gender
      address
      city
      zip
      country
      status
      unsubscribe
      unsubscribeData
      credits
      refills {
        items {
          id
          sub
          readerUserID
          paymentType
          paymentId
          payload
          response
          confirmation
          amount
          status
          transfered
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      purchases {
        items {
          id
          sub
          authorSub
          status
          unused
          rating
          current
          result
          identity
          buyer
          epub
          bookVersion
          bookUpdate
          amount
          readerUserID
          bookID
          paymentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      bookLikes {
        items {
          id
          sub
          authorSub
          bookVersion
          bookUpdate
          like
          status
          readerUserID
          bookID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      bookRatings {
        items {
          id
          sub
          authorSub
          bookVersion
          bookUpdate
          rating
          readerUserID
          bookID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      followAuthors {
        items {
          id
          sub
          authorSub
          like
          status
          readerUserID
          authorID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      notifyFollowers {
        items {
          id
          sub
          authorSub
          status
          readerUserID
          authorID
          bookID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listReaderUsers = /* GraphQL */ `
  query ListReaderUsers(
    $id: ID
    $filter: ModelReaderUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReaderUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sub
        firstName
        lastName
        avatar
        avatarKeys
        avatarData
        birthday
        email
        phone
        cellPhone
        gender
        address
        city
        zip
        country
        status
        unsubscribe
        unsubscribeData
        credits
        refills {
          nextToken
          startedAt
        }
        purchases {
          nextToken
          startedAt
        }
        bookLikes {
          nextToken
          startedAt
        }
        bookRatings {
          nextToken
          startedAt
        }
        followAuthors {
          nextToken
          startedAt
        }
        notifyFollowers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReaderUsers = /* GraphQL */ `
  query SyncReaderUsers(
    $filter: ModelReaderUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReaderUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sub
        firstName
        lastName
        avatar
        avatarKeys
        avatarData
        birthday
        email
        phone
        cellPhone
        gender
        address
        city
        zip
        country
        status
        unsubscribe
        unsubscribeData
        credits
        refills {
          nextToken
          startedAt
        }
        purchases {
          nextToken
          startedAt
        }
        bookLikes {
          nextToken
          startedAt
        }
        bookRatings {
          nextToken
          startedAt
        }
        followAuthors {
          nextToken
          startedAt
        }
        notifyFollowers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRefill = /* GraphQL */ `
  query GetRefill($id: ID!) {
    getRefill(id: $id) {
      id
      sub
      readerUserID
      readerUser {
        id
        sub
        firstName
        lastName
        avatar
        avatarKeys
        avatarData
        birthday
        email
        phone
        cellPhone
        gender
        address
        city
        zip
        country
        status
        unsubscribe
        unsubscribeData
        credits
        refills {
          nextToken
          startedAt
        }
        purchases {
          nextToken
          startedAt
        }
        bookLikes {
          nextToken
          startedAt
        }
        bookRatings {
          nextToken
          startedAt
        }
        followAuthors {
          nextToken
          startedAt
        }
        notifyFollowers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      paymentType
      paymentId
      payload
      response
      confirmation
      amount
      status
      transfered
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRefills = /* GraphQL */ `
  query ListRefills(
    $filter: ModelRefillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRefills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sub
        readerUserID
        readerUser {
          id
          sub
          firstName
          lastName
          avatar
          avatarKeys
          avatarData
          birthday
          email
          phone
          cellPhone
          gender
          address
          city
          zip
          country
          status
          unsubscribe
          unsubscribeData
          credits
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        paymentType
        paymentId
        payload
        response
        confirmation
        amount
        status
        transfered
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRefills = /* GraphQL */ `
  query SyncRefills(
    $filter: ModelRefillFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRefills(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sub
        readerUserID
        readerUser {
          id
          sub
          firstName
          lastName
          avatar
          avatarKeys
          avatarData
          birthday
          email
          phone
          cellPhone
          gender
          address
          city
          zip
          country
          status
          unsubscribe
          unsubscribeData
          credits
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        paymentType
        paymentId
        payload
        response
        confirmation
        amount
        status
        transfered
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPurchase = /* GraphQL */ `
  query GetPurchase($id: ID!) {
    getPurchase(id: $id) {
      id
      sub
      authorSub
      status
      unused
      rating
      current
      result
      identity
      buyer
      epub
      bookVersion
      bookUpdate
      amount
      readerUserID
      readerUser {
        id
        sub
        firstName
        lastName
        avatar
        avatarKeys
        avatarData
        birthday
        email
        phone
        cellPhone
        gender
        address
        city
        zip
        country
        status
        unsubscribe
        unsubscribeData
        credits
        refills {
          nextToken
          startedAt
        }
        purchases {
          nextToken
          startedAt
        }
        bookLikes {
          nextToken
          startedAt
        }
        bookRatings {
          nextToken
          startedAt
        }
        followAuthors {
          nextToken
          startedAt
        }
        notifyFollowers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      bookID
      book {
        id
        sub
        title
        description
        summary
        homeWeight
        familyWeight
        categoryWeight
        cover
        coverKeys
        coverData
        isbn
        coverFile {
          bucket
          region
          key
        }
        epub
        freeEpub
        epubData
        freeEpubData
        adminEpub
        adminEpubData
        status
        bookType
        rating
        ratingCount
        likesCount
        purchasedCount
        purchasedAmount
        price
        discount
        contents
        audioPreview
        draftID
        draft {
          id
          sub
          title
          description
          summary
          cover
          coverKeys
          coverTemp
          coverData
          epub
          epubTempURL
          freeEpub
          epubData
          freeEpubData
          status
          bookType
          history
          price
          isbn
          content
          audioPreview
          authorUserID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        authorID
        author {
          id
          sub
          name
          firstName
          email
          gender
          birthdate
          avatar
          avatarKeys
          avatarTemp
          avatarData
          biography
          homeWeight
          familyWeight
          categoryWeight
          followCount
          status
          authorUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bookContents {
          nextToken
          startedAt
        }
        purchases {
          nextToken
          startedAt
        }
        bookLikes {
          nextToken
          startedAt
        }
        bookRatings {
          nextToken
          startedAt
        }
        notifyFollowers {
          nextToken
          startedAt
        }
        familyID
        family {
          id
          name
          title
          description
          icon
          iconKeys
          content
          parameters
          appVersion
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryID
        category {
          id
          name
          title
          description
          content
          order
          parameters
          familyID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subCategoryID
        subCategory {
          id
          name
          title
          description
          content
          order
          parameters
          active
          familyID
          categoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      purchasedContents {
        items {
          id
          sub
          order
          label
          title
          text
          audio
          audioData
          tempURL
          bookContentID
          purchaseID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      paymentID
      payment {
        id
        sub
        amount
        made
        parameters
        transactionType
        transactionId
        note
        date
        purchases {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPurchases = /* GraphQL */ `
  query ListPurchases(
    $filter: ModelPurchaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPurchases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sub
        authorSub
        status
        unused
        rating
        current
        result
        identity
        buyer
        epub
        bookVersion
        bookUpdate
        amount
        readerUserID
        readerUser {
          id
          sub
          firstName
          lastName
          avatar
          avatarKeys
          avatarData
          birthday
          email
          phone
          cellPhone
          gender
          address
          city
          zip
          country
          status
          unsubscribe
          unsubscribeData
          credits
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bookID
        book {
          id
          sub
          title
          description
          summary
          homeWeight
          familyWeight
          categoryWeight
          cover
          coverKeys
          coverData
          isbn
          epub
          freeEpub
          epubData
          freeEpubData
          adminEpub
          adminEpubData
          status
          bookType
          rating
          ratingCount
          likesCount
          purchasedCount
          purchasedAmount
          price
          discount
          contents
          audioPreview
          draftID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        purchasedContents {
          nextToken
          startedAt
        }
        paymentID
        payment {
          id
          sub
          amount
          made
          parameters
          transactionType
          transactionId
          note
          date
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPurchases = /* GraphQL */ `
  query SyncPurchases(
    $filter: ModelPurchaseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPurchases(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sub
        authorSub
        status
        unused
        rating
        current
        result
        identity
        buyer
        epub
        bookVersion
        bookUpdate
        amount
        readerUserID
        readerUser {
          id
          sub
          firstName
          lastName
          avatar
          avatarKeys
          avatarData
          birthday
          email
          phone
          cellPhone
          gender
          address
          city
          zip
          country
          status
          unsubscribe
          unsubscribeData
          credits
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bookID
        book {
          id
          sub
          title
          description
          summary
          homeWeight
          familyWeight
          categoryWeight
          cover
          coverKeys
          coverData
          isbn
          epub
          freeEpub
          epubData
          freeEpubData
          adminEpub
          adminEpubData
          status
          bookType
          rating
          ratingCount
          likesCount
          purchasedCount
          purchasedAmount
          price
          discount
          contents
          audioPreview
          draftID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        purchasedContents {
          nextToken
          startedAt
        }
        paymentID
        payment {
          id
          sub
          amount
          made
          parameters
          transactionType
          transactionId
          note
          date
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPurchasedContent = /* GraphQL */ `
  query GetPurchasedContent($id: ID!) {
    getPurchasedContent(id: $id) {
      id
      sub
      order
      label
      title
      text
      audio
      audioData
      tempURL
      bookContentID
      purchaseID
      purchase {
        id
        sub
        authorSub
        status
        unused
        rating
        current
        result
        identity
        buyer
        epub
        bookVersion
        bookUpdate
        amount
        readerUserID
        readerUser {
          id
          sub
          firstName
          lastName
          avatar
          avatarKeys
          avatarData
          birthday
          email
          phone
          cellPhone
          gender
          address
          city
          zip
          country
          status
          unsubscribe
          unsubscribeData
          credits
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bookID
        book {
          id
          sub
          title
          description
          summary
          homeWeight
          familyWeight
          categoryWeight
          cover
          coverKeys
          coverData
          isbn
          epub
          freeEpub
          epubData
          freeEpubData
          adminEpub
          adminEpubData
          status
          bookType
          rating
          ratingCount
          likesCount
          purchasedCount
          purchasedAmount
          price
          discount
          contents
          audioPreview
          draftID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        purchasedContents {
          nextToken
          startedAt
        }
        paymentID
        payment {
          id
          sub
          amount
          made
          parameters
          transactionType
          transactionId
          note
          date
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPurchasedContents = /* GraphQL */ `
  query ListPurchasedContents(
    $id: ID
    $filter: ModelPurchasedContentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPurchasedContents(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sub
        order
        label
        title
        text
        audio
        audioData
        tempURL
        bookContentID
        purchaseID
        purchase {
          id
          sub
          authorSub
          status
          unused
          rating
          current
          result
          identity
          buyer
          epub
          bookVersion
          bookUpdate
          amount
          readerUserID
          bookID
          paymentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPurchasedContents = /* GraphQL */ `
  query SyncPurchasedContents(
    $filter: ModelPurchasedContentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPurchasedContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sub
        order
        label
        title
        text
        audio
        audioData
        tempURL
        bookContentID
        purchaseID
        purchase {
          id
          sub
          authorSub
          status
          unused
          rating
          current
          result
          identity
          buyer
          epub
          bookVersion
          bookUpdate
          amount
          readerUserID
          bookID
          paymentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBookRating = /* GraphQL */ `
  query GetBookRating($id: ID!) {
    getBookRating(id: $id) {
      id
      sub
      authorSub
      bookVersion
      bookUpdate
      rating
      readerUserID
      readerUser {
        id
        sub
        firstName
        lastName
        avatar
        avatarKeys
        avatarData
        birthday
        email
        phone
        cellPhone
        gender
        address
        city
        zip
        country
        status
        unsubscribe
        unsubscribeData
        credits
        refills {
          nextToken
          startedAt
        }
        purchases {
          nextToken
          startedAt
        }
        bookLikes {
          nextToken
          startedAt
        }
        bookRatings {
          nextToken
          startedAt
        }
        followAuthors {
          nextToken
          startedAt
        }
        notifyFollowers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      bookID
      book {
        id
        sub
        title
        description
        summary
        homeWeight
        familyWeight
        categoryWeight
        cover
        coverKeys
        coverData
        isbn
        coverFile {
          bucket
          region
          key
        }
        epub
        freeEpub
        epubData
        freeEpubData
        adminEpub
        adminEpubData
        status
        bookType
        rating
        ratingCount
        likesCount
        purchasedCount
        purchasedAmount
        price
        discount
        contents
        audioPreview
        draftID
        draft {
          id
          sub
          title
          description
          summary
          cover
          coverKeys
          coverTemp
          coverData
          epub
          epubTempURL
          freeEpub
          epubData
          freeEpubData
          status
          bookType
          history
          price
          isbn
          content
          audioPreview
          authorUserID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        authorID
        author {
          id
          sub
          name
          firstName
          email
          gender
          birthdate
          avatar
          avatarKeys
          avatarTemp
          avatarData
          biography
          homeWeight
          familyWeight
          categoryWeight
          followCount
          status
          authorUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bookContents {
          nextToken
          startedAt
        }
        purchases {
          nextToken
          startedAt
        }
        bookLikes {
          nextToken
          startedAt
        }
        bookRatings {
          nextToken
          startedAt
        }
        notifyFollowers {
          nextToken
          startedAt
        }
        familyID
        family {
          id
          name
          title
          description
          icon
          iconKeys
          content
          parameters
          appVersion
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryID
        category {
          id
          name
          title
          description
          content
          order
          parameters
          familyID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subCategoryID
        subCategory {
          id
          name
          title
          description
          content
          order
          parameters
          active
          familyID
          categoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBookRatings = /* GraphQL */ `
  query ListBookRatings(
    $filter: ModelBookRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sub
        authorSub
        bookVersion
        bookUpdate
        rating
        readerUserID
        readerUser {
          id
          sub
          firstName
          lastName
          avatar
          avatarKeys
          avatarData
          birthday
          email
          phone
          cellPhone
          gender
          address
          city
          zip
          country
          status
          unsubscribe
          unsubscribeData
          credits
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bookID
        book {
          id
          sub
          title
          description
          summary
          homeWeight
          familyWeight
          categoryWeight
          cover
          coverKeys
          coverData
          isbn
          epub
          freeEpub
          epubData
          freeEpubData
          adminEpub
          adminEpubData
          status
          bookType
          rating
          ratingCount
          likesCount
          purchasedCount
          purchasedAmount
          price
          discount
          contents
          audioPreview
          draftID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBookRatings = /* GraphQL */ `
  query SyncBookRatings(
    $filter: ModelBookRatingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBookRatings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sub
        authorSub
        bookVersion
        bookUpdate
        rating
        readerUserID
        readerUser {
          id
          sub
          firstName
          lastName
          avatar
          avatarKeys
          avatarData
          birthday
          email
          phone
          cellPhone
          gender
          address
          city
          zip
          country
          status
          unsubscribe
          unsubscribeData
          credits
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bookID
        book {
          id
          sub
          title
          description
          summary
          homeWeight
          familyWeight
          categoryWeight
          cover
          coverKeys
          coverData
          isbn
          epub
          freeEpub
          epubData
          freeEpubData
          adminEpub
          adminEpubData
          status
          bookType
          rating
          ratingCount
          likesCount
          purchasedCount
          purchasedAmount
          price
          discount
          contents
          audioPreview
          draftID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBookLike = /* GraphQL */ `
  query GetBookLike($id: ID!) {
    getBookLike(id: $id) {
      id
      sub
      authorSub
      bookVersion
      bookUpdate
      like
      status
      readerUserID
      readerUser {
        id
        sub
        firstName
        lastName
        avatar
        avatarKeys
        avatarData
        birthday
        email
        phone
        cellPhone
        gender
        address
        city
        zip
        country
        status
        unsubscribe
        unsubscribeData
        credits
        refills {
          nextToken
          startedAt
        }
        purchases {
          nextToken
          startedAt
        }
        bookLikes {
          nextToken
          startedAt
        }
        bookRatings {
          nextToken
          startedAt
        }
        followAuthors {
          nextToken
          startedAt
        }
        notifyFollowers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      bookID
      book {
        id
        sub
        title
        description
        summary
        homeWeight
        familyWeight
        categoryWeight
        cover
        coverKeys
        coverData
        isbn
        coverFile {
          bucket
          region
          key
        }
        epub
        freeEpub
        epubData
        freeEpubData
        adminEpub
        adminEpubData
        status
        bookType
        rating
        ratingCount
        likesCount
        purchasedCount
        purchasedAmount
        price
        discount
        contents
        audioPreview
        draftID
        draft {
          id
          sub
          title
          description
          summary
          cover
          coverKeys
          coverTemp
          coverData
          epub
          epubTempURL
          freeEpub
          epubData
          freeEpubData
          status
          bookType
          history
          price
          isbn
          content
          audioPreview
          authorUserID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        authorID
        author {
          id
          sub
          name
          firstName
          email
          gender
          birthdate
          avatar
          avatarKeys
          avatarTemp
          avatarData
          biography
          homeWeight
          familyWeight
          categoryWeight
          followCount
          status
          authorUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bookContents {
          nextToken
          startedAt
        }
        purchases {
          nextToken
          startedAt
        }
        bookLikes {
          nextToken
          startedAt
        }
        bookRatings {
          nextToken
          startedAt
        }
        notifyFollowers {
          nextToken
          startedAt
        }
        familyID
        family {
          id
          name
          title
          description
          icon
          iconKeys
          content
          parameters
          appVersion
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryID
        category {
          id
          name
          title
          description
          content
          order
          parameters
          familyID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subCategoryID
        subCategory {
          id
          name
          title
          description
          content
          order
          parameters
          active
          familyID
          categoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listBookLikes = /* GraphQL */ `
  query ListBookLikes(
    $filter: ModelBookLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sub
        authorSub
        bookVersion
        bookUpdate
        like
        status
        readerUserID
        readerUser {
          id
          sub
          firstName
          lastName
          avatar
          avatarKeys
          avatarData
          birthday
          email
          phone
          cellPhone
          gender
          address
          city
          zip
          country
          status
          unsubscribe
          unsubscribeData
          credits
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bookID
        book {
          id
          sub
          title
          description
          summary
          homeWeight
          familyWeight
          categoryWeight
          cover
          coverKeys
          coverData
          isbn
          epub
          freeEpub
          epubData
          freeEpubData
          adminEpub
          adminEpubData
          status
          bookType
          rating
          ratingCount
          likesCount
          purchasedCount
          purchasedAmount
          price
          discount
          contents
          audioPreview
          draftID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBookLikes = /* GraphQL */ `
  query SyncBookLikes(
    $filter: ModelBookLikeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBookLikes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sub
        authorSub
        bookVersion
        bookUpdate
        like
        status
        readerUserID
        readerUser {
          id
          sub
          firstName
          lastName
          avatar
          avatarKeys
          avatarData
          birthday
          email
          phone
          cellPhone
          gender
          address
          city
          zip
          country
          status
          unsubscribe
          unsubscribeData
          credits
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bookID
        book {
          id
          sub
          title
          description
          summary
          homeWeight
          familyWeight
          categoryWeight
          cover
          coverKeys
          coverData
          isbn
          epub
          freeEpub
          epubData
          freeEpubData
          adminEpub
          adminEpubData
          status
          bookType
          rating
          ratingCount
          likesCount
          purchasedCount
          purchasedAmount
          price
          discount
          contents
          audioPreview
          draftID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFollowAuthor = /* GraphQL */ `
  query GetFollowAuthor($id: ID!) {
    getFollowAuthor(id: $id) {
      id
      sub
      authorSub
      like
      status
      readerUserID
      readerUser {
        id
        sub
        firstName
        lastName
        avatar
        avatarKeys
        avatarData
        birthday
        email
        phone
        cellPhone
        gender
        address
        city
        zip
        country
        status
        unsubscribe
        unsubscribeData
        credits
        refills {
          nextToken
          startedAt
        }
        purchases {
          nextToken
          startedAt
        }
        bookLikes {
          nextToken
          startedAt
        }
        bookRatings {
          nextToken
          startedAt
        }
        followAuthors {
          nextToken
          startedAt
        }
        notifyFollowers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      authorID
      author {
        id
        sub
        name
        firstName
        email
        gender
        birthdate
        avatar
        avatarKeys
        avatarTemp
        avatarData
        biography
        homeWeight
        familyWeight
        categoryWeight
        followCount
        status
        authorUserID
        authorUser {
          id
          validated
          sub
          firstName
          lastName
          birthday
          email
          accessDocumentation
          setOtherAuthor
          unsubscribe
          unsubscribeData
          identity
          phone
          cellPhone
          gender
          address
          allowDoc
          city
          zip
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        drafts {
          nextToken
          startedAt
        }
        books {
          nextToken
          startedAt
        }
        followAuthors {
          nextToken
          startedAt
        }
        notifyFollowers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFollowAuthors = /* GraphQL */ `
  query ListFollowAuthors(
    $filter: ModelFollowAuthorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFollowAuthors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sub
        authorSub
        like
        status
        readerUserID
        readerUser {
          id
          sub
          firstName
          lastName
          avatar
          avatarKeys
          avatarData
          birthday
          email
          phone
          cellPhone
          gender
          address
          city
          zip
          country
          status
          unsubscribe
          unsubscribeData
          credits
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        authorID
        author {
          id
          sub
          name
          firstName
          email
          gender
          birthdate
          avatar
          avatarKeys
          avatarTemp
          avatarData
          biography
          homeWeight
          familyWeight
          categoryWeight
          followCount
          status
          authorUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFollowAuthors = /* GraphQL */ `
  query SyncFollowAuthors(
    $filter: ModelFollowAuthorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFollowAuthors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sub
        authorSub
        like
        status
        readerUserID
        readerUser {
          id
          sub
          firstName
          lastName
          avatar
          avatarKeys
          avatarData
          birthday
          email
          phone
          cellPhone
          gender
          address
          city
          zip
          country
          status
          unsubscribe
          unsubscribeData
          credits
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        authorID
        author {
          id
          sub
          name
          firstName
          email
          gender
          birthdate
          avatar
          avatarKeys
          avatarTemp
          avatarData
          biography
          homeWeight
          familyWeight
          categoryWeight
          followCount
          status
          authorUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getNotifyFollower = /* GraphQL */ `
  query GetNotifyFollower($id: ID!) {
    getNotifyFollower(id: $id) {
      id
      sub
      authorSub
      status
      readerUserID
      readerUser {
        id
        sub
        firstName
        lastName
        avatar
        avatarKeys
        avatarData
        birthday
        email
        phone
        cellPhone
        gender
        address
        city
        zip
        country
        status
        unsubscribe
        unsubscribeData
        credits
        refills {
          nextToken
          startedAt
        }
        purchases {
          nextToken
          startedAt
        }
        bookLikes {
          nextToken
          startedAt
        }
        bookRatings {
          nextToken
          startedAt
        }
        followAuthors {
          nextToken
          startedAt
        }
        notifyFollowers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      authorID
      author {
        id
        sub
        name
        firstName
        email
        gender
        birthdate
        avatar
        avatarKeys
        avatarTemp
        avatarData
        biography
        homeWeight
        familyWeight
        categoryWeight
        followCount
        status
        authorUserID
        authorUser {
          id
          validated
          sub
          firstName
          lastName
          birthday
          email
          accessDocumentation
          setOtherAuthor
          unsubscribe
          unsubscribeData
          identity
          phone
          cellPhone
          gender
          address
          allowDoc
          city
          zip
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        drafts {
          nextToken
          startedAt
        }
        books {
          nextToken
          startedAt
        }
        followAuthors {
          nextToken
          startedAt
        }
        notifyFollowers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      bookID
      book {
        id
        sub
        title
        description
        summary
        homeWeight
        familyWeight
        categoryWeight
        cover
        coverKeys
        coverData
        isbn
        coverFile {
          bucket
          region
          key
        }
        epub
        freeEpub
        epubData
        freeEpubData
        adminEpub
        adminEpubData
        status
        bookType
        rating
        ratingCount
        likesCount
        purchasedCount
        purchasedAmount
        price
        discount
        contents
        audioPreview
        draftID
        draft {
          id
          sub
          title
          description
          summary
          cover
          coverKeys
          coverTemp
          coverData
          epub
          epubTempURL
          freeEpub
          epubData
          freeEpubData
          status
          bookType
          history
          price
          isbn
          content
          audioPreview
          authorUserID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        authorID
        author {
          id
          sub
          name
          firstName
          email
          gender
          birthdate
          avatar
          avatarKeys
          avatarTemp
          avatarData
          biography
          homeWeight
          familyWeight
          categoryWeight
          followCount
          status
          authorUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bookContents {
          nextToken
          startedAt
        }
        purchases {
          nextToken
          startedAt
        }
        bookLikes {
          nextToken
          startedAt
        }
        bookRatings {
          nextToken
          startedAt
        }
        notifyFollowers {
          nextToken
          startedAt
        }
        familyID
        family {
          id
          name
          title
          description
          icon
          iconKeys
          content
          parameters
          appVersion
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryID
        category {
          id
          name
          title
          description
          content
          order
          parameters
          familyID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        subCategoryID
        subCategory {
          id
          name
          title
          description
          content
          order
          parameters
          active
          familyID
          categoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listNotifyFollowers = /* GraphQL */ `
  query ListNotifyFollowers(
    $filter: ModelNotifyFollowerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifyFollowers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sub
        authorSub
        status
        readerUserID
        readerUser {
          id
          sub
          firstName
          lastName
          avatar
          avatarKeys
          avatarData
          birthday
          email
          phone
          cellPhone
          gender
          address
          city
          zip
          country
          status
          unsubscribe
          unsubscribeData
          credits
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        authorID
        author {
          id
          sub
          name
          firstName
          email
          gender
          birthdate
          avatar
          avatarKeys
          avatarTemp
          avatarData
          biography
          homeWeight
          familyWeight
          categoryWeight
          followCount
          status
          authorUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bookID
        book {
          id
          sub
          title
          description
          summary
          homeWeight
          familyWeight
          categoryWeight
          cover
          coverKeys
          coverData
          isbn
          epub
          freeEpub
          epubData
          freeEpubData
          adminEpub
          adminEpubData
          status
          bookType
          rating
          ratingCount
          likesCount
          purchasedCount
          purchasedAmount
          price
          discount
          contents
          audioPreview
          draftID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncNotifyFollowers = /* GraphQL */ `
  query SyncNotifyFollowers(
    $filter: ModelNotifyFollowerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotifyFollowers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sub
        authorSub
        status
        readerUserID
        readerUser {
          id
          sub
          firstName
          lastName
          avatar
          avatarKeys
          avatarData
          birthday
          email
          phone
          cellPhone
          gender
          address
          city
          zip
          country
          status
          unsubscribe
          unsubscribeData
          credits
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        authorID
        author {
          id
          sub
          name
          firstName
          email
          gender
          birthdate
          avatar
          avatarKeys
          avatarTemp
          avatarData
          biography
          homeWeight
          familyWeight
          categoryWeight
          followCount
          status
          authorUserID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        bookID
        book {
          id
          sub
          title
          description
          summary
          homeWeight
          familyWeight
          categoryWeight
          cover
          coverKeys
          coverData
          isbn
          epub
          freeEpub
          epubData
          freeEpubData
          adminEpub
          adminEpubData
          status
          bookType
          rating
          ratingCount
          likesCount
          purchasedCount
          purchasedAmount
          price
          discount
          contents
          audioPreview
          draftID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      sub
      amount
      made
      parameters
      transactionType
      transactionId
      note
      date
      purchases {
        items {
          id
          sub
          authorSub
          status
          unused
          rating
          current
          result
          identity
          buyer
          epub
          bookVersion
          bookUpdate
          amount
          readerUserID
          bookID
          paymentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sub
        amount
        made
        parameters
        transactionType
        transactionId
        note
        date
        purchases {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPayments = /* GraphQL */ `
  query SyncPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPayments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sub
        amount
        made
        parameters
        transactionType
        transactionId
        note
        date
        purchases {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFamily = /* GraphQL */ `
  query GetFamily($id: ID!) {
    getFamily(id: $id) {
      id
      name
      title
      description
      icon
      iconKeys
      content
      parameters
      appVersion
      active
      drafts {
        items {
          id
          sub
          title
          description
          summary
          cover
          coverKeys
          coverTemp
          coverData
          epub
          epubTempURL
          freeEpub
          epubData
          freeEpubData
          status
          bookType
          history
          price
          isbn
          content
          audioPreview
          authorUserID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      books {
        items {
          id
          sub
          title
          description
          summary
          homeWeight
          familyWeight
          categoryWeight
          cover
          coverKeys
          coverData
          isbn
          epub
          freeEpub
          epubData
          freeEpubData
          adminEpub
          adminEpubData
          status
          bookType
          rating
          ratingCount
          likesCount
          purchasedCount
          purchasedAmount
          price
          discount
          contents
          audioPreview
          draftID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      categories {
        items {
          id
          name
          title
          description
          content
          order
          parameters
          familyID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      subCategories {
        items {
          id
          name
          title
          description
          content
          order
          parameters
          active
          familyID
          categoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFamilies = /* GraphQL */ `
  query ListFamilies(
    $id: ID
    $filter: ModelFamilyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFamilies(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        title
        description
        icon
        iconKeys
        content
        parameters
        appVersion
        active
        drafts {
          nextToken
          startedAt
        }
        books {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFamilies = /* GraphQL */ `
  query SyncFamilies(
    $filter: ModelFamilyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFamilies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        title
        description
        icon
        iconKeys
        content
        parameters
        appVersion
        active
        drafts {
          nextToken
          startedAt
        }
        books {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      title
      description
      content
      order
      parameters
      familyID
      family {
        id
        name
        title
        description
        icon
        iconKeys
        content
        parameters
        appVersion
        active
        drafts {
          nextToken
          startedAt
        }
        books {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      active
      drafts {
        items {
          id
          sub
          title
          description
          summary
          cover
          coverKeys
          coverTemp
          coverData
          epub
          epubTempURL
          freeEpub
          epubData
          freeEpubData
          status
          bookType
          history
          price
          isbn
          content
          audioPreview
          authorUserID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      books {
        items {
          id
          sub
          title
          description
          summary
          homeWeight
          familyWeight
          categoryWeight
          cover
          coverKeys
          coverData
          isbn
          epub
          freeEpub
          epubData
          freeEpubData
          adminEpub
          adminEpubData
          status
          bookType
          rating
          ratingCount
          likesCount
          purchasedCount
          purchasedAmount
          price
          discount
          contents
          audioPreview
          draftID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      subCategories {
        items {
          id
          name
          title
          description
          content
          order
          parameters
          active
          familyID
          categoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $id: ID
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCategories(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        title
        description
        content
        order
        parameters
        familyID
        family {
          id
          name
          title
          description
          icon
          iconKeys
          content
          parameters
          appVersion
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        active
        drafts {
          nextToken
          startedAt
        }
        books {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCategories = /* GraphQL */ `
  query SyncCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        title
        description
        content
        order
        parameters
        familyID
        family {
          id
          name
          title
          description
          icon
          iconKeys
          content
          parameters
          appVersion
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        active
        drafts {
          nextToken
          startedAt
        }
        books {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSubCategory = /* GraphQL */ `
  query GetSubCategory($id: ID!) {
    getSubCategory(id: $id) {
      id
      name
      title
      description
      content
      order
      parameters
      active
      familyID
      family {
        id
        name
        title
        description
        icon
        iconKeys
        content
        parameters
        appVersion
        active
        drafts {
          nextToken
          startedAt
        }
        books {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      categoryID
      category {
        id
        name
        title
        description
        content
        order
        parameters
        familyID
        family {
          id
          name
          title
          description
          icon
          iconKeys
          content
          parameters
          appVersion
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        active
        drafts {
          nextToken
          startedAt
        }
        books {
          nextToken
          startedAt
        }
        subCategories {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      drafts {
        items {
          id
          sub
          title
          description
          summary
          cover
          coverKeys
          coverTemp
          coverData
          epub
          epubTempURL
          freeEpub
          epubData
          freeEpubData
          status
          bookType
          history
          price
          isbn
          content
          audioPreview
          authorUserID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      books {
        items {
          id
          sub
          title
          description
          summary
          homeWeight
          familyWeight
          categoryWeight
          cover
          coverKeys
          coverData
          isbn
          epub
          freeEpub
          epubData
          freeEpubData
          adminEpub
          adminEpubData
          status
          bookType
          rating
          ratingCount
          likesCount
          purchasedCount
          purchasedAmount
          price
          discount
          contents
          audioPreview
          draftID
          authorID
          familyID
          categoryID
          subCategoryID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSubCategories = /* GraphQL */ `
  query ListSubCategories(
    $id: ID
    $filter: ModelSubCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSubCategories(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        title
        description
        content
        order
        parameters
        active
        familyID
        family {
          id
          name
          title
          description
          icon
          iconKeys
          content
          parameters
          appVersion
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryID
        category {
          id
          name
          title
          description
          content
          order
          parameters
          familyID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        drafts {
          nextToken
          startedAt
        }
        books {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSubCategories = /* GraphQL */ `
  query SyncSubCategories(
    $filter: ModelSubCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSubCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        title
        description
        content
        order
        parameters
        active
        familyID
        family {
          id
          name
          title
          description
          icon
          iconKeys
          content
          parameters
          appVersion
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        categoryID
        category {
          id
          name
          title
          description
          content
          order
          parameters
          familyID
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        drafts {
          nextToken
          startedAt
        }
        books {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSchool = /* GraphQL */ `
  query GetSchool($id: ID!) {
    getSchool(id: $id) {
      id
      title
      phoneNumber
      fixeNumber
      description
      address
      students {
        items {
          id
          firstName
          lastName
          phoneNumber
          email
          matricule
          birthday
          address
          schoolID
          studentGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      toTeachs {
        items {
          id
          status
          date
          teacherID
          schoolID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      schoolUsers {
        items {
          id
          sub
          firstName
          lastName
          email
          phoneNumber
          address
          status
          schoolREF
          schoolID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSchools = /* GraphQL */ `
  query ListSchools(
    $id: ID
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSchools(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        phoneNumber
        fixeNumber
        description
        address
        students {
          nextToken
          startedAt
        }
        toTeachs {
          nextToken
          startedAt
        }
        schoolUsers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSchools = /* GraphQL */ `
  query SyncSchools(
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSchools(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        phoneNumber
        fixeNumber
        description
        address
        students {
          nextToken
          startedAt
        }
        toTeachs {
          nextToken
          startedAt
        }
        schoolUsers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSchoolUser = /* GraphQL */ `
  query GetSchoolUser($id: ID!) {
    getSchoolUser(id: $id) {
      id
      sub
      firstName
      lastName
      email
      phoneNumber
      address
      status
      schoolREF
      schoolID
      school {
        id
        title
        phoneNumber
        fixeNumber
        description
        address
        students {
          nextToken
          startedAt
        }
        toTeachs {
          nextToken
          startedAt
        }
        schoolUsers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSchoolUsers = /* GraphQL */ `
  query ListSchoolUsers(
    $id: ID
    $filter: ModelSchoolUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSchoolUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sub
        firstName
        lastName
        email
        phoneNumber
        address
        status
        schoolREF
        schoolID
        school {
          id
          title
          phoneNumber
          fixeNumber
          description
          address
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSchoolUsers = /* GraphQL */ `
  query SyncSchoolUsers(
    $filter: ModelSchoolUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSchoolUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sub
        firstName
        lastName
        email
        phoneNumber
        address
        status
        schoolREF
        schoolID
        school {
          id
          title
          phoneNumber
          fixeNumber
          description
          address
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getStudent = /* GraphQL */ `
  query GetStudent($id: ID!) {
    getStudent(id: $id) {
      id
      firstName
      lastName
      phoneNumber
      email
      matricule
      birthday
      address
      schoolID
      school {
        id
        title
        phoneNumber
        fixeNumber
        description
        address
        students {
          nextToken
          startedAt
        }
        toTeachs {
          nextToken
          startedAt
        }
        schoolUsers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentGroupID
      studentGroup {
        id
        title
        description
        students {
          nextToken
          startedAt
        }
        courses {
          nextToken
          startedAt
        }
        liveCourses {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      schoolSubscriptions {
        items {
          id
          title
          amount
          dateStart
          dateEnd
          studentID
          schoolYearID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listStudents = /* GraphQL */ `
  query ListStudents(
    $id: ID
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStudents(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        firstName
        lastName
        phoneNumber
        email
        matricule
        birthday
        address
        schoolID
        school {
          id
          title
          phoneNumber
          fixeNumber
          description
          address
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentGroupID
        studentGroup {
          id
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        schoolSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStudents = /* GraphQL */ `
  query SyncStudents(
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStudents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstName
        lastName
        phoneNumber
        email
        matricule
        birthday
        address
        schoolID
        school {
          id
          title
          phoneNumber
          fixeNumber
          description
          address
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentGroupID
        studentGroup {
          id
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        schoolSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSchoolYear = /* GraphQL */ `
  query GetSchoolYear($id: ID!) {
    getSchoolYear(id: $id) {
      id
      title
      description
      schoolSubscriptions {
        items {
          id
          title
          amount
          dateStart
          dateEnd
          studentID
          schoolYearID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSchoolYears = /* GraphQL */ `
  query ListSchoolYears(
    $id: ID
    $filter: ModelSchoolYearFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSchoolYears(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        description
        schoolSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSchoolYears = /* GraphQL */ `
  query SyncSchoolYears(
    $filter: ModelSchoolYearFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSchoolYears(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        description
        schoolSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSchoolSubscription = /* GraphQL */ `
  query GetSchoolSubscription($id: ID!) {
    getSchoolSubscription(id: $id) {
      id
      title
      amount
      dateStart
      dateEnd
      studentID
      student {
        id
        firstName
        lastName
        phoneNumber
        email
        matricule
        birthday
        address
        schoolID
        school {
          id
          title
          phoneNumber
          fixeNumber
          description
          address
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentGroupID
        studentGroup {
          id
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        schoolSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      schoolYearID
      schoolYear {
        id
        title
        description
        schoolSubscriptions {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSchoolSubscriptions = /* GraphQL */ `
  query ListSchoolSubscriptions(
    $id: ID
    $filter: ModelSchoolSubscriptionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSchoolSubscriptions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        amount
        dateStart
        dateEnd
        studentID
        student {
          id
          firstName
          lastName
          phoneNumber
          email
          matricule
          birthday
          address
          schoolID
          studentGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        schoolYearID
        schoolYear {
          id
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSchoolSubscriptions = /* GraphQL */ `
  query SyncSchoolSubscriptions(
    $filter: ModelSchoolSubscriptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSchoolSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        amount
        dateStart
        dateEnd
        studentID
        student {
          id
          firstName
          lastName
          phoneNumber
          email
          matricule
          birthday
          address
          schoolID
          studentGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        schoolYearID
        schoolYear {
          id
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTeacher = /* GraphQL */ `
  query GetTeacher($id: ID!) {
    getTeacher(id: $id) {
      id
      firstName
      lastName
      phoneNumber
      email
      sphoneNumber
      address
      courses {
        items {
          id
          title
          docs
          description
          teacherID
          studentGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      liveCourses {
        items {
          id
          title
          dateStart
          dateEnd
          status
          teacherID
          studentGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      toTeachs {
        items {
          id
          status
          date
          teacherID
          schoolID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTeachers = /* GraphQL */ `
  query ListTeachers(
    $id: ID
    $filter: ModelTeacherFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTeachers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        firstName
        lastName
        phoneNumber
        email
        sphoneNumber
        address
        courses {
          nextToken
          startedAt
        }
        liveCourses {
          nextToken
          startedAt
        }
        toTeachs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTeachers = /* GraphQL */ `
  query SyncTeachers(
    $filter: ModelTeacherFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTeachers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstName
        lastName
        phoneNumber
        email
        sphoneNumber
        address
        courses {
          nextToken
          startedAt
        }
        liveCourses {
          nextToken
          startedAt
        }
        toTeachs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getStudentGroup = /* GraphQL */ `
  query GetStudentGroup($id: ID!) {
    getStudentGroup(id: $id) {
      id
      title
      description
      students {
        items {
          id
          firstName
          lastName
          phoneNumber
          email
          matricule
          birthday
          address
          schoolID
          studentGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      courses {
        items {
          id
          title
          docs
          description
          teacherID
          studentGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      liveCourses {
        items {
          id
          title
          dateStart
          dateEnd
          status
          teacherID
          studentGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listStudentGroups = /* GraphQL */ `
  query ListStudentGroups(
    $id: ID
    $filter: ModelStudentGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStudentGroups(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        description
        students {
          nextToken
          startedAt
        }
        courses {
          nextToken
          startedAt
        }
        liveCourses {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStudentGroups = /* GraphQL */ `
  query SyncStudentGroups(
    $filter: ModelStudentGroupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStudentGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        description
        students {
          nextToken
          startedAt
        }
        courses {
          nextToken
          startedAt
        }
        liveCourses {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      title
      docs
      description
      teacherID
      teacher {
        id
        firstName
        lastName
        phoneNumber
        email
        sphoneNumber
        address
        courses {
          nextToken
          startedAt
        }
        liveCourses {
          nextToken
          startedAt
        }
        toTeachs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentGroupID
      studentgroup {
        id
        title
        description
        students {
          nextToken
          startedAt
        }
        courses {
          nextToken
          startedAt
        }
        liveCourses {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $id: ID
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCourses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        docs
        description
        teacherID
        teacher {
          id
          firstName
          lastName
          phoneNumber
          email
          sphoneNumber
          address
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentGroupID
        studentgroup {
          id
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCourses = /* GraphQL */ `
  query SyncCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCourses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        docs
        description
        teacherID
        teacher {
          id
          firstName
          lastName
          phoneNumber
          email
          sphoneNumber
          address
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentGroupID
        studentgroup {
          id
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLiveCourse = /* GraphQL */ `
  query GetLiveCourse($id: ID!) {
    getLiveCourse(id: $id) {
      id
      title
      dateStart
      dateEnd
      status
      teacherID
      teacher {
        id
        firstName
        lastName
        phoneNumber
        email
        sphoneNumber
        address
        courses {
          nextToken
          startedAt
        }
        liveCourses {
          nextToken
          startedAt
        }
        toTeachs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      studentGroupID
      studentGroup {
        id
        title
        description
        students {
          nextToken
          startedAt
        }
        courses {
          nextToken
          startedAt
        }
        liveCourses {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLiveCourses = /* GraphQL */ `
  query ListLiveCourses(
    $id: ID
    $filter: ModelLiveCourseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLiveCourses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        dateStart
        dateEnd
        status
        teacherID
        teacher {
          id
          firstName
          lastName
          phoneNumber
          email
          sphoneNumber
          address
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentGroupID
        studentGroup {
          id
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLiveCourses = /* GraphQL */ `
  query SyncLiveCourses(
    $filter: ModelLiveCourseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLiveCourses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        dateStart
        dateEnd
        status
        teacherID
        teacher {
          id
          firstName
          lastName
          phoneNumber
          email
          sphoneNumber
          address
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        studentGroupID
        studentGroup {
          id
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getToTeach = /* GraphQL */ `
  query GetToTeach($id: ID!) {
    getToTeach(id: $id) {
      id
      status
      date
      teacherID
      teacher {
        id
        firstName
        lastName
        phoneNumber
        email
        sphoneNumber
        address
        courses {
          nextToken
          startedAt
        }
        liveCourses {
          nextToken
          startedAt
        }
        toTeachs {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      schoolID
      school {
        id
        title
        phoneNumber
        fixeNumber
        description
        address
        students {
          nextToken
          startedAt
        }
        toTeachs {
          nextToken
          startedAt
        }
        schoolUsers {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listToTeaches = /* GraphQL */ `
  query ListToTeaches(
    $id: ID
    $filter: ModelToTeachFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listToTeaches(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        status
        date
        teacherID
        teacher {
          id
          firstName
          lastName
          phoneNumber
          email
          sphoneNumber
          address
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        schoolID
        school {
          id
          title
          phoneNumber
          fixeNumber
          description
          address
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncToTeaches = /* GraphQL */ `
  query SyncToTeaches(
    $filter: ModelToTeachFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncToTeaches(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        status
        date
        teacherID
        teacher {
          id
          firstName
          lastName
          phoneNumber
          email
          sphoneNumber
          address
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        schoolID
        school {
          id
          title
          phoneNumber
          fixeNumber
          description
          address
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      title
      description
      prices {
        items {
          id
          title
          amount
          description
          ProductID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      orderLines {
        items {
          id
          amount
          productID
          orderID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $id: ID
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProducts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        description
        prices {
          nextToken
          startedAt
        }
        orderLines {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProducts = /* GraphQL */ `
  query SyncProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        description
        prices {
          nextToken
          startedAt
        }
        orderLines {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPrice = /* GraphQL */ `
  query GetPrice($id: ID!) {
    getPrice(id: $id) {
      id
      title
      amount
      description
      ProductID
      product {
        id
        title
        description
        prices {
          nextToken
          startedAt
        }
        orderLines {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPrices = /* GraphQL */ `
  query ListPrices(
    $id: ID
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPrices(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        amount
        description
        ProductID
        product {
          id
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPrices = /* GraphQL */ `
  query SyncPrices(
    $filter: ModelPriceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPrices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        amount
        description
        ProductID
        product {
          id
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      firstName
      lastName
      phoneNumber
      email
      sphoneNumber
      address
      orders {
        items {
          id
          title
          description
          amount
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $id: ID
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClients(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        firstName
        lastName
        phoneNumber
        email
        sphoneNumber
        address
        orders {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClients = /* GraphQL */ `
  query SyncClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstName
        lastName
        phoneNumber
        email
        sphoneNumber
        address
        orders {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      title
      description
      amount
      ClientID
      client {
        id
        firstName
        lastName
        phoneNumber
        email
        sphoneNumber
        address
        orders {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      orderLines {
        items {
          id
          amount
          productID
          orderID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $id: ID
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrders(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        description
        amount
        ClientID
        client {
          id
          firstName
          lastName
          phoneNumber
          email
          sphoneNumber
          address
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        orderLines {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOrders = /* GraphQL */ `
  query SyncOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        description
        amount
        ClientID
        client {
          id
          firstName
          lastName
          phoneNumber
          email
          sphoneNumber
          address
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        orderLines {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrderLine = /* GraphQL */ `
  query GetOrderLine($id: ID!) {
    getOrderLine(id: $id) {
      id
      amount
      productID
      product {
        id
        title
        description
        prices {
          nextToken
          startedAt
        }
        orderLines {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      orderID
      order {
        id
        title
        description
        amount
        ClientID
        client {
          id
          firstName
          lastName
          phoneNumber
          email
          sphoneNumber
          address
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        orderLines {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listOrderLines = /* GraphQL */ `
  query ListOrderLines(
    $id: ID
    $filter: ModelOrderLineFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrderLines(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        amount
        productID
        product {
          id
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        orderID
        order {
          id
          title
          description
          amount
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOrderLines = /* GraphQL */ `
  query SyncOrderLines(
    $filter: ModelOrderLineFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrderLines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        amount
        productID
        product {
          id
          title
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        orderID
        order {
          id
          title
          description
          amount
          ClientID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
