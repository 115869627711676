import React, {useState} from "react";
import {Route, Routes, useParams} from "react-router-dom";
import AuthorUserList from "./AuthorUserList";
import AuthorUserDetails from "./AuthorUserDetails";


export default function AuthorUsers() {
    return (
        <Routes>
            <Route path={""} element={<AuthorUserList />} />
            <Route path={":authorUserId/*"} element={<AuthorUserDetails />} />
        </Routes>
    )
}