import React, { useEffect, useState } from "react";
import { Authenticator, Image, View } from "@aws-amplify/ui-react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "@aws-amplify/ui-react/styles.css";
import Layout from "./components/Layout/Layout";
import * as models from "./models";
import {
  Amplify,
  DataStore,
  Hub,
  syncExpression,
  I18n,
  Auth,
} from "aws-amplify";
import awsExports from "./aws-exports";

const modelsToSync = [
  "Author",
  "AuthorStatus",
  "AuthorUser",
  "Book",
  "BookLike",
  "BookRating",
  "BookStatus",
  "BookType",
  "Category",
  "Draft",
  "Family",
  "FollowAuthor",
  "ReaderUser",
  "S3Object",
  "PurchaseStatus",
];

const allModels = Object.keys(models);
const modelsToDisable = allModels.filter(
  (m) => !modelsToSync.find((mod) => mod === m) && !!models[m]
);

Amplify.configure({
  ...awsExports,
  DataStore: {
    syncExpressions: [
      ...modelsToDisable.map((m) =>
        syncExpression(models[m], () => {
          return (data) => data.id("eq", "NEVER_MATCH_ME");
        })
      ),
    ],
  },
});

export default function App() {
  const components = {
    Header() {
      return (
        <View textAlign="center">
          <Image alt="Read-ing" src="logo.jpg" />
        </View>
      );
    },
  };
  const [user, setUSer] = useState();

  return (
    <ThemeProvider theme={theme}>
      <Authenticator components={components} hideSignUp={true}>
        {({ signOut, user }) => <UserChecker user={user} signOut={signOut} />}
      </Authenticator>
    </ThemeProvider>
  );
}

function UserChecker({ signOut, user }) {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (user) {
      if (
        !user.signInUserSession?.accessToken?.payload["cognito:groups"] ||
        !user.signInUserSession?.accessToken?.payload[
          "cognito:groups"
        ].includes("Admin")
      ) {
        signOut();
      } else {
        setReady(true);
      }
    }
  }, [user]);

  return ready ? <Layout /> : null;
}

//theme
const theme = createTheme({
  palette: {
    type: "dark",
    info: {
      main: "#000000",
    },
    primary: {
      main: "#f39200",
    },
    secondary: {
      main: "#e8c89b",
    },
    background: {
      default: "#f6f6f6",
    },
    grillColor: {
      default: "#f39200",
    },
  },
  shape: {
    borderRadius: 12,
  },
});
