import React, {useEffect, useState} from 'react'
import {
    Avatar, Button,
    Card, CardActions,
    CardContent,
    CardHeader, Chip,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Grid, IconButton, Link,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DataStore, Storage} from "aws-amplify";
import {Author, AuthorUser, Book, BookContent, Draft, DraftContent, FollowAuthor} from "../../models";
import {useParams} from "react-router-dom";
// import BookDraftList from "../Book/BookDraftList";
// import BookList from "../Book/Published/BookList";
import {tdb} from "../Services/translateJSON";
import PurchaseList from "../Purchase/PurchaseList";
import {AccountCircle, Edit} from "@mui/icons-material";
import AuthorUserDelete from "./AuthorUserDelete";
import {bestColSize} from "../Services/bestColSize";
import AuthorUserEdit from "./AuthorUserEdit";
import BooksDrafts from "../Book/BooksDrafts";
import BooksPublished from "../Book/BooksPublished";
import LikeList from "../Like/LikeList";
import RatingList from "../Rating/RatingList";
import FollowList from "../Follow/FollowList";
import {chooseCorrectKey} from "../Services/correctPath";

export default function AuthorUserDetails({onClose, open}) {
    const {t, i18n} = useTranslation();
    const {authorUserId} = useParams()

    //AuthorUser
    const [authorUser, setAuthorUser] = useState("");
    const fetchAuthorUser = async () => setAuthorUser(await DataStore.query(AuthorUser, authorUserId))
    useEffect(()=>{
        if(authorUserId) {
            fetchAuthorUser();
            const authorUserSubscribe = DataStore.observe(AuthorUser, authorUserId).subscribe(()=>fetchAuthorUser())
            return () => authorUserSubscribe.unsubscribe()
        }
    }, [authorUserId])

    //Author
    const [authors, setAuthors] = useState([]);
    const fetchAuthors = async () => setAuthors((await DataStore.query(Author)).filter(a=>a.sub == authorUser.sub))

    //BOOK
    const [books, setBooks] = useState([]);
    const fetchBooks = async () => setBooks((await DataStore.query(Book)).filter(b => b.sub === authorUser.sub))
    
    //BOOK CONTENT
    const [bookContents, setBookContents] = useState([]);
    const fetchBookContents = async () => setBookContents((await DataStore.query(BookContent)).filter(b => b.sub === authorUser.sub))

    //DRAFT
    const [drafts, setDrafts] = useState([]);
    const fetchDrafts = async () => setDrafts((await DataStore.query(Draft)).filter(d => d.sub === authorUser.sub))
    
    //DRAFT CONTENT
    const [draftContents, setDraftContents] = useState([]);
    const fetchDraftContents = async () => setDraftContents((await DataStore.query(DraftContent)).filter(d => d.sub == authorUser.sub))
    
    useEffect(()=>{if(authorUser)  {
        fetchBooks();
        fetchBookContents();
        fetchDrafts()
        fetchDraftContents();
        fetchAuthors()
        const obsA  = DataStore.observe(Author      ).subscribe(msg=>{if(msg.element.sub===authorUser.sub)fetchAuthors()})
        const obsB  = DataStore.observe(Book        ).subscribe(msg=>{if(msg.element.sub===authorUser.sub)fetchBooks()})
        const obsBC = DataStore.observe(BookContent ).subscribe(msg=>{if(msg.element.sub===authorUser.sub)fetchBookContents()})
        const obsD  = DataStore.observe(Draft       ).subscribe(msg=>{if(msg.element.sub===authorUser.sub)fetchDrafts()})
        const obsDC = DataStore.observe(DraftContent).subscribe(msg=>{if(msg.element.sub===authorUser.sub)fetchDraftContents()})
        return ()=>{
            obsA.unsubscribe();
            obsB.unsubscribe();
            obsBC.unsubscribe();
            obsD.unsubscribe();
            obsDC.unsubscribe();
        }
    }}, [authorUser])
    
    const [deleteMode, setDeleteMode] = useState();
    const [editMode, setEditMode] = useState();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader
                        title={t('authorUser.user')}
                        action={<IconButton
                            onClick={()=>setEditMode(true)}
                        >
                            <Edit />
                        </IconButton>}
                    />
                    <CardContent>
                        {editMode && <AuthorUserEdit authorUserId={authorUserId} onClose={()=>setEditMode(false)} />}
                        <Grid container spacing={4}>
                            <Grid item xs={4}>
                                <Grid container>
                                    <DataLine label={t('authorUser.gender')} value={authorUser && authorUser.gender} />
                                    <DataLine label={t('authorUser.firstName')} value={authorUser && authorUser.firstName} />
                                    <DataLine label={t('authorUser.name')} value={authorUser && authorUser.lastName} />
                                    <DataLine label={t('authorUser.birthday')} value={authorUser && authorUser.birthday} />
                                    <DataLine value={authorUser && authorUser.identity} cols={[0,12]} />
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container>
                                    <DataLine label={t('authorUser.phone')} value={authorUser && authorUser.phone} />
                                    <DataLine label={t('authorUser.mail')} value={authorUser && authorUser.email} />
                                    <DataLine label={t('authorUser.address')} value={authorUser && authorUser.address} />
                                    <DataLine label={t('authorUser.zip')} value={authorUser && authorUser.zip} />
                                    <DataLine label={t('authorUser.city')} value={authorUser && authorUser.city} />
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container>
                                    <DataLine label={t('authorUser.form.validated')} value={authorUser && authorUser.validated} cols={[8,4]} boolean={["YES", "NO"]} />
                                    <DataLine label={t('authorUser.form.accessDocumentation')} value={authorUser && authorUser.accessDocumentation} cols={[8,4]} boolean={["YES", "NO"]} />
                                    <DataLine label={t('authorUser.form.setOtherAuthor')} value={authorUser && authorUser.setOtherAuthor} cols={[8,4]} boolean={["YES", "NO"]} />
                                    <DataLine label={t('authorUser.form.unsubscribe')} value={authorUser && authorUser.unsubscribe} cols={[8,4]} boolean={["YES", "NO"]} />
                                    <DataLine label={t('authorUser.form.status')} value={authorUser && authorUser.status} cols={[8,4]} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Button onClick={()=>setDeleteMode(true)} variant={"contained"}>
                            Supprimer
                        </Button>
                        {deleteMode && <AuthorUserDelete id={authorUserId} onClose={()=>setDeleteMode(false)} open={true} />}
                    </CardActions>
                </Card>
            </Grid>
            {authors.map((author, index)=>(<Grid item xs={bestColSize(authors.length, index)}><AuthorPublicProfile author={author} /></Grid>))}
            {authorUser && !!authorUser.id && <Grid item xs={12}>
                <BooksDrafts authorUserId={authorUser.id}/>
            </Grid>}
            {authors.length > 0 &&
                <Grid item xs={12}>
                    <BooksPublished authorIds={authors.map(a=>a.id)} />
                </Grid>}
            {authorUser && !!authorUser.sub && <Grid item xs={12}>
                <PurchaseList authorSub={authorUser.sub} />
            </Grid> }
            {authorUser && !!authorUser.sub && <Grid item xs={12}>
                <LikeList authorSub={authorUser.sub} />
            </Grid> }
            {authorUser && !!authorUser.sub && <Grid item xs={12}>
                <RatingList authorSub={authorUser.sub} />
            </Grid> }
            {authorUser && !!authorUser.sub && <Grid item xs={12}>
                <FollowList authorSub={authorUser.sub} />
            </Grid> }
        </Grid>
    )
}

function AuthorPublicProfile({author}) {
    const [followers, setFollowers] = useState([]);
    const fetchFollowers = async () => {
        const _followers = await DataStore.query(FollowAuthor);
        console.debug("_followers", _followers)
        setFollowers(_followers.filter(f=>f.author && f.author.id === author.id && f.like === true))
    }
    
    const [avatarURL, setAvatarURL] = useState("");
    const fetchAvatar = async () => {
        console.debug("author", author);
        if (author.avatarKeys && author.avatarKeys.sm) {
            setAvatarURL(await Storage.get(chooseCorrectKey(author.avatarKeys.sm, {level: 'protected'}), {level: 'protected', identityId: author.avatarKeys.identity}))
        }
    }
    useEffect(()=> {
        fetchAvatar()
        fetchFollowers();
    }, [author])
    
    return <Card>
        <CardHeader
            avatar={<Avatar style={{width: 50, height: 50}} src={avatarURL} />}
            title={<Typography variant={"h6"}>{`${author.firstName} ${author.name}`}</Typography>}
            action={followers.length > 0 && <Chip
                color="primary"
                icon={<AccountCircle />}
                label={`${followers.length} followers`}
            />}
        />
        <CardContent style={{height: 120, overflow: 'auto'}}>
            <Typography variant={"body2"}>{tdb(author.biography)}</Typography>
        </CardContent>
    </Card>
}

function DataLine({label, value, cols = [4,8], noBorder=false, boolean=false}) {
    return <>
        <Grid item xs={cols[0]} borderTop={noBorder ? 0 : 1} borderColor={"#e0e0e0"}>
            <Typography fontSize={14} color={"#999"}>
                {label}
            </Typography>
        </Grid>
        <Grid item xs={cols[1]} borderTop={noBorder ? 0 : 1} borderColor={"#e0e0e0"}>
            <Typography variant={"body1"}>
                {boolean && !!value === value
                    ? (value ? boolean[0] : boolean[1])
                    : (value || "-")
                }
            </Typography>
        </Grid>
    </>
}
