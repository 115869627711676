import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DataStore} from "aws-amplify";
import {Category, Family} from "../../models";
import {tdb} from "../Services/translateJSON";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    FormControlLabel,
    Checkbox,
    MenuItem, Switch, Box
} from "@mui/material";
import {Cancel, Save} from "@mui/icons-material";

export default function CategoryEdit({open, onClose, catId}) {
    const {t, in18} = useTranslation();
    const [category, setCategory] = useState([]);
    const [families, setFamilies] = useState([])

    //FETCH
    const fetchCategory = async () => setCategory(await DataStore.query(Category, catId))
    const fetchFamilies = async () => setFamilies(await DataStore.query(Family))
    useEffect(()=>{
        fetchFamilies()
        fetchCategory()
        const catSubscribe = DataStore.observe(Category).subscribe(()=>fetchCategory())
        return () => {
            catSubscribe.unsubscribe()
        }
    }, [catId])

    //FORM
    const [form, setForm] = useState({
        title: "",
        name: "",
        description: "",
        content: "",
        family:"",
        active: false,
        order: 0
    })
    useEffect(() => {
        if(category) {
            setForm({
                name: category.name,
                title: category.title && tdb(category.title.default),
                description: category.description && tdb(category.description.default),
                content: category.content && tdb(category.content.default),
                active: category.active,
                family: category.family && category.family.id,
                order: category.order
            })
        }
    },[category])
    
    //UPDATE
    const handleUpdate = async () => {
        try {
            const _categoryUpdate = Category.copyOf(category, updated => {
                updated.name = (form.name);
                updated.title = JSON.stringify({default: form.title});
                updated.description = JSON.stringify({default: form.description});
                updated.content = JSON.stringify({default: form.content});
                updated.active = form.active;
                updated.familyID = form.family;
                updated.order = parseInt(form.order);
            })
            await DataStore.save(_categoryUpdate)
            if (onClose) onClose()
        } catch (e) {
            console.log(e);
        }
    }

    return <Dialog open={open} onClose={onClose} fullWidth>
        <DialogTitle>{t("generic.Edit")}</DialogTitle>
        <DialogContent>
            {category && form && <Grid container spacing={2}
                               component="form"
                               noValidate
                               autoComplete="off"
                               mt={1}
            >
                <Grid item xs={6}>
                    {!!families.find(f=>f.id===form.family) && <TextField
                        select
                        variant={"outlined"}
                        label={t('category.Family')}
                        fullWidth
                        value={form.family}
                        onChange={event => setForm(state => ({...state, family: event.target.value}))}
                    >
                        {families.map(f=>(<MenuItem key={f.id} value={f.id}>{tdb(f.title.default)}</MenuItem>))}
                    </TextField>}
                </Grid>
                <Grid item xs={3}>
                    <Box p={1}>
                        <FormControlLabel
                            control={<Switch checked={Boolean(form.active)} value={1} />}
                            label={t("generic.Activate")}
                            onChange={event => setForm(state => ({...state, active: event.target.checked}))}
                        />
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        value={form.order || ""}
                        label={t('generic.Order')}
                        placeholder={t('generic.Order')}
                        variant="outlined"
                        type={"number"}
                        onChange={event => setForm(state => ({...state, order: event.target.value}))}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        value={form.title || ""}
                        label={t('generic.Title')}
                        placeholder={t('generic.Title')}
                        variant="outlined"
                        onChange={event => setForm(state => ({...state, title: event.target.value}))}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={t('generic.Name')}
                        variant="outlined"
                        helperText={t('family.HelpName')}
                        value={form.name || ""}
                        onChange={event => setForm(state => ({...state, name: event.target.value}))}
                        InputProps={{pattern: "[a-z]{3,*}"}}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={t('family.Description')}
                        helperText={t('family.HelpDescription')}
                        variant="outlined"
                        multiline
                        rows={6}
                        value={form.description || ""}
                        onChange={event => setForm(state => ({...state, description: event.target.value}))}
                    />
    
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={t('family.Content')}
                        helperText={t('family.HelpContent')}
                        variant="outlined"
                        multiline
                        rows={6}
                        value={form.content || ""}
                        onChange={event => setForm(state => ({...state, content: event.target.value}))}
                    />
                </Grid>
            </Grid>}
        </DialogContent>
        <DialogActions>
            <Button startIcon={<Cancel />} variant="contained"onClick={onClose} color="secondary">{t("generic.Cancel")}</Button>
            <Button startIcon={<Save />} onClick={handleUpdate} variant="contained" color="primary">{t("generic.Update")}</Button>
        </DialogActions>
    </Dialog>
}