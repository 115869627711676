import React, { Fragment, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Snackbar,
  Switch,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Cancel, Save } from "@mui/icons-material";
import { DataStore, Storage } from "aws-amplify";
import { Product } from "../../models";
import { tdb } from "../Services/translateJSON";
import { t } from "i18next";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function SettingsCreate({ open, onClose }) {
  //SAVE
  const handleSave = async () => {
    const _form = {
      title: "",
      description: "",
    };
  };

  return (
    <Fragment>
      <Dialog maxWidth={"lg"} open={true} onClose={onClose}>
        <DialogTitle> <h6>AJOUT DE CONDITIONS GÉNÉRALES D'UTILISATION</h6></DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
          <Grid item xs={12}>
            <CKEditor
              editor={ClassicEditor}
              // onChange={this.handleMessageChange}
            />
          </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<Cancel />}
            variant="contained"
            onClick={onClose}
            //onClick={()=>{window.location.reload(false)}}
            color="secondary"
          >
            {t("generic.Cancel")}
          </Button>
          <Button
            startIcon={<Save />}
            onClick={handleSave}
            variant="contained"
            color="primary"
          >
            {t("generic.Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default SettingsCreate;
