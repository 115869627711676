import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataStore } from "aws-amplify";
import { Product } from "../../models";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import {
  Add,
  AddCircle,
  Close,
  DeleteForever,
  Edit,
  Search,
  VisibilitySharp,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
  Typography,
} from "@mui/material";
import { Route, Routes, useNavigate } from "react-router-dom";
import SettingsCreate from "./SettingsCreate";

function SettingsList() {

    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const [createMode, setCreateMode] = useState(false);
    const handleCreate = () => setCreateMode(true);
    const handleCancelCreate = () => setCreateMode(false);

    return (
    <Fragment>
        <p>CONDITIONS GÉNÉRALES D'UTILISATION</p>
      <Button
        startIcon={<AddCircle />}
        className={classes.mt}
        variant="contained"
        onClick={() => setCreateMode(true)}>
        {t("generic.Add")}
      </Button>
      {createMode && (<SettingsCreate onClose={() => setCreateMode(false)} />)}
    </Fragment>
  );
}
const useStyles = makeStyles(() => ({
    mt: {
      marginTop: 10,
    },
  }));
export default SettingsList;
