import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DataStore} from "aws-amplify";
import {Draft} from "../../models";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function ChartBooks() {
    const {t, i18n} = useTranslation();

    //ALLBOOKS
    const [drafts, setDrafts] = useState([]);
    const [published, setPublished] = useState([]);
    const [declined, setDeclined] = useState([]);
    const [publicationWanted, setPublicationWanted] = useState([]);

    const fetchAllBooks = async () => {
        const _drafts = await DataStore.query(Draft);
        setDrafts(_drafts.filter(d => d.status === "Draft"));
        setDeclined(_drafts.filter(d => d.status === "PublicationDeclined"));
        setPublished(_drafts.filter(d => d.status === "Published"));
        setPublicationWanted(_drafts.filter(d=>d.status === "PublicationWanted"))
    }

    useEffect(()=>{
        fetchAllBooks();
        const _allBooksSubscribe = DataStore.observe(Draft).subscribe(()=>fetchAllBooks());
        return () => {
            _allBooksSubscribe.unsubscribe()
        }
    }, [])


    //DATAS CHART

    const options = {
        responsive: true,
        plugins : {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: `${t('chart.bookStatus')}`
            }
        }
    }


    const labels = [`${t('chart.bookStatus')}`]

    const data = {
        labels,

        datasets: [
            {
                label: `${t("chart.publishedBook")}`,
                data: `${published.length}`,
                backgroundColor: 'rgb(243,146,0)',
            },
            {
                label: `${t("chart.draft")}`,
                data: `${drafts.length}`,
                backgroundColor: 'rgb(232,200,155)',

            },
            {
                label: `${t("chart.publicationWanted")}`,
                data: `${publicationWanted.length}`,
                backgroundColor: 'rgba(38,214,255,0.5)'

            },
            {
                label: `${t("chart.declined")}`,
                data: `${declined.length}`,
                backgroundColor: 'rgba(255, 99, 132, 0.5)'

            },

        ]
    }
    return (
        <Bar options options={options} data={data} />
    )
}