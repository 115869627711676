import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {Done, Edit, HourglassEmpty, VisibilityOff, Warning} from "@mui/icons-material";
import {Avatar, Chip, Tooltip} from "@mui/material";

const statuses = {
    Draft: {
        color: "secondary",
        icon: <Edit />
    },
    Finalized: {
        color: "primary",
        icon: <Done />
    },
    PublicationWanted: {
        color: "error",
        icon: <HourglassEmpty />
    },
    PublicationDeclined: {
        color: "warning",
        icon: <Warning />
    },
    Published: {
        color: "success",
        icon: <Done />
    },
    Unpublished: {
        color: "error",
        icon: <VisibilityOff />
    },
    Updating: {
        color: "warning",
        icon: <Edit />
    },
    UpdateWanted: {
        color: "error",
        icon: <HourglassEmpty />
    },
}

export function BookStatusChip({status, active}) {
    const {t} = useTranslation()
    if (!statuses[status])return null;
    return <Chip color={statuses[status].color} icon={statuses[status].icon} label={t(`book.status.all.${status}`)} />
}

export function BookStatusIcon({status, active}) {
    const {t} = useTranslation()
    if (!statuses[status])return null;
    return <Tooltip title={t(`book.status.all.${status}`)}>
        <Avatar color={statuses[status].color} icon={statuses[status].icon} />
    </Tooltip>
}