import React from "react";
import clsx from "clsx";
import {FormControl, FormLabel} from "@mui/material";
import {makeStyles} from "@mui/styles";

export default function FieldSet({title, children, className}) {
    const classes = useStyles();
    return <FormControl fullWidth component={"fieldset"} className={clsx(className, classes.fieldset)}>
        {!!title && <FormLabel component={"legend"} className={classes.legend}>
            {title}
        </FormLabel>}
        <div className={classes.content}>
            {children}
        </div>
    </FormControl>
}

const useStyles = makeStyles((theme) => ({
    fieldset: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.grey[400],
        //boxShadow: theme.shadows[3],
        // padding: theme.spacing(1),

        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    legend: {
        fontSize: theme.typography.overline.fontSize,
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.paper,
        paddingLeft: 5,
        paddingRight: 5,
    },
    content: {
        paddingLeft: theme.spacing(1),
    }
}))