import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DataStore} from "aws-amplify";
import {Book, Category} from "../../models";
import {Alert} from "@mui/lab";
import {Cancel, WarningOutlined} from "@mui/icons-material";
import {tdb} from "../Services/translateJSON";

export default function CategoryDelete({onClose, open, id}) {
    const {t, i18n} = useTranslation();

    const [category, setCategory] = useState("");
    const [booksCategory, setBooksCategory] = useState([]);

    const fetchCategory = async () => {
        const _category = await DataStore.query(Category, id)
        setCategory(_category)
    }

    const fetchBooksCategory = async () => {
        const _booksCategory = await DataStore.query(Book)
        setBooksCategory(_booksCategory.filter(b => b.category.id === id ))
    }

    useEffect(()=>{
        if(id) {
            fetchCategory()
            fetchBooksCategory()
        }
    }, [id])

    const [error, setError] = useState(false);

    useEffect(()=>{
        if(booksCategory && booksCategory.length > 0) {
            setError(true)
        }
        else {
            setError(false)
        }
    }, [booksCategory])

    const handleDelete =  () => {
        try {
            if(category) {
                DataStore.delete(category)
            }
            if(onClose)onClose()
        }
        catch (e) {
            console.log(e)
        }
    }
    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>{t('category.delete-category')}</DialogTitle>
            <DialogContent>
                {t('category.delete-info')} {category && tdb(category.title)}
                {error && <Alert severity="error" square>{t('category.delete-error')}</Alert> }

            </DialogContent>
            <DialogActions>
                <Button startIcon={<Cancel />} variant="contained"onClick={onClose} color="secondary">{t("generic.Cancel")}</Button>
                <Button disabled={error} startIcon={<WarningOutlined />} onClick={handleDelete} variant="contained" color="warning">{t("generic.del")}</Button>

            </DialogActions>
        </Dialog>
    )
}