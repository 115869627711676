import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DataStore} from "aws-amplify";
import {Draft} from "../../models";
import IconButton from "@mui/material/IconButton";
import {VisibilitySharp} from "@mui/icons-material";
import {DataGrid} from "@mui/x-data-grid";
import {NavLink} from "react-router-dom";
import {Card, CardContent, CardHeader, CardMedia, Typography} from "@mui/material";
import {BookStatusChip} from "../Misc/Status";

export default function BookPublicationWantedList({limit = 100, actionButton = null}) {
    const {t, i18n} = useTranslation();

    //BOOK
    const [books, setBooks] = useState([]);
    const fetchBooks = async () => {
        const _Books = await DataStore.query(Draft)
        setBooks(_Books.filter(d=>["PublicationWanted", "UpdateWanted"].includes(d.status)))
    }
    useEffect(()=>{
        fetchBooks();
        const _booksSubscribe = DataStore.observe(Draft).subscribe(()=>fetchBooks())
        return () => {
            _booksSubscribe.unsubscribe();
        }
    }, [])

    //DATAGrid PARAMS
    const _renderActions = (params) => {
        return (1 || params.row === 0) && <>
            <IconButton size={"small"} component={NavLink}  to={params.id}><VisibilitySharp /></IconButton>
        </>;
    }

    const columns = [
        {field:'_version',      headerName: "Version", width:70 },
        {field:'title',         headerName: `${t('book.title')}`,       flex:1 },
        {field:'bookType',      headerName: `${t('book.type')}`,    width:80 },
        {field:'authorUSer',    headerName: `${t('authorUser.user')}`,  flex:1, renderCell: (params)=>(`${params.row.authorUser.firstName} ${params.row.authorUser.lastName}`) },
        {field:'draftContents', headerName: `${t('book.chapters')}`,    width:80, renderCell: (params)=>(`${params.row.draftContents ? params.row.draftContents.length : "0"}`) },
        {field:'status',        headerName: `${t('status')}`,           flex:1, renderCell: (params)=>(<BookStatusChip status={params.value} active={true} />) },
        {field:'action',        headerName: 'Action', width: 150, renderCell: _renderActions, disableColumnMenu: true }
    ]
    const rows = books;

    return <Card>
        <CardHeader
            title={<Typography variant={"h5"}>{t("book.publicationWanted")}</Typography> }
            action={actionButton}
        />
        <CardContent>
            <DataGrid
                density={"compact"}
                autoHeight
                pageSize={limit}
                hideFooter={rows.length <= limit}
                rows={rows}
                columns={columns}
            />
        </CardContent>
    </Card>

}