import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {DataStore} from "aws-amplify";
import {ReaderUser, Book, Draft, Purchase} from "../../models";
import IconButton from "@mui/material/IconButton";
import {Check, Close, Delete, Search, VisibilitySharp} from "@mui/icons-material";
import {Card, CardContent, CardHeader, TextField, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";


export default function ReaderUserList({}) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    
    //ReaderUser
    const [readerUsers, setReaderUsers] = useState([]);
    const fetchReaderUsers = async () => setReaderUsers(await DataStore.query(ReaderUser));
    
    const [purchases, setPurchases] = useState([]);
    const fetchPurchases = async () => setPurchases(await DataStore.query(Purchase));
    
    useEffect(()=>{
        fetchReaderUsers();
        fetchPurchases();
        const obsReaderUser = DataStore.observe(ReaderUser).subscribe(()=>fetchReaderUsers())
        const obsPurchase = DataStore.observe(Purchase).subscribe(()=>fetchPurchases())
        return () => {
            obsReaderUser.unsubscribe()
            obsPurchase.unsubscribe()
        }
    }, [])
    
    //DATAGrid PARAMS
    const _renderActions = (params) => {
        return (1 || params.row === 0) && <>
            <IconButton size={"small"} onClick={()=>navigate(params.row.id)}><VisibilitySharp /></IconButton>
            {/*{purchases.filter(p=>p.readerUser.id===params.row.id).length === 0 && <IconButton size={"small"} onClick={() => handleDelete(params.row)}><Delete/></IconButton>}*/}
        </>;
    }
    
    const _renderBoolean = (params) => {
        return params.value ? <Check /> : ""
    }
    
    const columns = [
        {field: '_version',     headerName: "v.",           width:40 },
        {field: 'id',           headerName: "ID",           width:300, hide: true},
        {field: 'sub',          headerName: "Sub",          width:300, hide: true },
        {field: 'email',        headerName: "Email",        flex:2 },
        {field: 'firstName',    headerName: "Prénom",       flex:1 },
        {field: 'lastName',     headerName: "Nom",          flex:1 },
        {field: 'city',         headerName: "Ville",        flex:1 },
        {field: 'country',      headerName: "Country",      width:100 },
        {field: 'phone',        headerName: "Phone",        flex:1 },
        {field: 'credits',      headerName: "Credits",      width:100 },
        {field: 'unsubscribe',  headerName: "Unsubscribe",  width:60, renderCell: _renderBoolean},
        {field: 'purchases',    headerName: "Achats",       width: 60, renderCell: params => (purchases.filter(p=>p.readerUser.id === params.row.id).length) || ""},
        {field: 'action',       headerName: ' ',            width: 80, renderCell: _renderActions, disableColumnMenu: true, align: 'right' }
    ]
    
    const [filter, setFilter] = useState("");
    const [rows, setRows] = useState([]);
    
    useEffect(()=>{
        if (filter) {
            setRows(readerUsers
                .filter(reader=>
                    (reader.firstName && reader.firstName.toLowerCase().includes(filter.toLowerCase()))
                    ||
                    (reader.lastName && reader.lastName.toLowerCase().includes(filter.toLowerCase()))
                    ||
                    (reader.email && reader.email.toLowerCase().includes(filter.toLowerCase()))
                )
            )
        }
        else {
            setRows(readerUsers)
        }
        
    }, [readerUsers, filter])
    
    
    // const rows = readerUsers;
    
    
    
    return (
        <Card>
            <CardHeader
                title={<Typography variant={"h5"}>{t("reader.readerList")}</Typography> }
            />
            <CardContent>
                <TextField
                    value={filter}
                    InputProps={{
                        startAdornment: <Search />,
                        endAdornment: filter !== "" && <IconButton onClick={()=>setFilter("")}><Close /></IconButton>
                    }}
                    size={"small"}
                    onChange={event=>setFilter(event.target.value)}
                />
            </CardContent>
            <CardContent>
                <DataGrid
                    density={"compact"}
                    autoHeight
                    rows={rows}
                    columns={columns}
                />
            </CardContent>
        </Card>
    )
}