import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Amplify, {DataStore, Storage} from "aws-amplify";
import {DraftContent} from "../../../models";
import {v4 as uuidv4} from "uuid";
import {
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import FieldSet from "../../Misc/FieldSet";
import {Save, Upload} from "@mui/icons-material";
import prettySize from "../../Services/prettySize";
import {styled} from "@mui/styles";
import awsExports from "../../../aws-exports";
import awsconfig from "../../../aws-exports";

Amplify.configure(awsconfig);

export default function BookDraftAudioEdit({draftId, contentId, onClose}) {
    const {t, i18n} = useTranslation();
    const [title, setTitle] = useState("");
    const [label, setLabel] = useState("");
    const [order, setOrder] = useState("");
    
    const [audioData, setAudioData] = useState();
    const [audioFreeData, setAudioFreeData] = useState();
    
    const [audioFile, setAudioFile] = useState();
    const [audioFreeFile, setAudioFreeFile] = useState();
    const handleAudioFile = async (e) => setAudioFile(e.target.files[0])
    const handleAudioFreeFile = async (e) => setAudioFreeFile(e.target.files[0])

    const [content, setContent]   = useState();
    const fetchContent = async () => setContent(( await DataStore.query(DraftContent, contentId)));
    useEffect(()=>{
        if (draftId && contentId) {
            fetchContent();
        }
    }, [draftId, contentId]);

    useEffect(()=>{
        if (content) {
            setAudioData(content.audioData);
            setAudioFreeData(content.freeAudioData)
            setTitle(content.title);
            setLabel(content.label);
            setOrder(content.order)
        }
    }, [content])

    const handleSave = async () => {
        try {
            // upload audio, if changed
            let _uploadAudio
            if (audioFile) {
                const uuid = uuidv4();
                _uploadAudio = await Storage.put(uuid, audioFile, {
                    level: 'private',
                    contentType: audioFile.type
                });
            }
    
            // upload free audio, if changed
            let _uploadFree;
            if (audioFreeFile) {
                const uuidFree = uuidv4();
                _uploadFree = await Storage.put(uuidFree, audioFreeFile, {
                    level: "public",
                    contentType: audioFreeFile.type
                });

            }
            
            // update
            await DataStore.save(DraftContent.copyOf(content, updater=> {
                updater.title = title;
                updater.order = order;
                updater.label = label;
                
                // save only if new file was uploaded
                if (_uploadAudio && _uploadAudio.key && audioFile) {
                    updater.audioData = {
                        name: audioFile.name,
                        key: _uploadAudio.key,
                        size: audioFile.size,
                        type: audioFile.type,
                    };
                }
    
                // save only if new file was uploaded
                if (_uploadFree && _uploadFree.key && audioFreeFile) {
                    updater.freeAudioData = {
                        name: audioFreeFile.name,
                        key: _uploadFree.key,
                        size: audioFreeFile.size,
                        type: audioFile.type,
                    }
                }
            }))
            if(onClose)onClose()
        }
        catch (e) {
            console.error(e)
        }
    }

    return <Dialog open={true} onClose={onClose} maxWidth={"md"} fullWidth>
        <DialogTitle sx={{backgroundColor: 'primary.main'}} >
            {t('book.content.operations.update-dialog')}
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={8}>
                    <TextField
                        variant={"outlined"}
                        label={t('book.content.fields.label')}
                        value={label}
                        onChange={event => setLabel(event.target.value)}
                        helperText={t('book.content.fields.label-helper')}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        variant={"outlined"}
                        label={t('book.content.fields.order')}
                        type={"number"}
                        value={order}
                        onChange={event => setOrder(event.target.value)}
                        helperText={t('book.content.fields.order-helper')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant={"outlined"}
                        label={t('book.content.fields.title')}
                        value={title}
                        onChange={event => setTitle(event.target.value)}
                        helperText={t('book.content.fields.title-helper')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldSet title={t('book.content.fields.audio-file')}>
                        <Typography variant={"body1"}>
                            {t('book.content.fields.audio-file-helper')}
                        </Typography>
                        <Grid container flexDirection={"row"} mt={1}>
                            <Grid>
                                <label htmlFor="contained-button-file">
                                    <Input accept="audio/*" id="contained-button-file" type="file" onChange={handleAudioFile} />
                                    <Button
                                        variant="contained"
                                        color={"secondary"}
                                        component="span"
                                        startIcon={<Upload />}
                                        fullWidth
                                    >
                                        {audioData ? t('book.content.operations.file-change') : t('book.content.operations.file-choose')}
                                    </Button>
                                </label>
                            </Grid>
                            <Grid item flexGrow={1} p={1}>
                                {audioFile ? audioFile.name : (audioData && audioData.name )}
                            </Grid>
                            <Grid item flexGrow={1} p={1}>
                                {audioFile ? prettySize(audioFile.size) : (audioData && prettySize(audioData.size) )}
                            </Grid>
                        </Grid>
                    </FieldSet>
                </Grid>
                <Grid item xs={12}>
                    <FieldSet title={`${t('book.content.fields.audio-file')} ${t('book.content.fields.freeContent')}`} >
                        <Typography variant={"body1"}>
                            {t('book.content.fields.audio-file-helper')}
                        </Typography>
                        <Grid container flexDirection={"row"} mt={1}>
                            <Grid>
                                <label htmlFor="contained-button-file-free">
                                    <Input id="contained-button-file-free" type="file" accept="audio/mpeg, audio/mp3" onChange={handleAudioFreeFile} />
                                    <Button
                                        variant="contained"
                                        color={"secondary"}
                                        component="span"
                                        startIcon={<Upload />}
                                        fullWidth
                                    >
                                        {audioFreeData ? t('book.content.operations.file-free-change') : t('book.content.operations.file-free-choose')}
                                    </Button>
                                </label>
                            </Grid>
                            <Grid item flexGrow={1} p={1}>
                                {audioFreeFile ? audioFreeFile.name : (audioFreeData && audioFreeData.name )}
                            </Grid>
                            <Grid item flexGrow={1} p={1}>
                                {audioFreeFile ? prettySize(audioFreeFile.size) : (audioFreeData && prettySize(audioFreeData.size) )}
                            </Grid>
                        </Grid>
                    </FieldSet>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color={"secondary"} variant={"contained"}>
                {t('generic.cancel')}
            </Button>
            <Button
                startIcon={<Save />}
                variant={"contained"}

                onClick={handleSave}
            >
                {t('book.content.operations.update-perform')}
            </Button>
        </DialogActions>
    </Dialog>

}
const Input = styled('input')({
    display: 'none',
});