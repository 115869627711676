import React, {useState} from "react";
import {Button, Grid, Typography} from "@mui/material";
import ChartBooks from "./Chart/ChartBooks";
import ChartUser from "./Chart/ChartUser";
import {Auth, Storage} from "aws-amplify";
import BookPublicationWantedList from "./Book/BookPublicationWantedList";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function Home() {
    const {t, i18n} = useTranslation();
    Auth.currentAuthenticatedUser().then(user => console.log(user))
    
    return (
        <Grid container spacing={2}>
    
            <Grid item xs={12}>
                <BookPublicationWantedList limit={5} actionButton={<Button component={NavLink} to={"/books/publicationWanted"}>{t('generic.SeeAll')}</Button>} />
            </Grid>
            <Grid item xs={6}>
                <ChartUser/>
            </Grid>
            <Grid item xs={6}>
                <ChartUser cumulative={true} />
            </Grid>
            <Grid item xs={6}>
                <ChartBooks />
            </Grid>
        </Grid>
    )
}
