import React, { Fragment } from "react";
import { Route, Routes } from "react-router";
import ProductsLayout from "./ProductsLayout";

function Products() {
  return (
    <Routes>
      <Route path={""} element={<ProductsLayout/>} />
    </Routes>
  );
}

export default Products;
