import React, {useState} from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CategoryList from "./CategoryList";
import FamilyList from "./FamilyList";
import SubCategoryList from "./SubCategoryList";

export default function CategoryLayout() {

    const [value, setValue] = useState('families');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Familles" value="families" />
                        <Tab label="Categories" value="categories" />
                        <Tab label="Sous-Categories" value="subcategories" />
                    </TabList>
                </Box>
                <TabPanel value="families">
                    <FamilyList />
                </TabPanel>
                <TabPanel value="categories">
                    <CategoryList />
                </TabPanel>
                <TabPanel value="subcategories">
                    <SubCategoryList />
                </TabPanel>
            </TabContext>
        </Box>
    );
}