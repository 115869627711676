import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Link,
    TextField
} from "@mui/material";
import {tdb} from "../Services/translateJSON";
import {Cancel, Edit, InsertPhoto, Save} from "@mui/icons-material";
import {Author} from "../../models";
import {Auth, DataStore, Storage} from "aws-amplify";
import {styled} from "@mui/styles";
import { v4 as uuidv4 } from 'uuid';
import FileUploader from "../Misc/FileUploader";
import {chooseCorrectKey} from "../Services/correctPath";

export default function PublicAuthorEdit({onClose, authorId}) {
    const {t, i18n} = useTranslation();
    
    const [identity, setIdentity] = useState("");
    useEffect(()=>{
        Auth.currentUserCredentials().then(result=>setIdentity(result.identityId))
    }, [])
    
    const [author, setAuthor] = useState();
    const fetchAuthor = async () => {
        setAuthor(await DataStore.query(Author, authorId))
    }
    useEffect(()=>{
        if (authorId) {
            fetchAuthor()
            const obsAuthor = DataStore.observe(Author, authorId).subscribe(msg=>{
                fetchAuthor()
            })
            return () => {
                obsAuthor.unsubscribe();
            }
        }
    }, [authorId])
    
    const [avatar_path, setAvatar_path] = useState();
    const [avatarURL, setAvatarURL] = useState("");
    const [avatarURL_sm, setAvatarURL_sm] = useState("");
    const [avatarURL_md, setAvatarURL_md] = useState("");
    const [avatarURL_lg, setAvatarURL_lg] = useState("");
    
    const [form, setForm] = useState({
        firstName: "",
        name: "",
        email: "",
        biography: ""
    });
    
    
    useEffect(()=>{
        console.debug("author effect", author);
        if (author) {
            setForm(s=>({
                firstName: author.firstName,
                name: author.name,
                email: author.email,
                biography: tdb(author.biography)
                
            }))
            
            if (author.avatarData && author.avatarData.key)Storage.get(author.avatarData.key, {level: 'protected', identityId: author.avatarKeys.identity}).then(result=>setAvatarURL   (result));
            if (author.avatarKeys && author.avatarKeys.sm) Storage.get(chooseCorrectKey(author.avatarKeys.sm, {level: 'protected'}), {level: 'protected', identityId: author.avatarKeys.identity}).then(result=>setAvatarURL_sm(result));
            if (author.avatarKeys && author.avatarKeys.md) Storage.get(chooseCorrectKey(author.avatarKeys.md, {level: 'protected'}), {level: 'protected', identityId: author.avatarKeys.identity}).then(result=>setAvatarURL_md(result));
            if (author.avatarKeys && author.avatarKeys.lg) Storage.get(chooseCorrectKey(author.avatarKeys.lg, {level: 'protected'}), {level: 'protected', identityId: author.avatarKeys.identity}).then(result=>setAvatarURL_lg(result));
        }
    }, [author])
    
    const [chosenImage, setChosenImage] = useState();
    const chooseImage = (file) => {
        setChosenImage(file)
    }
    
    const [chosenURL, setChosenURL] = useState();
    useEffect(()=>{
        if (chosenImage) {
            setChosenURL(URL.createObjectURL(chosenImage))
        }
    }, [chosenImage])
    
    const [uploadedImage, setUploadedImage] = useState();
    const handleUpload = (_upload) => {
        setUploadedImage({
            ..._upload,
            identity: identity,
            level: 'protected',
            name: chosenImage.name,
            size: chosenImage.size,
            type: chosenImage.type,
        })
    }
    
    // const [fileReading, setFileReading] = useState("");
    
    useEffect(()=>{
        if (uploadedImage) {
            console.debug("uploadedImage", uploadedImage);
        }
    }, [uploadedImage])

    const handleUpdate = async () => {
        try{
            const _update = await DataStore.save(Author.copyOf(author, updated => {
                updated.firstName = form.firstName
                updated.name = form.name
                updated.email = form.email
                updated.biography = JSON.stringify({
                    "default": form.biography
                });
                if (uploadedImage) {
                    updated.avatarData = JSON.stringify(uploadedImage);
                }
            }));
            console.debug("_update", _update);
            if(onClose) onClose()
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Dialog open={true} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>{t("profile.public.operations.edit-dialog-title")}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{pt:2}}>
                    <Grid item xs={4}>
                        <Box display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                            {!chosenImage && <Avatar src={avatarURL_md} sx={{width:120, height:120}} />}
                            {!!chosenURL && <Avatar src={chosenURL} sx={{width:120, height:120}} />}
                            <Box flex={1} ml={2}>
                                <FileUploader
                                    buttonLabel={"Upload"}
                                    prefix={"avatars"}
                                    accept={"image/*"}
                                    level={"protected"}
                                    onChoose={chooseImage}
                                    onUpload={handleUpload}
                                    metadata={author ? {
                                        next: "resize",
                                        modelType: "Author",
                                        modelId: author.id
                                    } : {}}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={t('profile.form.firstname')}
                                    variant="outlined"
                                    value={form && form.firstName}
                                    onChange={event => setForm( state => ({...state, firstName: event.target.value}))}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={t('profile.form.lastname')}
                                    variant="outlined"
                                    value={form && form.name}
                                    onChange={event => setForm( state => ({...state, name: event.target.value}))}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={t('profile.form.email')}
                                    variant="outlined"
                                    value={form && form.email}
                                    onChange={event => setForm( state => ({...state, email: event.target.value}))}
                                />
                            </Grid>
                        </Grid>
                        
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label={t('profile.form.biography')}
                        value={form && form.biography}
                        variant="outlined"
                        multiline
                        rows={5}
                        onChange={event => setForm( state => ({...state, biography: event.target.value}))}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {/*<Link mr={2} href={avatarURL} target={"_blank"}>original</Link>
                        <Link mr={2} href={avatarURL_sm} target={"_blank"}>small</Link>
                        <Link mr={2} href={avatarURL_md} target={"_blank"}>medium</Link>
                        <Link mr={2} href={avatarURL_lg} target={"_blank"}>large</Link>*/}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button startIcon={<Cancel />} variant="contained"onClick={onClose} color={"secondary"}>{t("generic.cancel")}</Button>
                <Button startIcon={<Save />} onClick={handleUpdate} variant="contained">{t("generic.update")}</Button>
            </DialogActions>
        </Dialog>
    )
}

const Input = styled('input')({
    display: 'none',
});