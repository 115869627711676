import React, { Fragment, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Snackbar,
  Switch,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Cancel, Save } from "@mui/icons-material";
import { DataStore } from "aws-amplify";
import { School } from "../../models";
import { tdb } from "../Services/translateJSON";
import { t } from "i18next";


function SchoolEdit({ onClose, open, schoolId }) {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  //fetch school
  const [school, setSchool] = useState([]);
  const fetchSchool = async () => {
    const school = await DataStore.query(School, schoolId);
    setSchool(school);
  };

  //observe
  useEffect(() => {
    fetchSchool();
    const schoolSubscribe = DataStore.observe(School).subscribe(() =>
      fetchSchool()
    );
    return () => {
      schoolSubscribe.unsubscribe();
    };
  }, [schoolId]);

  //Form
  const [form, setForm] = useState({
    title: "",
    phoneNumber: "",
    fixeNumber: "",
    description: "",
    address: "",
  });

  useEffect(() => {
    if (schoolId) {
      setForm({
        title: school.title && school.title,
        phoneNumber: school.phoneNumber && school.phoneNumber,
        fixeNumber: school.fixeNumber && school.fixeNumber,
        description: school.description && school.description,
        address: school.address && school.address,
      });
    } else {
      setForm(null);
    }
  }, [school, schoolId]);

  //UPDATE
  const handleUpdate = async () => {
    try {
      const _schoolUpdate = School.copyOf(school, (updated) => {
        updated.title = form.title;
        updated.phoneNumber =form.phoneNumber;
        updated.fixeNumber =form.fixeNumber;
        updated.description =form.description;
        updated.address = form.address;
      });
      await DataStore.save(_schoolUpdate);
      if (onClose) onClose();
    } catch (e) {
      console.log(e);
      setError(e);
      setSnackbarOpen(true);
    }
  };

  return (
    <Fragment>
      <Dialog maxWidth={"lg"} open={true} onClose={onClose}>
        <DialogTitle>{t("school.Add")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t("generic.Nom")}
                variant="outlined"
                value={(form && form.title) || ""}
                //   error={!!formErrors.title}
                //   helperText={formErrors.title}
                onChange={(event) =>
                  setForm((state) => ({ ...state, title: event.target.value }))
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t("generic.TeleponeMobile")}
                variant="outlined"
                value={(form && form.phoneNumber) || ""}
                //   error={!!formErrors.title}
                //   helperText={formErrors.title}

                onChange={(event) =>
                  setForm((state) => ({
                    ...state,
                    phoneNumber: event.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t("generic.TelephoneFixe")}
                variant="outlined"
                value={(form && form.fixeNumber) || ""}
                //   error={!!formErrors.title}
                //   helperText={formErrors.title}
                onChange={(event) =>
                  setForm((state) => ({
                    ...state,
                    fixeNumber: event.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t("generic.Adresse")}
                variant="outlined"
                value={(form && form.address) || ""}
                //   error={!!formErrors.title}
                //   helperText={formErrors.title}
                onChange={(event) =>
                  setForm((state) => ({
                    ...state,
                    address: event.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                value={(form && form.description) || ""}
                label={t("generic.Desciption")}
                variant="outlined"
                id="standard-multiline-static"
                multiline
                rows={4}
                onChange={(event) =>
                    setForm((state) => ({
                      ...state,
                      description: event.target.value,
                    }))
                  }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<Cancel />}
            variant="contained"
            onClick={onClose}
            color="secondary"
          >
            {t("generic.Cancel")}
          </Button>
          <Button
            startIcon={<Save />}
            onClick={handleUpdate}
            variant="contained"
            color="primary"
          >
            {t("generic.Update")}
          </Button>
        </DialogActions>
        <Snackbar open={snackbarOpen} onClose={onClose}>
          <Alert onClose={onClose} severity="error" sx={{ width: "100%" }}>
            {error}
          </Alert>
        </Snackbar>
      </Dialog>
    </Fragment>
  );
}

export default SchoolEdit;
