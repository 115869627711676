import { API, graphqlOperation } from "aws-amplify";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import localStorage from "local-storage";
import { listBookContents } from "../graphql/queries";

const CACHE_KEY = "BookContents_";

export function useBookContents(bookId) {
  const [loading, setLoading] = useState(false);
  const [contents, setContents] = useState(null);

  const loadExistingData = (id) => {
    const value = localStorage.get(`${CACHE_KEY}${id}`);
    return value ? JSON.parse(value) : null;
  };
  const getData = async (id, nextToken, items) => {
    const result = await API.graphql(
      graphqlOperation(listBookContents, {
        filter: {
          bookID: { eq: id },
        },
        limit: 100,
        nextToken,
      })
    );
    const token = result.data.listBookContents.nextToken;
    const newItems = result.data.listBookContents.items;

    if (token) {
      const nextItems = await getData(id, token, newItems);
      return [...items, ...nextItems];
    }
    return [...items, ...newItems];
  };

  const fetchContents = useCallback(async (id) => {
    setLoading(true);
    let data = await loadExistingData(id);
    setContents(data);
    try {
      // throw new Error("Failed to fetch data");
      data = await getData(id, null, []);
      data = data.filter((d) => !d._deleted).sort((a, b) => a.order - b.order);
      setContents(data);
      localStorage.set(`${CACHE_KEY}${id}`, JSON.stringify(data));
    } catch (e) {
      console.error("Failed to fetch data", e);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (bookId) {
      fetchContents(bookId);
    }
  }, [bookId, fetchContents]);

  return {
    contents: contents ?? [],
    loading,
  };
}
