
export function chooseCorrectKey(storedKey, params) {
    //console.log("CHOOSE_CORRECT_KEY", storedKey);
    if ('userPath' in storedKey)return storedKey.userPath;
    else {
        let key;
        if (typeof key === "string")key = storedKey;
        else if ('path' in storedKey)key = storedKey.path;
        else if ('key' in storedKey)key = storedKey.key;
        //console.log('key', key)
        if (params.level === 'protected' || params.level === 'private') {
            //console.log("Level is there")
            if (key.startsWith(params.level)) {
                const regExp = new RegExp(params.level+'/[^/]+/')
                //console.log("reg", regExp)
                const correctPath = key.replace(regExp, '')
                //console.log('correctPath', correctPath)
                return correctPath
            }
        }
    }
    
    return storedKey.key
}


export function correctPath(path, level, identity) {
    const pre = `${level}/${identity}`
    if (path.startsWith(pre)) {
        return path
    }
    else {
        return pre+'/'+path
    }
}
