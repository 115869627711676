import React, {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import {Storage} from "aws-amplify";
import {Button, FormLabel, LinearProgress, Typography} from "@mui/material";
import {Upload} from "@mui/icons-material";
import {styled} from "@mui/styles";

export default function FileUploader({key, prefix, onChoose, onUpload, buttonLabel, level, accept, onUploading, disable, metadata=  {}}) {
    
    const [uniq, setUniq] = useState();
    useEffect(()=>{
        setUniq(uuidv4())
    }, [])
    
    const [file, setFile] = useState();
    const handleFile = (e) => setFile(e.target.files[0]);
    // when file is chosen
    useEffect(()=>{
        if (file) {
            setUploadProgress(0)
            onChoose(file)
            // uploadFile();
            if (onUploading)onUploading();
            const _upload = Storage.put(key ? key : (prefix ? `${prefix}/${uniq}` : uniq), file, {
                level: level,
                contentType: file.type,
                //type: file.type.replace('document/', ''),
                metadata: {
                    ...metadata,
                    contenttype: file.type
                },
                progressCallback: (progress) => {
                    setUploadProgress(100*progress.loaded/progress.total);
                }
            }).then(result=>{
                setUploadedFile({...result});
            })
            return () => {
                Storage.cancel(_upload)
            }
        }
    }, [file])
    
    const [uploadedFile, setUploadedFile] = useState();
    const [uploadProgress, setUploadProgress] = useState(-1);
    const uploadFile = async () => {
        if (onUploading)onUploading();
        console.log("upload");
        const _upload = await Storage.put(key ? key : (prefix ? `${prefix}/${uniq}` : uniq), file, {
            level: level,
            contentType: file.type,
            //type: file.type.replace('document/', ''),
            metadata: {
                ...metadata,
                contenttype: file.type
            },
            progressCallback: (progress) => {
                setUploadProgress(100*progress.loaded/progress.total);
            }
        });
        console.log("upload done", _upload);
        setUploadedFile({..._upload});
    }
    
    // when file is uploaded
    useEffect(()=>{
        if (uploadedFile) {
            console.log("uploadedFile", uploadedFile)
            onUpload(uploadedFile)
        }
    }, [uploadedFile])
    
    return <FormLabel htmlFor={uniq}>
        <Input accept={accept} id={uniq} type="file" onChange={handleFile} />
        {(uploadProgress <= 0 || uploadProgress === 100) && <Button
            variant="contained"
            color={"secondary"}
            component="span"
            startIcon={<Upload />}
            fullWidth
            disabled={disable}
        >
            {buttonLabel}
        </Button>}
        {uploadProgress >= 0 && uploadProgress < 100 && <LinearProgress sx={{height: 20}} variant="determinate" value={uploadProgress} />}
    </FormLabel>
}

const Input = styled('input')({
    display: 'none',
});