import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataStore } from "aws-amplify";
import { School } from "../../models";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import {
  Add,
  AddCircle,
  Close,
  DeleteForever,
  Edit,
  Search,
  VisibilitySharp,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
  Typography,
} from "@mui/material";
import { Route, Routes, useNavigate } from "react-router-dom";
import SchoolCreate from "./SchoolCreate";
import SchoolDelete from "./SchoolDelete";
import SchoolEdit from "./SchoolEdit";

function SchoolList() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  //********Liste etablissement********////
  const [schools, setSchools] = useState([]);
  const fetchSchools = async () => {
    setSchools(await DataStore.query(School));
  };
  useEffect(() => {
    fetchSchools();
  }, []);

  const [deleteSchool, setDeleteSchool] = useState(false);
  const [editSchool, setEditSchool] = useState("");
  const [schoolId, setSchoolId] = useState("");

  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const handleCreate = () => setCreateMode(true);
  const handleCancelCreate = () => setCreateMode(false);

  const handleCancelEdit = () => {
    setEditSchool(null);
    setEditMode(false);
  };
  const handleDelete = (id) => {
    setSchoolId(id);
    setDeleteSchool(true);
  };

  const handleEdit = (id) => {
    setEditSchool(id);
    setEditMode(true);
  };

  //GRID PARAMS
  const _renderActions = (params) => {
    return (
      (1 || params.row === 0) && (
        <>
          <IconButton size={"small"} onClick={() => handleEdit(params.row.id)}>
            <Edit />
          </IconButton>
          <IconButton
            size={"small"}
            onClick={() => handleDelete(params.row.id)}
          >
            <DeleteForever />
          </IconButton>
        </>
      )
    );
  };

  const columns = [
    { field: "id", headerName: "id", flex: 1, hide: false },
    { field: "title", headerName: "Etablissement", flex: 1, hide: false },
    { field: "phoneNumber", headerName: "Téléphone", flex: 1 },
    { field: "fixeNumber", headerName: "Fixe", flex: 1 },
    { field: "address", headerName: "Adresse", flex: 1 },
    { field: "description", headerName: "Description", flex: 1 },
    {
      field: "action",
      headerName: "Actions",
      width: 100,
      align: "right",
      renderCell: _renderActions,
      disableColumnMenu: true,
    },
  ];

  return (
    <Fragment>
      <Card>
        <CardHeader
          title={<Typography variant={"h5"}>{t("school.list")}</Typography>}
        />
        <CardContent>
          <DataGrid
            density={"compact"}
            autoHeight
            rows={schools}
            columns={columns}
            onRowDoubleClick={(params) => navigate(params.row.id)}
          />
        </CardContent>
        <CardActions>
          <Button
            startIcon={<AddCircle />}
            className={classes.mt}
            variant="contained"
            onClick={() => setCreateMode(true)}
          >
            {t("generic.Add")}
          </Button>
          {createMode && <SchoolCreate onClose={() => setCreateMode(false)} />}
          {editMode && (
            <SchoolEdit onClose={handleCancelEdit} schoolId={editSchool} />
          )}
          <SchoolDelete
            open={deleteSchool}
            onClose={() => setDeleteSchool(false)}
            id={schoolId}
          />
        </CardActions>
      </Card>
    </Fragment>
  );
}
const useStyles = makeStyles(() => ({
  mt: {
    marginTop: 10,
  },
}));
export default SchoolList;
