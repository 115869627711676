const prettySize = (size) => {
    if (!size)return ""
    if (size < 1024) {
        return `${size}o`;
    }
    else if (size < 1024*1024) {
        return `${Math.ceil(10*size/1024)/10}Ko`;
    }
    else if (size < 1024*1024*1024) {
        return `${Math.round(10*size/(1024*1024))/10}Mo`;
    }
    else if (size < 1024*1024*1024*1024) {
        return `${Math.ceil(10*size/(1024*1024*1024))/10}Go`;
    }
}

export default prettySize