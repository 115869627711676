import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataStore } from "aws-amplify";
import { Price,Product} from "../../models";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import {
  Add,
  AddCircle,
  Close,
  DeleteForever,
  Edit,
  Search,
  VisibilitySharp,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import { Route, Routes, useNavigate } from "react-router-dom";
import PricesEdit from "./PricesEdit";
import PricesDelete from "./PricesDelete";
import PricesCreate from "./PricesCreate";
import { tdb } from "../Services/translateJSON";
function PricesList() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  //********Liste tarifs********////
  const [prices, setPrices] = useState([]);
  const fetchPrices = async () => {
    setPrices(await DataStore.query(Price));
  };
     // SERVICES
     const [products, setProducts] = useState([]);
     const fetchProducts = async () => setProducts(await DataStore.query(Product))
    useEffect(() => {
    fetchPrices();
    fetchProducts();
    //   const pricesSubscribes = DataStore.observe(Price).subscribe(() =>fetchPrices())
    //   return () => {
    //     pricesSubscribes.unsubscribe();
    //   }
  }, []);



  const [deletePrice, setDeletePrice] = useState(false);
  const [editPrice, setEditPrice] = useState("");
  const [priceId, setPriceId] = useState("");

  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const handleCreate = () => setCreateMode(true);
  const handleCancelCreate = () => setCreateMode(false);

  const handleCancelEdit = () => {
    setEditPrice(null);
    setEditMode(false);
  };
  const handleDelete = (id) => {
    setPriceId(id);
    setDeletePrice(true);
  };

  const handleEdit = (id) => {
    setEditPrice(id);
    setEditMode(true);
  };
  //GRID PARAMS
  const _renderActions = (params) => {
    return (
      (1 || params.row === 0) && (
        <>
          <IconButton size={"small"} onClick={() => handleEdit(params.row.id)}>
            <Edit />
          </IconButton>
          <IconButton
            size={"small"}
            onClick={() => handleDelete(params.row.id)}
          >
            <DeleteForever />
          </IconButton>
        </>
      )
    );
  };
  const columns = [
    { field: "title", headerName: "Titre", flex: 1, hide: false },
    {
      field: "product",
      headerName: "categorie",
      flex: 1,
      renderCell: (params) => tdb(params.row.product.title),
    },

    { field: "amount", headerName: "Montant", flex: 1 },
    { field: "description", headerName: "Description", flex: 1 },
    {
      field: "action",
      headerName: "Actions",
      width: 100,
      align: "right",
      renderCell: _renderActions,
      disableColumnMenu: true,
    },
  ];

  const [rows, setRows] = useState([]);
  const [service, setService] = useState("none");
  useEffect(()=>{
      setRows(prices.filter(c=>service!=="none" ? c.service.id===service : true))
  }, [prices, service])

  return (
    <Fragment>
      <Card>
        <CardHeader
          title={<Typography variant={"h5"}>{t("tarif.list")}</Typography>}
          action={
            <TextField variant={"outlined"} label={"service"} size={"small"} value={service} onChange={event=>setService(event.target.value)} select>
                <MenuItem value={"none"}>{t("product.AllService")}</MenuItem>
                {products.map(service=><MenuItem key={service.id} value={service.id}>{tdb(service.title)}</MenuItem>)}
            </TextField>}
        />
        <CardContent>
          <DataGrid
            density={"compact"}
            autoHeight
            rows={rows}
            columns={columns}
            onRowDoubleClick={(params) => navigate(params.row.id)}
          />
        </CardContent>
        <CardActions>
          <Button
            startIcon={<AddCircle />}
            className={classes.mt}
            variant="contained"
            onClick={() => setCreateMode(true)}
          >
            {t("generic.Add")}
          </Button>
          {createMode && <PricesCreate onClose={() => setCreateMode(false)} />}
          {editMode && (
            <PricesEdit onClose={handleCancelEdit} priceId={editPrice} />
          )}
          <PricesDelete
            open={deletePrice}
            onClose={() => setDeletePrice(false)}
            id={priceId}
          />
        </CardActions>
      </Card>
    </Fragment>
  );
}
const useStyles = makeStyles(() => ({
  mt: {
    marginTop: 10,
  },
}));

export default PricesList;
