import React, { Fragment } from 'react';
import { Route, Routes } from "react-router";
import SettingsLayout from './SettingsLayout';

function Settings(props) {
    return (
        <Fragment>
             <Routes> <Route path={""} element={<SettingsLayout/>} /></Routes>
            
        </Fragment>
    );
}

export default Settings;