import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Link,
  Typography,
} from "@mui/material";
import { DataStore } from "aws-amplify";
import { BookRating, Purchase } from "../../models";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import { Delete, Money, Star } from "@mui/icons-material";
import { NavLink } from "react-router-dom";

export default function RatingList({ authorSub, bookId, readerId }) {
  const { t, i18n } = useTranslation();

  const [ratings, setRatings] = useState([]);
  const fetchRating = async () => {
    const _ratings = await DataStore.query(BookRating);
    if (authorSub)
      setRatings(_ratings.filter((p) => p.authorSub === authorSub));
    else if (bookId) setRatings(_ratings.filter((p) => p.book.id === bookId));
    else if (readerId)
      setRatings(_ratings.filter((p) => p.readerUser.id === readerId));
    else setRatings(_ratings);
  };
  useEffect(() => {
    fetchRating();
    const obsRating = DataStore.observe(BookRating).subscribe((msg) => {
      if (authorSub && msg.element.authorSub === authorSub) fetchRating();
      else if (
        bookId &&
        (msg.element.bookID === bookId || msg.element.book.id === bookId)
      )
        fetchRating();
      else if (
        readerId &&
        (msg.element.readerUserID === readerId ||
          msg.element.readerUser.id === readerId)
      )
        fetchRating();
      else fetchRating();
    });
    return () => {
      obsRating.unsubscribe();
    };
  }, []);

  const handleDelete = async (ratingId) => {
    const rating = ratings.find((p) => p.id === ratingId);
    if (rating && ["Failed", "Pending"].includes(rating.status)) {
      const _delete = await DataStore.delete(rating);
    }
  };

  const _actions = (params) => {
    if (["Failed", "Pending"].includes(params.row.status)) {
      return (
        <IconButton onClick={() => handleDelete(params.row.id)}>
          <Delete />
        </IconButton>
      );
    }
  };

  const _book = (params) => {
    return (
      <Link
        underline={"hover"}
        color="black"
        component={NavLink}
        sx={{}}
        to={`/books/published/${params.row.book.id}`}
      >
        {params.row.book.title}
      </Link>
    );
  };

  const _reader = (params) => {
    return (
      <Link
        underline={"hover"}
        color="black"
        component={NavLink}
        sx={{}}
        to={`/readers/${params.row.readerUser.id}`}
      >
        {params.row.readerUser.email}
      </Link>
    );
  };

  const columns = [
    { field: "_version", headerName: "v", width: 40 },
    {
      field: "_deleted",
      headerName: "d",
      width: 40,
      renderCell: (params) => (params.value ? "x" : ""),
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 160,
      renderCell: (params) => moment(params.value).format("DD/MM/YYYY HH:mm"),
    },
    // {field: 'updatedAt', headerName: "Updated", flex:1, renderCell: params => moment(params.value).format('DD/MM/YYYY HH:mm')},
    { field: "book", headerName: t("book.book"), flex: 1, renderCell: _book },
    {
      field: "reader",
      headerName: t("purchase.reader"),
      flex: 1,
      renderCell: _reader,
    },
    { field: "rating", headerName: t("rating.rating"), width: 80 },
    { field: "action", headerName: " ", width: 80, renderCell: _actions },
  ];

  const [filter, setFilter] = useState("");
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(ratings);
  }, [filter, ratings]);

  return (
    <Card elevation={4}>
      <CardHeader
        avatar={
          <Avatar>
            <Star />
          </Avatar>
        }
        title={<Typography variant={"h6"}>{t("rating.ratingList")}</Typography>}
      />
      <CardContent>
        <DataGrid
          density={"compact"}
          autoHeight
          rows={rows}
          columns={columns}
          hideFooter={rows.length <= 100}
        />
      </CardContent>
    </Card>
  );
}
