import React, {useState} from "react";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    TextField,
    Button, Snackbar, Alert
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Cancel, Save} from "@mui/icons-material";
import {Family} from "../../models";
import {DataStore} from "aws-amplify";

export default function FamilyCreate({open, onClose}) {
    const {t, i18n} = useTranslation();
    const [form, setForm] = useState({
        title: "",
        name: "",
        description: "",
        content:"",
        active: false
    })
    const handleChange = (field, value) => {
        setForm(state => ({...state, [field]: value}))
    }
    const [formErrors, setFormErrors] = useState({});
    const [error, setError] = useState()
    const [snackbarOpen, setSnackbarOpen] = useState(false)

    const handleSave = async () => {

        //ERROR
        const _errors = {};
        const _required = `${t('generic.Required')}`;
        if (form.title < 2)_errors['title'] = _required
        setFormErrors(_errors)

        //SAVE
        const _family = {
            title: JSON.stringify({
                "default": form.title
            }),
            name:form.name,
            description: JSON.stringify({
                "default": form.description
            }),
            content: JSON.stringify({
                "default": form.content
            }),
            active: form.active
        }
        let family;
        try {
            const __family = new Family(_family);
            family = await DataStore.save(__family)
            if (onClose)onClose();
        }
        catch (e) {
            console.log(e);
            setError(e)
            setSnackbarOpen(true)
        }


    }

    return <Dialog maxWidth={"lg"} open={open} onClose={onClose} >
        <DialogTitle>{t('family.Add')}</DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={t('generic.Title')}
                        variant="outlined"
                        value={form.title}
                        error={!!formErrors.title} helperText={formErrors.title}
                        onChange={event => handleChange("title", event.target.value)} />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={t('generic.Name')}
                        helperText={t('family.HelpName')}
                        variant="outlined"
                        value={form.name}
                        onChange={event => handleChange("name", event.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={t('family.Description')}
                        helperText={t('family.HelpDescription')}
                        variant="outlined"
                        multiline
                        rows={3}
                        value={form.description}
                        onChange={event => handleChange("description", event.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={t('family.Content')}
                        helperText={t('family.HelpContent')}
                        variant="outlined"
                        multiline
                        rows={6}
                        value={form.content}
                        onChange={event => handleChange("content", event.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox  checked={!!form.active} value={form.active} onChange={event => setForm(state => ({...state, active: event.target.checked}))} />} label={t("generic.Activate")} />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button startIcon={<Cancel />} variant="contained"onClick={onClose} color="secondary">{t("generic.Cancel")}</Button>
            <Button startIcon={<Save />} onClick={handleSave} variant="contained" color="primary">{t("generic.Save")}</Button>
        </DialogActions>
        <Snackbar open={snackbarOpen} onClose={onClose}>
            <Alert onClose={onClose} severity="error" sx={{ width: '100%' }}>
                {error}
            </Alert>
        </Snackbar>
    </Dialog>
}