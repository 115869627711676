import {
    Avatar,
    Dialog,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText
} from "@mui/material";
import {Edit, ErrorOutline} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {Storage} from "aws-amplify";
import ReactAudioPlayer from "react-audio-player";

export function BookInfo({avatar, primary, secondary, editable, danger = false, edit = false}) {
    return <ListItem>
        <ListItemAvatar>
            <Avatar sx={{backgroundColor: danger ? "error.main" : "default"}}>
                {danger ? <ErrorOutline fontSize={"large"} /> : avatar}
            </Avatar>
        </ListItemAvatar>
        <ListItemText
            primary={<small>{primary}</small>}
            secondary={<>{secondary}</>}
            // primary={<Typography variant={"caption"}>{primary}</Typography>}
            // secondary={<Typography variant={"body1"}>{secondary}</Typography>}
        />
        {edit && <ListItemSecondaryAction>
            {edit && <IconButton onClick={edit} disabled={!editable}><Edit /></IconButton> }
        </ListItemSecondaryAction>}
    </ListItem>
}

export function BookImgModal({open, onClose, urlImg}) {
    return (
        <Dialog open={open} onClose={onClose} maxWidth={"md"}>
            <img src={urlImg}  style={{width: "100%"}}/>
        </Dialog>
    )
}

export function BookAudioPlayer({data, onClose}) {
    const [audioURL, setAudioURL] = useState("");
    const fetchAudioURL = async () => {
        if (data.tempURL) {
            setAudioURL(data.tempURL)
        }
        else {
            setAudioURL(await Storage.get(data.key,{level: data.level, ...data.identity ? {identity: data.identity} : null}))
        }
    }
    useEffect(()=>fetchAudioURL(), [data])
    return (
        <Dialog open={true} onClose={onClose} maxWidth={"md"} fullWidth>
            <ReactAudioPlayer
                src={audioURL}
                autoPlay
                controls
                style={{width: '100%'}}
            />
        </Dialog>
    )
}