import { API, graphqlOperation } from "aws-amplify";
import { getDraftContent } from "./../graphql/queries";
import {
  updateDraftContent as updateDraftContentMutation,
  deleteDraftContent as deleteDraftContentMutation,
} from "./../graphql/mutations";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import localStorage from "local-storage";

const CACHE_KEY = "DraftContent_";

export function useDraftContent(draftContentId) {
  const [loading, setLoading] = useState(false);
  const [draftContent, setDraftContent] = useState(null);

  const loadExistingData = (id) => {
    const value = localStorage.get(`${CACHE_KEY}${id}`);
    return value ? JSON.parse(value) : null;
  };

  const fetchDraftContent = useCallback(async (id) => {
    setLoading(true);
    let data = await loadExistingData(id);
    setDraftContent(data);
    const result = await API.graphql(
      graphqlOperation(getDraftContent, {
        id,
      })
    );
    data = result.data.getDraftContent;
    localStorage.set(`${CACHE_KEY}${id}`, JSON.stringify(data));
    setDraftContent(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (draftContentId) {
      fetchDraftContent(draftContentId);
    }
  }, [draftContentId, fetchDraftContent]);

  const updateDraftContent = async (input) => {
    const updatedDraftContent = await API.graphql({
      query: updateDraftContentMutation,
      variables: {
        input: {
          id: draftContentId,
          _version: draftContent._version,
          ...input,
        },
      },
    });
    setDraftContent(updatedDraftContent.data.updateDraftContent);
  };

  const deleteDraftContent = async () => {
    const deletedDraftContent = await API.graphql({
      query: deleteDraftContentMutation,
      variables: {
        input: {
          id: draftContentId,
          _version: draftContent._version,
        },
      },
    });
    console.log(deletedDraftContent);
    setDraftContent(null);
  };

  return {
    draftContent,
    loading,
    updateDraftContent,
    deleteDraftContent,
  };
}
