export function tdb(obj, lang = 'fr') {

    if(!obj)return "";

    if (typeof obj === "string") {
        try {
            obj = JSON.parse(obj)
        }
        catch (e) {}
    }

    if (typeof obj === "string") {
        return obj
    }
    else if (lang in obj) {
        return obj[lang]
    }
    else if ('default' in obj) {
        return obj.default
    }
    else if ('en' in obj) {
        return obj.en
    }
    else if (typeof obj === "object") {
        const _k = Object.keys(obj);
        if (_k.length) {
            return obj[_k[0]]
        }
    }
    return ""
}