import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  List,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Cancel, Save } from "@mui/icons-material";
import { DataStore } from "aws-amplify";
import {
  Author,
  AuthorUser,
  Category,
  Draft,
  Family,
  SubCategory,
} from "../../../models";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { tdb } from "../../Services/translateJSON";
import { makeStyles } from "@mui/styles";

// INFOS
export function BookDraftEditInfo({ onClose, draftId, summary }) {
  const { t } = useTranslation();
  const [draft, setDraft] = useState();
  const fetchDraft = async () =>
    setDraft(await DataStore.query(Draft, draftId));
  useEffect(() => {
    if (draftId) fetchDraft();
  }, [draftId]);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  useEffect(() => {
    if (draft) {
      setTitle(draft.title);
      setDescription(draft.description);
    }
  }, [draft]);

  const handleSave = async () => {
    DataStore.save(
      Draft.copyOf(draft, (updater) => {
        updater.title = title;
        updater.description = description;
      })
    ).then((result) => onClose());
  };

  return (
    <Dialog open={true} maxWidth={"md"} fullWidth onClose={onClose}>
      <DialogTitle>{t("book.operations.update-dialog")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ pt: 2 }}>
          <Grid item xs={12} hidden={summary}>
            <TextField
              fullWidth
              value={title}
              variant={"outlined"}
              label={t("book.form.title")}
              onChange={(event) => setTitle(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} hidden={summary}>
            <TextField
              fullWidth
              multiline
              value={description}
              variant={"outlined"}
              label={t("book.form.description")}
              onChange={(event) => setDescription(event.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          color={"secondary"}
          startIcon={<Cancel />}
          onClick={onClose}
        >
          {t("generic.cancel")}
        </Button>
        <Button
          variant={"contained"}
          startIcon={<Save />}
          onClick={handleSave}
          disabled={!title || !description}
        >
          {t("book.operations.update-perform")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// AUTHOR
export function BookDraftEditAuthor({ onClose, draftId, summary }) {
  const { t } = useTranslation();
  const { user } = useAuthenticator();
  const classes = useStyles();

  // get author user
  const [authorUser, setAuthorUser] = useState();
  const fetchAuthorUser = async () =>
    setAuthorUser(
      await DataStore.query(AuthorUser, user.attributes["custom:author_id"])
    );
  useEffect(() => {
    if (user) fetchAuthorUser();
  }, [user]);

  // get public authors
  const [authors, setAuthors] = useState([]);
  const fetchAuthors = async () =>
    setAuthors(
      (await DataStore.query(Author)).filter((a) => a.sub === authorUser.sub)
    );
  useEffect(() => {
    if (authorUser) fetchAuthors();
  }, [authorUser]);

  const [draft, setDraft] = useState();
  const fetchDraft = async () =>
    setDraft(await DataStore.query(Draft, draftId));
  useEffect(() => {
    if (draftId) fetchDraft();
  }, [draftId]);

  const [author, setAuthor] = useState();
  useEffect(() => {
    if (draft) setAuthor(draft.author ? draft.author.id : "");
  }, [draft]);

  const handleSave = async () => {
    DataStore.save(
      Draft.copyOf(draft, (updater) => {
        updater.authorID = author;
      })
    ).then((result) => onClose());
  };

  return (
    <Dialog open={true} maxWidth={"md"} fullWidth onClose={onClose}>
      <DialogTitle>{t("book.form.publicProfile")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ pt: 2 }}>
          <Grid item xs={12}>
            {/*(!author || authors.find(a=>a.id===author)) &&*/}
            <RadioGroup
              value={author}
              onChange={(event) => setAuthor(event.target.value)}
            >
              {authors.map((author) => (
                <>
                  <FormControlLabel
                    control={<Radio />}
                    label={
                      <Typography>
                        {author.firstName} {author.name}
                      </Typography>
                    }
                  />
                </>
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          color={"secondary"}
          startIcon={<Cancel />}
          onClick={onClose}
        >
          {t("generic.cancel")}
        </Button>
        <Button
          variant={"contained"}
          startIcon={<Save />}
          disabled={!author}
          onClick={handleSave}
        >
          {t("book.operations.update-perform")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// SUMMARY
export function BookDraftEditSummary({ onClose, draftId }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [draft, setDraft] = useState();
  const fetchDraft = async () =>
    setDraft(await DataStore.query(Draft, draftId));
  useEffect(() => {
    if (draftId) fetchDraft();
  }, [draftId]);

  const [summary, setSummary] = useState("");

  useEffect(() => {
    if (draft) {
      setSummary(draft.summary);
    }
  }, [draft]);

  const handleSave = async () => {
    DataStore.save(
      Draft.copyOf(draft, (updater) => {
        updater.summary = summary;
      })
    ).then((result) => onClose());
  };

  return (
    <Dialog open={true} maxWidth={"md"} fullWidth onClose={onClose}>
      <DialogTitle>{t("book.form.summary")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ pt: 2 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              value={summary}
              variant={"outlined"}
              onChange={(event) => setSummary(event.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          color={"secondary"}
          startIcon={<Cancel />}
          onClick={onClose}
        >
          {t("generic.cancel")}
        </Button>
        <Button
          variant={"contained"}
          startIcon={<Save />}
          disabled={!summary}
          onClick={handleSave}
        >
          {t("book.operations.update-perform")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// PRICE
export function BookDraftEditPrice({ onClose, draftId }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [draft, setDraft] = useState();
  const fetchDraft = async () =>
    setDraft(await DataStore.query(Draft, draftId));
  useEffect(() => {
    if (draftId) fetchDraft();
  }, [draftId]);

  const [price, setPrice] = useState("");
  useEffect(() => {
    if (draft) setPrice(draft.price);
  }, [draft]);

  const handleSave = async () => {
    DataStore.save(
      Draft.copyOf(draft, (updater) => {
        updater.price = parseFloat(price);
      })
    ).then((result) => onClose());
  };

  return (
    <Dialog open={true} maxWidth={"md"} fullWidth onClose={onClose}>
      <DialogTitle>{t("book.form.price")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ pt: 2 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={price}
              variant={"outlined"}
              label={t("book.form.price")}
              onChange={(event) => setPrice(event.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          color={"secondary"}
          startIcon={<Cancel />}
          onClick={onClose}
        >
          {t("generic.cancel")}
        </Button>
        <Button variant={"contained"} startIcon={<Save />} onClick={handleSave}>
          {t("book.operations.update-perform")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// ISBN
export function BookDraftEditIsbn({ onClose, draftId }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [draft, setDraft] = useState();
  const fetchDraft = async () =>
    setDraft(await DataStore.query(Draft, draftId));
  useEffect(() => {
    if (draftId) fetchDraft();
  }, [draftId]);

  const [isbn, setIsbn] = useState("");
  useEffect(() => {
    if (draft) setIsbn(draft.isbn);
  }, [draft]);

  const handleSave = async () => {
    DataStore.save(
      Draft.copyOf(draft, (updater) => {
        updater.isbn = isbn;
      })
    ).then((result) => onClose());
  };

  return (
    <Dialog open={true} maxWidth={"md"} fullWidth onClose={onClose}>
      <DialogTitle>{t("book.form.isbn")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ pt: 2 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={isbn}
              variant={"outlined"}
              label={t("book.form.isbn")}
              onChange={(event) => setIsbn(event.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          color={"secondary"}
          startIcon={<Cancel />}
          onClick={onClose}
        >
          {t("generic.cancel")}
        </Button>
        <Button variant={"contained"} startIcon={<Save />} onClick={handleSave}>
          {t("book.operations.update-perform")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// CATEGORY
export function BookDraftEditCategory({ onClose, draftId, summary }) {
  const { t } = useTranslation();
  const classes = useStyles();

  // fetching draft
  const [draft, setDraft] = useState();
  const fetchDraft = async () =>
    setDraft(await DataStore.query(Draft, draftId));
  useEffect(() => {
    if (draftId) fetchDraft();
  }, [draftId]);

  // fetching thesaurus
  const [suitableFamilies, setSuitableFamilies] = useState([]);
  const [families, setFamilies] = useState([]);
  const fetchFamilies = async () => setFamilies(await DataStore.query(Family));
  const [categories, setCategories] = useState([]);
  const fetchCategories = async () =>
    setCategories(await DataStore.query(Category));
  const [subCategories, setSubCategories] = useState([]);
  const fetchSubCategories = async () =>
    setSubCategories(await DataStore.query(SubCategory));
  useEffect(() => {
    fetchCategories();
    fetchSubCategories();
    fetchFamilies();
  }, []);

  const [type, setType] = useState("");
  const [familyId, setFamilyId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");

  useEffect(() => {
    if (draft) {
      setType(draft.bookType);
      setFamilyId(draft.family ? draft.family.id : "");
      setCategoryId(draft.category ? draft.category.id : "");
      setSubCategoryId(draft.subCategory ? draft.subCategory.id : "");
    }
  }, [draft]);

  const [ready, setReady] = useState();
  // get correct categories depending on type
  useEffect(() => {
    if (families.length && categories.length && subCategories.length && type) {
      const _audioFamily = families.find((f) => f.name === "audio");
      if (_audioFamily) {
        if (type === "Audio") {
          setSuitableFamilies([_audioFamily]);
        } else {
          setSuitableFamilies(
            families.filter((f) => !["audio", "documentation"].includes(f.name))
          );
        }
      } else {
        throw new Error("No audio family");
      }
      setReady(true);
    }
  }, [families, categories, subCategories, type, familyId, categoryId]);

  useEffect(() => {
    // if current family not in suitable families
    if (suitableFamilies.length > 0) {
      if (familyId && !suitableFamilies.find((f) => f.id === familyId)) {
        setFamilyId("");
        setCategoryId("");
        setSubCategoryId("");
        //setForm(state=>({...state, family: "", category: "", subCategory: ""}))
      }
      // if current category not in current family
      else if (
        familyId &&
        categoryId &&
        categories.find((c) => c.id === categoryId).family.id !== familyId
      ) {
        setCategoryId("");
        setSubCategoryId("");
        //setForm(state=>({...state, category: "", subCategory: ""}))
      }
      // if current sub category not in current category
      else if (
        categoryId &&
        subCategoryId &&
        subCategories.find((c) => c.id === subCategoryId).category.id !==
          categoryId
      ) {
        setSubCategoryId("");
        //setForm(state=>({...state, subCategory: ""}))
      }
    }
  }, [suitableFamilies, familyId, categoryId]);

  const handleSave = async () => {
    console.debug({
      familyId: familyId,
      categoryId: categoryId,
      subCategoryId: subCategoryId,
    });
    DataStore.save(
      Draft.copyOf(draft, (updater) => {
        updater.familyID = familyId;
        updater.family = families.find((f) => f.id === familyId);
        updater.categoryID = categoryId;
        updater.category = categories.find((c) => c.id === categoryId);
        updater.subCategoryID = subCategoryId || null;
        updater.subCategory = subCategoryId
          ? subCategories.find((s) => s.id === subCategoryId)
          : null;
      })
    );
  };

  return (
    <Dialog open={true} maxWidth={"md"} fullWidth onClose={onClose}>
      <DialogTitle>{t("book.operations.update-dialog")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ pt: 2 }}>
          <Grid item xs={12}>
            <RadioGroup
              sx={{ pl: 1 }}
              value={familyId}
              onChange={(event) => setFamilyId(event.target.value)}
              row
            >
              {suitableFamilies.map((family) => (
                <FormControlLabel
                  className={classes.familyButton}
                  value={family.id}
                  control={<Radio />}
                  label={<Typography>{tdb(family.title)}</Typography>}
                />
              ))}
            </RadioGroup>
            {familyId}
          </Grid>
          <Grid item xs={12}>
            {(!familyId ||
              !categoryId ||
              (categoryId &&
                categories.find((c) => c.id === categoryId) &&
                categories.find((c) => c.id === categoryId).family.id ===
                  familyId)) && (
              <TextField
                select
                label={t("book.form.category")}
                fullWidth
                value={categoryId}
                onChange={(event) => setCategoryId(event.target.value)}
              >
                {categories
                  .filter((c) => c.family.id === familyId)
                  .map((category) => (
                    <MenuItem value={category.id}>
                      {tdb(category.title)}
                    </MenuItem>
                  ))}
              </TextField>
            )}
            {categoryId}
          </Grid>
          <Grid item xs={12}>
            {categoryId &&
              subCategories.filter((c) => c.category.id === categoryId).length >
                0 && (
                <TextField
                  select
                  label={t("book.form.subcategory")}
                  fullWidth
                  value={subCategoryId}
                  onChange={(event) => setSubCategoryId(event.target.value)}
                >
                  {subCategories
                    .filter((c) => c.category.id === categoryId)
                    .map((subCategory) => (
                      <MenuItem value={subCategory.id}>
                        {tdb(subCategory.title)}
                      </MenuItem>
                    ))}
                </TextField>
              )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          color={"secondary"}
          startIcon={<Cancel />}
          onClick={onClose}
        >
          {t("generic.cancel")}
        </Button>
        <Button
          variant={"contained"}
          startIcon={<Save />}
          disabled={!categoryId}
          onClick={handleSave}
        >
          {t("book.operations.update-perform")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// ALL (deprecated)
export function BookDraftEditAll({ onClose, draftId, summary }) {
  const { t } = useTranslation();
  const { user } = useAuthenticator();
  const classes = useStyles();

  const [authorUser, setAuthorUser] = useState();
  const fetchAuthorUser = async () => {
    setAuthorUser(
      await DataStore.query(AuthorUser, user.attributes["custom:author_id"])
    );
  };
  useEffect(() => {
    if (user) fetchAuthorUser();
  }, [user]);

  const [draft, setDraft] = useState();
  const fetchDraft = async () =>
    setDraft(await DataStore.query(Draft, draftId));
  useEffect(() => {
    if (draftId) fetchDraft();
  }, [draftId]);

  // Categories
  const [families, setFamilies] = useState([]);
  const [suitableFamilies, setSuitableFamilies] = useState([]);
  const fetchFamilies = async () => setFamilies(await DataStore.query(Family));
  const [categories, setCategories] = useState([]);
  const fetchCategories = async () =>
    setCategories(await DataStore.query(Category));
  const [subCategories, setSubCategories] = useState([]);
  const fetchSubCategories = async () =>
    setSubCategories(await DataStore.query(SubCategory));

  useEffect(() => {
    fetchCategories();
    fetchSubCategories();
    fetchFamilies();
  }, []);

  const [form, setForm] = useState({
    title: "",
    description: "",
    summary: "",
    author: "",
    price: "",
    isbn: "",
    type: "",
    family: "",
    category: "",
    subCategory: "",
  });

  useEffect(() => {
    if (draft) {
      setForm({
        title: draft.title,
        description: draft.description,
        summary: draft.summary,
        price: draft.price,
        type: draft.type,
        isbn: draft.isbn,
        author: draft.author && draft.author.id,
        family: draft.family && draft.family.id,
        category: draft.category && draft.category.id,
        subCategory: draft.subCategory && draft.subCategory.id,
      });
    }
  }, [draft]);

  // get correct categories depending on type
  useEffect(() => {
    if (
      families.length &&
      categories.length &&
      subCategories.length &&
      form.type
    ) {
      const _audioFamily = families.find((f) => f.name === "audio");
      if (_audioFamily) {
        if (form.type === "Audio") {
          setSuitableFamilies([_audioFamily]);
        } else {
          setSuitableFamilies(families.filter((f) => f.name !== "audio"));
        }
      } else {
        throw new Error("No audio family");
      }
    }
  }, [families, categories, subCategories, form.type]);

  const [authors, setAuthors] = useState([]);
  const fetchAuthors = async () => {
    const _authors = await DataStore.query(Author);
    setAuthors(_authors.filter((a) => a.sub === authorUser.sub));
  };
  useEffect(() => {
    if (authorUser) fetchAuthors();
  }, [authorUser]);

  const handleChange = (field, value) => {
    setForm((state) => ({ ...state, [field]: value }));
  };

  const handleSave = async () => {
    DataStore.save(
      Draft.copyOf(draft, (updater) => {
        updater.title = form.title;
        updater.description = form.description;
        updater.summary = form.summary;
        updater.price = parseFloat(form.price);
        updater.isbn = form.isbn;
        updater.categoryID = form.category;
        updater.authorID = form.author;
      })
    ).then((result) => onClose());
  };

  return (
    <Dialog open={true} maxWidth={"md"} fullWidth onClose={onClose}>
      <DialogTitle>{t("book.operations.update-dialog")}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ pt: 2 }}>
          <Grid item xs={12} hidden={summary}>
            <TextField
              fullWidth
              value={form.title}
              variant={"outlined"}
              label={t("book.form.title")}
              onChange={(event) => handleChange("title", event.target.value)}
            />
          </Grid>
          <Grid item xs={12} hidden={summary}>
            <TextField
              fullWidth
              multiline
              value={(form && form.description) || ""}
              variant={"outlined"}
              label={t("book.form.description")}
              onChange={(event) =>
                handleChange("description", event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} hidden={!summary}>
            <TextField
              fullWidth
              multiline
              value={(form && form.summary) || ""}
              variant={"outlined"}
              label={t("book.form.summary")}
              onChange={(event) => handleChange("summary", event.target.value)}
            />
          </Grid>
          <Grid item xs={12} hidden={summary}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>{t("book.form.publicProfile")}</InputLabel>
              <Select
                value={form.author}
                onChange={(event) =>
                  setForm((state) => ({ ...state, author: event.target.value }))
                }
              >
                <MenuItem value="">None</MenuItem>
                {authors.map((author) => (
                  <MenuItem value={author.id}>
                    {author.firstName} {author.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              sx={{ pl: 1 }}
              disable={!form.type}
              value={form.family}
              onChange={(event) =>
                setForm((state) => ({ ...state, family: event.target.value }))
              }
              row
            >
              {suitableFamilies.map((family) => (
                <FormControlLabel
                  className={classes.familyButton}
                  value={family.id}
                  control={<Radio />}
                  label={<Typography>{tdb(family.title)}</Typography>}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            {(!form.family ||
              !form.category ||
              categories.find((c) => c.id === form.category).family.id ===
                form.family) && (
              <TextField
                select
                label={t("book.form.category")}
                fullWidth
                disable={!form.type}
                value={form.category}
                onChange={(event) =>
                  setForm((state) => ({
                    ...state,
                    category: event.target.value,
                  }))
                }
              >
                {categories
                  .filter((c) => c.family.id === form.family)
                  .map((category) => (
                    <MenuItem value={category.id}>
                      {tdb(category.title)}
                    </MenuItem>
                  ))}
              </TextField>
            )}
          </Grid>
          <Grid item xs={12}>
            {form.category &&
              subCategories.filter((c) => c.category.id === form.category)
                .length > 0 && (
                <TextField
                  select
                  label={t("book.form.subcategory")}
                  fullWidth
                  disable={!form.type}
                  value={form.subcategory}
                  onChange={(event) =>
                    setForm((state) => ({
                      ...state,
                      subcategory: event.target.value,
                    }))
                  }
                >
                  {subCategories
                    .filter((c) => c.category.id === form.category)
                    .map((subCategory) => (
                      <MenuItem value={subCategory.id}>
                        {tdb(subCategory.title)}
                      </MenuItem>
                    ))}
                </TextField>
              )}
          </Grid>
          <Grid item xs={6} hidden={summary}>
            <TextField
              fullWidth
              value={(form && form.price) || ""}
              variant={"outlined"}
              label={t("book.form.price")}
              onChange={(event) => handleChange("price", event.target.value)}
            />
          </Grid>
          <Grid item xs={6} hidden={summary}>
            <TextField
              fullWidth
              value={(form && form.isbn) || ""}
              variant={"outlined"}
              label={t("book.form.isbn")}
              onChange={(event) => handleChange("isbn", event.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          color={"secondary"}
          startIcon={<Cancel />}
          onClick={onClose}
        >
          {t("generic.cancel")}
        </Button>
        <Button
          variant={"contained"}
          startIcon={<Save />}
          disabled={!form.title || !form.description}
          onClick={handleSave}
        >
          {t("book.operations.update-perform")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  borders: {
    padding: theme.spacing(1),
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.grey[400],
  },
  typeButton: {
    border: "1px solid #ccc",
    width: "100%",
    height: 100,
    borderRadius: theme.shape.borderRadius,
  },
  familyButton: {
    border: "1px solid #ccc",
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
}));
