import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
import { Book, BookContent } from "../../../models";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Add, Visibility, VolumeUp } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { BookAudioPlayer } from "../BookCommon";
import { API, graphqlOperation } from "aws-amplify";
import { listBookContents } from "./../../../graphql/queries";
import { useBookContents } from "../../../hooks/useBookContents";

export function BookBookContents({ bookId, full }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleContent = (contentId) => {
    navigate(`/books/published/${bookId}/${contentId}`);
  };

  const [book, setBook] = useState();
  const fetchBook = async () => setBook(await DataStore.query(Book, bookId));

  const { contents, loading } = useBookContents(bookId);

  useEffect(() => {
    if (bookId) {
      fetchBook();

      const observeBook = DataStore.observe(Book).subscribe((msg) =>
        fetchBook()
      );
      return () => {
        observeBook.unsubscribe();
      };
    }
  }, [bookId]);

  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    if (book && book.status != "Book") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [book]);

  const _renderActions = (params) => {
    return (
      <>
        <IconButton onClick={() => handleContent(params.row.id)}>
          <Visibility />
        </IconButton>
      </>
    );
  };

  const [listenAudioMode, setListenAudioMode] = useState();

  const [content, setContent] = useState("");
  const [freeMode, setFreeMode] = useState("");

  const _renderAudio = (params) => {
    return params.row.audio ? (
      <IconButton
        onClick={() => {
          setListenAudioMode(true);
          setContent(params.row);
          setFreeMode(false);
        }}
      >
        <VolumeUp />
      </IconButton>
    ) : (
      "-"
    );
  };
  const _renderFreeAudio = (params) => {
    return params.row.freeAudio ? (
      <IconButton
        onClick={() => {
          setListenAudioMode(true);
          setContent(params.row);
          setFreeMode(true);
        }}
      >
        <VolumeUp />
      </IconButton>
    ) : (
      "-"
    );
  };
  const _renderChars = (params) => {
    return params.row.text ? params.row.text.length : "-";
  };
  const _renderFreeChars = (params) => {
    return params.row.freeText ? params.row.freeText.length : "-";
  };

  const [textHidden, setTextHidden] = useState("");
  const [audioHidden, setAudioHidden] = useState("");

  useEffect(() => {
    if (book && book.bookType != "Text") {
      setTextHidden(true);
      setAudioHidden(false);
    } else {
      setTextHidden(false);
      setAudioHidden(true);
    }
  }, [book]);

  const columns = [
    { field: "id", headerName: t("generic.id"), hide: true },
    {
      field: "order",
      headerName: t("book.content.fields.order"),
      width: 50,
      align: "right",
    },
    { field: "_version", headerName: "V", width: 50, hide: false },
    { field: "label", headerName: t("book.content.fields.label"), hide: false },
    {
      field: "title",
      headerName: t("book.content.fields.title"),
      hide: false,
      flex: 1,
    },
    {
      field: "audio",
      headerName: t("book.content.fields.audio"),
      hide: audioHidden,
      width: 150,
      renderCell: _renderAudio,
    },
    {
      field: "freeAudio",
      headerName: t("book.content.fields.freeContent"),
      hide: audioHidden,
      width: 150,
      renderCell: _renderFreeAudio,
    },
    {
      field: "chars",
      headerName: t("book.content.fields.chars"),
      hide: textHidden,
      width: full ? 150 : 60,
      renderCell: _renderChars,
      align: "right",
    },
    {
      field: "freeChars",
      headerName: t("book.content.fields.freeContent"),
      hide: textHidden,
      width: full ? 150 : 60,
      renderCell: _renderFreeChars,
      align: "right",
    },
    //{field: 'full',       headerName: t('book.content.fields.title'), renderCell: _renderTitle, flex: 1},
    { field: "actions", headerName: " ", renderCell: _renderActions },
  ];

  return (
    <Card elevation={4} px="16px">
      <CardHeader title={t("book.content.sections")} />
      {loading && (
        <div style={{ textAlign: "center", flex: 1, margin: 24 }}>
          En cours de synchronisation...
        </div>
      )}
      <CardMedia>
        <div style={{ height: 300, padding: 16 }}>
          <DataGrid
            columns={columns}
            rows={contents}
            hideFooter
            density={"compact"}
            //onRowDoubleClick={(params)=>handleContent(params.row.id)}
            onRowClick={(params) => handleContent(params.row.id)}
            square
          />
        </div>
      </CardMedia>
      <CardActions>
        <Box justifyContent={"flex-end"}>
          {listenAudioMode && (
            <BookAudioPlayer
              onClose={() => setListenAudioMode(false)}
              content={content}
              freeMode={freeMode}
            />
          )}
        </Box>
      </CardActions>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  rightAlign: {
    marginLeft: "auto",
  },
  subCardHeader: {
    height: 66,
  },
  subCardContent: {
    height: 120,
  },
}));
