import React, { useEffect, useState } from "react";
import { DataStore, Hub } from "aws-amplify";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { Check, Done, Sync, SyncAlt, Warning } from "@mui/icons-material";

export default function HubMessages() {
  const [outBoxStatusIsEmpty, setOutBoxStatusIsEmpty] = useState();
  const [loading, setLoading] = useState(false);

  const [allEvents, setAllEvents] = useState([]);
  const [countEvents, setCountEvents] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const onEvent = (event) => {
    console.log(event.payload.event);
    if (event.payload.event === "ready") {
      setIsReady(true);
    }
    allEvents.push(event);
    setAllEvents(allEvents);
    setCountEvents(allEvents.length);

    if (
      event.channel === "datastore" &&
      event.payload.event === "outboxStatus"
    ) {
      setOutBoxStatusIsEmpty(event.payload.data.isEmpty);
    }
  };

  const [dialog, setDialog] = useState(false);
  const handleOpenDialog = () => setDialog(true);
  const handleCloseDialog = () => setDialog(false);

  useEffect(() => {
    Hub.listen("datastore", (data) => {
      onEvent(data);
    });
  }, []);

  const handleClear = async () => {
    try {
      setLoading(true);
      await DataStore.clear();
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };
  const handleStart = async () => {
    try {
      setLoading(true);
      await DataStore.start();
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };
  const handleEmpty = () => setAllEvents([]);

  const outputData = (channel, payload) => {
    return payload.data
      ? Object.entries(payload.data).map(([key, value]) => {
          if (typeof value === "string")
            return (
              <div key={key}>
                {key} = {value}
              </div>
            );
          else
            return (
              <div key={key}>
                {key} = {JSON.stringify(value, null, " ")}
              </div>
            );
        })
      : "no data";
  };

  return (
    <div style={{ textAlign: "center", flex: 1 }}>
      {!isReady && <> En cours de synchronisation ...</>}
      {/* <>
        <IconButton onClick={handleOpenDialog}>
          {<Sync />}
          {outBoxStatusIsEmpty ? <Done /> : <Warning />}
        </IconButton>
        <Dialog
          open={dialog}
          fullWidth
          maxWidth={"md"}
          onClose={handleCloseDialog}
        >
          <DialogTitle>Hub messages</DialogTitle>
          <DialogContent>
            {allEvents.map((event, i) => (
              <Grid container key={i}>
                <Grid item xs={2}>
                  {event.channel}
                </Grid>
                <Grid item xs={2}>
                  {event.payload.event}
                </Grid>
                <Grid item xs={8}>
                  {outputData(event.channel, event.payload)}
                </Grid>
              </Grid>
            ))}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleClear}>
              {loading ? "loading" : "Clear DataStore"}
            </Button>
            <Button variant="contained" color="primary" onClick={handleStart}>
              {loading ? "loading" : "Start DataStore"}
            </Button>
            <Button variant="contained" color="primary" onClick={handleEmpty}>
              {loading ? "loading" : "Empty LIST"}
            </Button>
          </DialogActions>
        </Dialog>
      </> */}
    </div>
  );
}
