import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { DataStore } from "aws-amplify";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { School, SchoolUser } from "../../models";
import SchoolDeleteUser from "./SchoolDeleteUser";
import SchoolUserCreate from "./SchoolUserCreate";
import SchoolUserEdit from "./SchoolUserEdit";
import { makeStyles } from "@mui/styles";
import { AddCircle } from "@mui/icons-material";

function UserSchool() {
  const { t, i18n } = useTranslation();
  const { schoolId } = useParams();
  const classes = useStyles();
  //School fetch
  const [school, setSchool] = useState("");
  const fecthSchool = async () => {
    const _school = await DataStore.query(School, schoolId);
    setSchool(_school);
  };
  useEffect(() => {
    if (schoolId) {
      fecthSchool();
      const SchoolSubscribe = DataStore.observe(School).subscribe(() =>
        fecthSchool()
      );
      return () => {
        SchoolSubscribe.unsubscribe();
      };
    }
  }, [schoolId]);

  

  const [schoolUsers, setSchoolUsers] = useState([]);
  const fetchSchoolUsers = async () => {
    const _schoolUsers = await DataStore.query(SchoolUser);
    setSchoolUsers(_schoolUsers.filter((a) => a.school.id === schoolId));
  };
  const [rows, setRows] = useState([]);
  useEffect(() => {
    setRows(schoolUsers);
  }, [schoolUsers]);

  useEffect(() => {
    fetchSchoolUsers();
    const SchoolUserSubscribe = DataStore.observe(
      SchoolUser,schoolId
    ).subscribe((msg) => fetchSchoolUsers());
    return () => {
      SchoolUserSubscribe.unsubscribe();
    };
  }, []);

  const columns = [
    { field: "lastName", headerName: "Nom", flex: 1, hide: false },
    { field: "firstName", headerName: "Prenoms", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phoneNumber", headerName: "Téléphone", flex: 1 },
    { field: "createdAt", headerName: "Date", flex: 1 },
    {
      field: "action",
      headerName: "Actions",
      width: 100,
      align: "right",
      //renderCell: _renderActions,
      disableColumnMenu: true,
    },
  ];

  //manage schooluser
  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [deleteSchoolUser, setDeleteSchoolUser] = useState(false);
  const [editSchoolUser, setEditSchoolUser] = useState("");

  const handleCancelEdit = () => {
    setEditSchoolUser(null);
    setEditMode(false);
  };
  const handleDeleteUser = (id) => {
    setDeleteSchoolUser(true);
  };

  return (
    <Fragment>
      <Grid>
        <Card>
          <CardHeader
            title={<Typography variant={"h5"}>{school.title}</Typography>}
          />
          <CardContent>
            <Grid container spacing={1}>
              <Grid xs={2} item>
                Mobile
              </Grid>
              <Grid xs={10} item>
                {school.phoneNumber}
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid xs={2} item>
                Fixe
              </Grid>
              <Grid xs={10} item>
                {school.fixeNumber}
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid xs={2} item>
                Adresse
              </Grid>
              <Grid xs={10} item>
                {school.address}
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={2}>
              <Grid xs={2} item>
                Description
              </Grid>
              <Grid xs={10} item>
                {school.description}
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* Liste utilisateur */}
      </Grid>
      <Grid mt={3}>
        <Card>
          <CardHeader
            title={
              <Typography variant={"h5"}>Utilisateur établissement</Typography>
            }
          />
          <CardContent>
            <DataGrid
              density={"compact"}
              autoHeight
              rows={rows}
              columns={columns}
              // onRowDoubleClick={(params)=>navigate(params.row.id)}
            />
          </CardContent>
          <CardActions>
            <Button
              startIcon={<AddCircle />}
              className={classes.mt}
              variant="contained"
              onClick={() => setCreateMode(true)}
            >
              {t("generic.Add")}
            </Button>
            {createMode && (
              <SchoolUserCreate
                schoolId={schoolId}
                onClose={() => setCreateMode(false)}
              />
            )}
            {editMode && (
              <SchoolUserEdit
                onClose={handleCancelEdit}
                schoolId={editSchoolUser}
              />
            )}
            <SchoolDeleteUser
              open={deleteSchoolUser}
              onClose={() => setDeleteSchoolUser(false)}
              // id={schoolUserId}
            />
          </CardActions>
        </Card>
      </Grid>
    </Fragment>
  );
}
const useStyles = makeStyles(() => ({
  mt: {
    marginTop: 10,
  },
}));
export default UserSchool;
