import React, { Fragment, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Snackbar,
  Switch,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Cancel, Save } from "@mui/icons-material";
import { DataStore } from "aws-amplify";
import { Product } from "../../models";
import { tdb } from "../Services/translateJSON";
import { t } from "i18next";
function ProductsEdit({ onClose, open, productId }) {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  //fetch product
  const [product, setProduct] = useState([]);
  const fetchProduct = async () => {
    const product = await DataStore.query(Product, productId);
    setProduct(product);
  };
  //observe
  useEffect(() => {
    fetchProduct();
    const productSubscribe = DataStore.observe(Product).subscribe(() =>
      fetchProduct()
    );
    return () => {
      productSubscribe.unsubscribe();
    };
  }, [productId]);
  //Form
  const [form, setForm] = useState({
    title: "",
    description: "",
  });
  //
  useEffect(() => {
    if (productId) {
      setForm({
        title: product.title && product.title,
        description: product.description && product.description,
      });
    } else {
      setForm(null);
    }
  }, [product, productId]);

  const handleUpdate = async () => {
    try {
      const _productUpdate = Product.copyOf(product, (updated) => {
        updated.title = form.title;
        updated.description = form.description;
      });
      await DataStore.save(_productUpdate);
      if (onClose) onClose();
    } catch (e) {
      console.log(e);
      setError(e);
      setSnackbarOpen(true);
    }
  };

  return (
    <Fragment>
      <Dialog maxWidth={"lg"} open={true} onClose={onClose}>
        <DialogTitle>{t("Product.Add")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t("generic.Nom")}
                variant="outlined"
                value={(form && form.title) || ""}
                //   error={!!formErrors.title}
                //   helperText={formErrors.title}
                onChange={(event) =>
                  setForm((state) => ({ ...state, title: event.target.value }))
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                value={(form && form.description) || ""}
                label={t("generic.Desciption")}
                variant="outlined"
                id="standard-multiline-static"
                multiline
                rows={4}
                onChange={(event) =>
                  setForm((state) => ({
                    ...state,
                    description: event.target.value,
                  }))
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<Cancel />}
            variant="contained"
            onClick={onClose}
            color="secondary"
          >
            {t("generic.Cancel")}
          </Button>
          <Button
            startIcon={<Save />}
            onClick={handleUpdate}
            variant="contained"
            color="primary"
          >
            {t("generic.Update")}
          </Button>
        </DialogActions>
        <Snackbar open={snackbarOpen} onClose={onClose}>
          <Alert onClose={onClose} severity="error" sx={{ width: "100%" }}>
            {error}
          </Alert>
        </Snackbar>
      </Dialog>
    </Fragment>
  );
}

export default ProductsEdit;
