import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
import { Book } from "../../../models";
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { BookAudioPlayer } from "../BookCommon";
import { AudioData, TextLayout } from "../Draft/BookDraftContent";

import { useBookContent } from "../../../hooks/useBookContent";

export function BookBookContent({ bookId, contentId }) {
  const { t } = useTranslation();

  // load book
  const [book, setBook] = useState();
  const fetchBook = async () => setBook(await DataStore.query(Book, bookId));
  useEffect(() => {
    if (bookId) {
      fetchBook();
      const observeBook = DataStore.observe(Book, bookId).subscribe(() =>
        fetchBook()
      );
      return () => {
        observeBook.unsubscribe();
      };
    }
  }, [bookId]);

  const { content } = useBookContent(contentId);

  // audio or text ? take bookType
  // const [displayType, setDisplayType] = useState("");
  // useEffect(()=>{if (book)setDisplayType(book.bookType)}, [book])

  // show emptiness error
  const [error, setError] = useState(false);
  useEffect(() => {
    let error = false;
    if (book && content) {
      if (book.bookType === "Audio" && !content.audioData) error = true;
      if (book.bookType === "Text" && !content.text) error = true;
      if (!content.title && !content.label) error = true;
    }
    setError(error);
  }, [book, content]);

  // modes
  const [playingData, setPlayingData] = useState(null);

  return (
    <Card elevation={4}>
      <CardHeader
        title={content && content.label}
        subheader={content && content.title}
        action={
          <>
            <IconButton component={NavLink} to={".."}>
              <Close />
            </IconButton>
          </>
        }
      />
      {error && (
        <Alert severity={"error"} square>
          {t("book.content.error-empty")}
        </Alert>
      )}
      {content && book && book.bookType === "Audio" && (
        <CardContent>
          {content.audioData && (
            <AudioData
              label={t("book.content.fields.audio")}
              data={{ ...content.audioData, level: "private" }}
              action={setPlayingData}
              tempURL={content.tempURL}
            />
          )}
          {content.freeAudioData && (
            <AudioData
              label={t("book.content.fields.freeContent")}
              data={{ ...content.freeAudioData, level: "protected" }}
              action={setPlayingData}
              boxProps={{ borderTop: 1, borderColor: "#ccc" }}
            />
          )}
        </CardContent>
      )}

      {content && <TextLayout content={content} />}
      {playingData && (
        <BookAudioPlayer
          data={playingData}
          onClose={() => setPlayingData(null)}
        />
      )}
    </Card>
  );
}
