

export function aggregateValues(values, callback) {
    const _values = {};
    values.map(value=>{
        const _expr = callback(value);
        if (!(_expr in _values)) {
            _values[_expr] = [];
        }
        _values[_expr].push(value)
    })
    return _values
}