import React, {useEffect, useState} from 'react'
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel, Grid,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Cancel, Save, WarningOutlined} from "@mui/icons-material";
import {DataStore} from "aws-amplify";
import {Author, AuthorUser, Book, BookContent, Draft, DraftContent} from "../../models";

export default function AuthorUserDelete({open, onClose, id}) {
    const {t, i18n} = useTranslation();
    //AuthorUser
    const [authorUser, setAuthorUser] = useState("");
    const fecthAuthorUser = async () => {
        const _authorUser = await DataStore.query(AuthorUser, id);
        setAuthorUser(_authorUser)
    }
    useEffect(()=>{
        if(id) {
            fecthAuthorUser();
        }
    }, [id])
    //Author
    const [authors, setAuthors] = useState([]);
    const fetchAuthors = async () => {
        setAuthors((await DataStore.query(Author)).filter(a=>a.sub == authorUser.sub))
    }

    useEffect(()=>{if(authorUser) fetchAuthors();}, [authorUser])

    //BOOK
    const [books, setBooks] = useState([]);
    const fetchBooks = async () => setBooks((await DataStore.query(Book)).filter(b => b.sub === authorUser.sub))
    
    //BOOK CONTENT
    const [bookContents, setBookContents] = useState([]);
    const fetchBookContents = async () => setBookContents((await DataStore.query(BookContent)).filter(b => b.sub === authorUser.sub))

    //DRAFT
    const [drafts, setDrafts] = useState([]);
    const fetchDrafts = async () => setDrafts((await DataStore.query(Draft)).filter(d => d.sub == authorUser.sub))
    
    //DRAFT CONTENT
    const [draftContents, setDraftContents] = useState([]);
    const fetchDraftContents = async () => setDraftContents((await DataStore.query(DraftContent)).filter(d => d.sub == authorUser.sub))
    
    useEffect(()=>{if(authorUser)  {
        fetchBooks();
        fetchBookContents();
        fetchDrafts()
        fetchDraftContents();
        fetchAuthors()
        const obsA  = DataStore.observe(Author      ).subscribe(msg=>{if(msg.element.sub===authorUser.sub)fetchAuthors()})
        const obsB  = DataStore.observe(Book        ).subscribe(msg=>{if(msg.element.sub===authorUser.sub)fetchBooks()})
        const obsBC = DataStore.observe(BookContent ).subscribe(msg=>{if(msg.element.sub===authorUser.sub)fetchBookContents()})
        const obsD  = DataStore.observe(Draft       ).subscribe(msg=>{if(msg.element.sub===authorUser.sub)fetchDrafts()})
        const obsDC = DataStore.observe(DraftContent).subscribe(msg=>{if(msg.element.sub===authorUser.sub)fetchDraftContents()})
        return ()=>{
            obsA.unsubscribe();
            obsB.unsubscribe();
            obsBC.unsubscribe();
            obsD.unsubscribe();
            obsDC.unsubscribe();
        }
    }}, [authorUser])

    const [checked, setChecked] = useState(false);
    
    const deleteObjects = async (objects) => {
        return Promise.all(objects.map(object=>{
            DataStore.delete(object)
        }))
    }

    //Delete
    const handleDelete = async () => {
        try {
            if(authors)await deleteObjects(authors)
            if(books)await deleteObjects(books)
            if(bookContents)await deleteObjects(bookContents)
            if(drafts)await deleteObjects(drafts)
            if(draftContents)await deleteObjects(draftContents)
            if(authorUser.unsubscribe) {
                await DataStore.delete(authorUser)
            }
            else {
                await DataStore.save(AuthorUser.copyOf(authorUser, updater => {
                    updater.validated = false;
                    updater.unsubscribe = true;
                }))
                // if(onClose) onClose()
            }
            //Cognito User

        } catch (e) {
            console.log(e)
        }

    }

    return (
        <Dialog color="primary" open={open} onClose={onClose} fullWidth >
            <DialogTitle>{`${t('authorUser.del')} ${authorUser && authorUser.lastName} ${authorUser && authorUser.firstName}`}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {`${t('authorUser.delInfo')}`}
                    </Grid>
                    <Grid item xs={12}>
                        {`${authors && authors.length} ${t('authorUser.authors')} `}
                    </Grid>
                    <Grid item xs={6}>
                        {`${books && books.length} ${t('authorUser.books')} `}
                    </Grid>
                    <Grid item xs={6}>
                        {`${bookContents && bookContents.length} ${t('authorUser.bookContents')} `}
                    </Grid>
                    <Grid item xs={6}>
                        {`${drafts && drafts.length} ${t('authorUser.drafts')} `}
                    </Grid>
                    <Grid item xs={6}>
                        {`${draftContents && draftContents.length} ${t('authorUser.draftContents')} `}
                    </Grid>


                </Grid>
                <FormControlLabel
                    label={t('generic.understand')}
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={event =>setChecked(event.target.checked)}
                        />
                    }
                />
            </DialogContent>
            <DialogActions>
                <Button startIcon={<Cancel />} variant="contained"onClick={onClose} color="secondary">{t("generic.close")}</Button>
                <Button disabled={!checked} startIcon={<WarningOutlined />} onClick={handleDelete} variant="contained" color="warning">{t("generic.Delete")}</Button>
            </DialogActions>

        </Dialog>
    )

}