import React, { Fragment, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SettingsList from "./SettingsList";

function SettingsLayout() {
    const [value, setValue] = useState('cgu');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
  return (
    <Fragment>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="basic tabs example">
              <Tab label="CGU" value="cgu" />
              <Tab label="Mentions" value="mentions" />
            </TabList>
          </Box>
          <TabPanel value="cgu">
         <SettingsList/>
          </TabPanel>
          <TabPanel value="mentions">
          <p>MENTIONS LEGALE</p>
          </TabPanel>
        </TabContext>
      </Box>
    </Fragment>
  );
}

export default SettingsLayout;
