import React, {useEffect, useState} from "react";
import {
    Alert, Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid, MenuItem, Snackbar, Switch,
    TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Cancel, Save} from "@mui/icons-material";
import {DataStore} from "aws-amplify";
import {Category, Family} from "../../models";
import {tdb} from "../Services/translateJSON"

export default function CategoryCreate({onClose}) {
    const {t, i18n} = useTranslation();
    const [form, setForm] = useState({
        title: "",
        name: "",
        description: "",
        content:"",
        family:"",
        active: false
    })
    const handleChange = (field, value) => {
        setForm(state => ({...state, [field]: value}))
    }

    //ERROR
    const [formErrors, setFormErrors] = useState({});
    const [error, setError] = useState()
    const [snackbarOpen, setSnackbarOpen] = useState(false)

    //FETCH FAMILIES
    const [families, setFamilies] = useState([]);
    const fetchFamilies = async () => {
        const _families = await DataStore.query(Family);
        setFamilies(_families)
    }
    useEffect(()=> {
        fetchFamilies();
        const familiesSubscribes = DataStore.observe(Family).subscribe(()=>fetchFamilies())
        return () => {
            familiesSubscribes.unsubscribe()
        }
    }, [])

    //SAVE
    const handleSave = async () => {
        const _errors = {};
        const _required = `${t('generic.Required')}`;
        if (form.title < 2)_errors['title'] = _required
        setFormErrors(_errors)

        const _category = {
            title: JSON.stringify({
                "default": form.title
            }),
            name:form.name,
            description: JSON.stringify({
                "default": form.description
            }),
            content: JSON.stringify({
                "default": form.content
            }),
            active: form.active,
            familyID: form.family
        }
        let category
        try {
            const __category = new Category(_category);
            category = await DataStore.save(__category)
            if (onClose)onClose();
        }
        catch (e) {
            console.log(e);
            setError(e)
            setSnackbarOpen(true)
        }
    }


    return <Dialog maxWidth={"sm"} open={true} onClose={onClose} >
        <DialogTitle>{t('category.Add')}</DialogTitle>
        <DialogContent>
            <Grid container spacing={2}
                  component="form"
                  noValidate
                  autoComplete="off"
                  mt={1}
            >
                <Grid item xs={8}>
                    <TextField select
                               variant={"outlined"}
                               label={t('category.Family')}
                               fullWidth
                               value={form.family}
                               onChange={event => handleChange("family", event.target.value)}>
                        {families.map(f=>(<MenuItem value={f.id}>{tdb(f.title.default)}</MenuItem> ))}
                    </TextField>
                </Grid>
                <Grid item xs={4}>
                    <Box p={1}>
                        <FormControlLabel control={<Switch  checked={!!form.active} value={Boolean(form.active)} onChange={event => setForm(state => ({...state, active: event.target.checked}))} />} label={t("generic.Activate")} />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={t('generic.Title')}
                        variant="outlined"
                        value={form.title}
                        error={!!formErrors.title} helperText={formErrors.title}
                        onChange={event => handleChange("title", event.target.value)} />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={t('generic.Name')}
                        helperText={t('family.HelpName')}
                        variant="outlined"
                        value={form.name}
                        onChange={event => handleChange("name", event.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={t('family.Description')}
                        helperText={t('family.HelpDescription')}
                        variant="outlined"
                        multiline
                        rows={3}
                        value={form.description}
                        onChange={event => handleChange("description", event.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={t('family.Content')}
                        helperText={t('family.HelpContent')}
                        variant="outlined"
                        multiline
                        rows={6}
                        value={form.content}
                        onChange={event => handleChange("content", event.target.value)} />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button startIcon={<Cancel />} variant="contained"onClick={onClose} color="secondary">{t("generic.Cancel")}</Button>
            <Button disabled={!form.family} startIcon={<Save />} onClick={handleSave} variant="contained" color="primary">{t("generic.Save")}</Button>
        </DialogActions>
        <Snackbar open={snackbarOpen} onClose={onClose}>
            <Alert onClose={onClose} severity="error" sx={{ width: '100%' }}>
                {error}
            </Alert>
        </Snackbar>
    </Dialog>
}