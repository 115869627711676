import React, { Fragment } from 'react';

function SchoolUserEdit() {
    return (
        <Fragment>
             
        </Fragment>
    );
}

export default SchoolUserEdit;