import { Cancel, Save } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { DataStore } from "aws-amplify";
import { t } from "i18next";
import React, { useState } from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { SchoolUser } from "../../models";

function SchoolUserCreate({ open, onClose, schoolId }) {
  const navigate = useNavigate();
  console.log(schoolId);
  const { t, i18n } = useTranslation();
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    schoolID: "",
  });
  const handleChange = (field, value) => {
    setForm((state) => ({ ...state, [field]: value }));
  };
  //SAVE
  const handleSave = async () => {
    const _form = {
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
      phoneNumber: form.phoneNumber,
      address: form.address,
      schoolID: schoolId,
    };
    try {
      const __form = new SchoolUser(_form);
      await DataStore.save(__form);
      if (onClose) onClose();
    } catch (e) {
      console.log(e);
      console.log("schoolUser Not  Saved!");
    }
  };
  return (
    <Fragment>
      <Dialog maxWidth={"lg"} open={true} onClose={onClose}>
        <DialogTitle>{t("schoolUser.Add")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Nom"
                variant="outlined"
                value={form.firstName}
                //   error={!!formErrors.title}
                //   helperText={formErrors.title}
                onChange={(event) =>
                  handleChange("firstName", event.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Prenoms"
                variant="outlined"
                value={form.lastName}
                //   error={!!formErrors.title}
                //   helperText={formErrors.title}
                onChange={(event) =>
                  handleChange("lastName", event.target.value)
                }
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Téléphone"
                variant="outlined"
                value={form.phoneNumber}
                //   error={!!formErrors.title}
                //   helperText={formErrors.title}
                onChange={(event) =>
                  handleChange("phoneNumber", event.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={form.email}
                //   error={!!formErrors.title}
                //   helperText={formErrors.title}
                onChange={(event) => handleChange("email", event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Adresse"
                variant="outlined"
                value={form.address}
                //   error={!!formErrors.title}
                //   helperText={formErrors.title}
                onChange={(event) =>
                  handleChange("address", event.target.value)
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<Cancel />}
            variant="contained"
            onClick={onClose}
            color="secondary"
          >
            {t("generic.Cancel")}
          </Button>
          <Button
            startIcon={<Save />}
            onClick={handleSave}
            variant="contained"
            color="primary"
          >
            {t("generic.Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default SchoolUserCreate;
