import React from 'react';
import { Route, Routes } from 'react-router';
import SchoolList from './SchoolList';
import UserSchool from './UserSchool';

function School() {
    return (
        <Routes>
            <Route path={""} element={<SchoolList/>} />
            <Route path={":schoolId/*"} element={<UserSchool />} />
        </Routes>
    )
}

export default School;