import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { API, graphqlOperation } from "aws-amplify";

import {
  Avatar,
  Box,
  Button,
  CardActions,
  CardContent,
  Chip,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import { Book, BookContent, Draft, DraftContent } from "../../../models";
import { DataStore } from "aws-amplify";
import { makeStyles } from "@mui/styles";
import { Alert } from "@mui/lab";
import { BookStatusChip } from "../../Misc/Status";
import { useNavigate } from "react-router-dom";
import { MenuBook, Warning } from "@mui/icons-material";
import { useDraftContents } from "../../../hooks/useDraftContents";
import {
  createBookContent,
  updateBookContent as updateBookContentMutation,
} from "../../../graphql/mutations";

export default function BookDraftStatus({ draft }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();

  const [books, setBooks] = useState([]);
  const fetchBooks = async () => {
    const _books = await DataStore.query(Book);
    setBooks(_books.filter((b) => b.draft.id === draft.id));
  };
  useEffect(() => {
    if (draft) fetchBooks();
  }, [draft]);

  const navigateToBook = (book) => {
    navigate(`/books/published/${book.id}`);
  };

  return (
    <>
      <CardContent className={classes.subCardContent}>
        <BookStatusChip status={draft.status} active={true} />
        <List dense>
          <ListItem>
            <ListItemText>{t("Version publiée :")}</ListItemText>
          </ListItem>
          {books.map((book) => (
            <ListItem button onClick={() => navigateToBook(book)}>
              <ListItemIcon sx={{ width: 40 }}>
                <MenuBook />
              </ListItemIcon>
              <ListItemText>{book.title}</ListItemText>
            </ListItem>
          ))}
        </List>
      </CardContent>
      <BookStatusButton draft={draft} />
    </>
  );
}

function BookStatusButton({ draft }) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [disabled, setDisabled] = useState(true);

  const { contents } = useDraftContents(draft.id);

  const [books, setBooks] = useState([]);
  const fetchBooks = async () => {
    const _books = await DataStore.query(Book);
    setBooks(
      _books
        .filter((b) => b.draft.id === draft.id)
        .sort((a, b) =>
          a._created === b._created ? 0 : a._created > b._created ? 1 : -1
        )
    );
  };

  useEffect(() => {
    if (draft) {
      fetchBooks();
    }
  }, [draft]);

  const [book, setBook] = useState();
  useEffect(() => {
    if (books.length) setBook(books[0]);
  }, [books]);

  const [bookContents, setBookContents] = useState();
  const fetchBookContents = async () =>
    setBookContents(
      (await DataStore.query(BookContent)).filter(
        (c) => c.book && c.book.id === book.id
      )
    );
  useEffect(() => {
    if (book) fetchBookContents();
  }, [book]);

  useEffect(() => {
    setDisabled(
      draft &&
        !["Draft", "PublicationWanted", "UpdateWanted"].includes(draft.status)
    );
  }, [draft]);

  //REFUSED
  const handleRefuse = async () => {
    try {
      const _newStatus = Draft.copyOf(draft, (updated) => {
        updated.status = "PublicationDeclined";
      });
      await DataStore.save(_newStatus);
    } catch (e) {
      console.log(e);
    }
  };

  //REFUSED
  const handleRefuseUpdate = async () => {
    try {
      const _newStatus = Draft.copyOf(draft, (updated) => {
        updated.status = "UpdateDeclined";
      });
      await DataStore.save(_newStatus);
    } catch (e) {
      console.log(e);
    }
  };

  //PUBLISHED

  const createBookContentFromDraftContent = async (bookId, draftContent) => {
    const createBookContentResponse = await API.graphql({
      query: createBookContent,
      variables: {
        input: {
          sub: draftContent.sub,
          order: draftContent.order,
          label: draftContent.label,
          title: draftContent.title,
          text: draftContent.text,
          freeText: draftContent.freeText,
          audioData: JSON.stringify(draftContent.audioData),
          freeAudioData: JSON.stringify(draftContent.freeAudioData),
          draftContentID: draftContent.id,
          bookID: bookId,
        },
      },
    });
    console.log(createBookContentResponse);
  };

  const updateBookContent = async (input) => {
    const updateBookContentResponse = await API.graphql({
      query: updateBookContentMutation,
      variables: {
        input,
      },
    });
    console.log(updateBookContentResponse);
  };

  const handlePublish = async () => {
    try {
      const _book = new Book({
        sub: draft.sub,
        bookType: draft.bookType,
        title: draft.title,
        description: draft.description,
        summary: draft.summary,
        cover: draft.cover,
        coverData: draft.coverData,
        coverKeys: draft.coverKeys,
        coverFile: draft.coverFile,
        epub: draft.epub,
        freeEpub: draft.freeEpub,
        epubData: draft.epubData,
        freeEpubData: draft.freeEpubData,
        price: draft.price,
        isbn: draft.isbn,
        author: draft.author,
        family: draft.family,
        category: draft.category,
        subCategory: draft.subCategory,
        draft: draft,
        status: "Published",
        // add field to set as current version
      });
      //Save
      const __book = await DataStore.save(_book);
      console.debug("BOOK", __book);

      await Promise.all(
        contents.map((c) => createBookContentFromDraftContent(_book.id, c))
      );

      await DataStore.save(
        Draft.copyOf(draft, (updater) => {
          updater.status = "Published";
        })
      );
    } catch (e) {
      console.error(e);
      setError("La publication a échoué", JSON.stringify(e));
    }
  };

  const handleUpdate = async () => {
    const book = books[0];
    const _update = await DataStore.save(
      Book.copyOf(book, (updater) => {
        updater.title = draft.title;
        updater.description = draft.description;
        updater.summary = draft.summary;
        updater.cover = draft.cover;
        updater.coverData = draft.coverData;
        updater.coverKeys = draft.coverKeys;
        updater.coverFile = draft.coverFile;
        updater.epub = draft.epub;
        updater.freeEpub = draft.freeEpub;
        updater.epubData = draft.epubData;
        updater.freeEpubData = draft.freeEpubData;
        updater.price = draft.price;
        updater.isbn = draft.isbn;
        updater.author = draft.author;
        updater.family = draft.family;
        updater.category = draft.category;
        updater.subCategory = draft.subCategory;
      })
    );

    await Promise.all(
      await bookContents.map(async (bookContent) => {
        const content = bookContent.draftContentID
          ? contents.find((c) => c.id === bookContent.draftContentID)
          : contents.find((c) => c.order === bookContent.order);
        if (content) {
          await updateBookContent({
            id: bookContent.id,
            _version: bookContent._version,
            label: content.label,
            title: content.title,
            text: content.text,
            freeText: content.freeText,
            audioData: JSON.stringify(content.audioData),
            freeAudioData: JSON.stringify(content.freeAudioData),
          });
        }
      })
    );

    DataStore.save(
      Draft.copyOf(draft, (updater) => {
        updater.status = "Published";
      })
    );
  };

  const [error, setError] = useState(false);

  useEffect(() => {
    if (!draft.author || !draft.price || !draft.cover) {
      setError("Auteur, prix ou couverture manquante");
    } else {
      setError(false);
    }
  }, [draft]);

  return (
    <CardActions
      className={classes.subCardActions}
      style={{ alignItems: "flex-end", justifyContent: "flex-end" }}
    >
      {!!error && (
        <Box flexGrow={1}>
          <IconButton color={"error"}>
            <Warning />
          </IconButton>
          <div>{error}</div>
        </Box>
      )}
      {draft && draft.status === "PublicationWanted" && (
        <Stack spacing={2} direction="row">
          <Button onClick={handleRefuse} disabled={disabled} variant="outlined">
            {t("generic.refused")}
          </Button>
          <Button
            onClick={handlePublish}
            disabled={disabled}
            variant="contained"
          >
            {t("generic.published")}
          </Button>
        </Stack>
      )}
      {draft && draft.status === "UpdateWanted" && (
        <Stack spacing={2} direction="row">
          <Button
            onClick={handleRefuseUpdate}
            disabled={disabled}
            variant="outlined"
          >
            {t("generic.refused")}
          </Button>
          <Button
            onClick={handleUpdate}
            disabled={disabled}
            variant="contained"
          >
            {t("generic.published")}
          </Button>
        </Stack>
      )}
    </CardActions>
  );
}

const useStyles = makeStyles((theme) => ({
  subCardContent: {
    minHeight: 170,
  },
  subCardActions: {
    minHeight: 80,
  },
  errorMessage: {
    marginTop: -180,
  },
}));
