import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import {DataStore} from "aws-amplify";
import {Family} from "../../models";
import IconButton from "@mui/material/IconButton";
import {AddCircle, DeleteForever, Edit, VisibilitySharp} from "@mui/icons-material";
import {DataGrid} from "@mui/x-data-grid";
import {Button, Card, CardActions, CardContent, CardHeader, Typography} from "@mui/material";
import FamilyCreate from "./FamilyCreate";
import {tdb} from "../Services/translateJSON"
import FamilyEdit from "./FamilyEdit";
import FamilyDelete from "./FamilyDelete";

export default function FamilyList() {
    const classes = useStyles();
    const {t, i18n} = useTranslation();

    //MODES
    const [createMode, setCreateMode] = useState(false);
    const handleCreate = () => setCreateMode(true);
    const handleCancelCreate = () => setCreateMode(false);

    const [editMode, setEditMode] = useState(false);
    const [editFamily, setEditFamily] = useState();
    const handleEdit = (id) => {
        setEditFamily(id)
        setEditMode(true);
    }
    const handleCancelEdit = () => {
        setEditFamily(null);
        setEditMode(false)
    }

    const [familyId, setFamilyId] = useState("");
    const [deleteFamily, setDeleteFamily] = useState(false);

    const handleDelete = (id) => {
        setFamilyId(id)
        setDeleteFamily(true)
    }



    //FAMILIES
    const [families, setFamilies] = useState([]);
    const fetchFamilies = async () => {
        const _families = await DataStore.query(Family);
        setFamilies(_families)
    }
    useEffect(()=> {
        fetchFamilies();
        const familiesSubscribes = DataStore.observe(Family).subscribe(()=>fetchFamilies())
        return () => {
            familiesSubscribes.unsubscribe()
        }
    }, [])

    //GRID PARAMS
    const _renderActions = (params) => {
        return (1 || params.row === 0) && <>
            <IconButton size={"small"} onClick={() => handleEdit(params.row.id)}><Edit /></IconButton>
            <IconButton size={"small"} onClick={()=> handleDelete(params.row.id)}><DeleteForever/></IconButton>
        </>;
    }

    const columns = [
        {field:'id', headerName: "id", flex:1, hide: true},
        {field:'_version', headerName: "v", width:70 },
        {field: 'name', headerName: "Nom Admin", flex:1},
        {field:'title', headerName: "titre", flex:1, renderCell: (params => (tdb(params.value.default)))  },
        {field:'description', headerName: "description", flex:1, renderCell: (params => (tdb(params.value.default)))  },
        {field:'active', headerName: "Activé", width: 70, renderCell: (params)=>(params.row.active === true ? `${t('generic.Yes')}` : `${t('generic.No')}`)},
        {field: 'action', headerName: 'Action', width: 150, renderCell: _renderActions, disableColumnMenu: true }
    ]
    const rows = families;

    return  (
        <Card>
            <CardHeader
                title={<Typography variant={"h5"}>{t("family.List")}</Typography> }
            />
            <CardContent>
                <DataGrid
                    density={"compact"}
                    autoHeight
                    rows={rows}
                    columns={columns}
                />
            </CardContent>
            <CardActions>
                <Button startIcon={<AddCircle/>} className={classes.mt} variant="contained" onClick={handleCreate}>{t("generic.Add")} </Button>
                <FamilyCreate onClose={handleCancelCreate} open={createMode} />
                {editMode && <FamilyEdit onClose={handleCancelEdit} familyId={editFamily}/>}
                <FamilyDelete open={deleteFamily} onClose={()=>setDeleteFamily(false)} id={familyId} />
            </CardActions>
        </Card>
    )
}

const useStyles = makeStyles(() => ({
    mt: {
        marginTop: 10,
    }
}))