import React, {useState} from "react";
import {Route, Routes, useParams} from "react-router-dom";
import PublicAuthorList from "./PublicAuthorList";


export default function PublicAuthors() {
    return (
        <Routes>
            <Route path={""} element={<PublicAuthorList />} />
            {/*<Route path={":authorId/*"} element={<AuthorUserDetails />} />*/}
        </Routes>
    )
}