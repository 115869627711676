import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DataStore} from "aws-amplify";
import {Category, Family} from "../../models";
import {Alert} from "@mui/lab";
import {Cancel, WarningOutlined} from "@mui/icons-material";
import {tdb} from "../Services/translateJSON";


export default function FamilyDelete({open, onClose, id}) {
    const {t, i18n} = useTranslation();
    const [family, setFamily] = useState("");
    const [categories, setCategories] = useState([]);

    const fetchFamily = async () => {
        const _family = await DataStore.query(Family, id)
        setFamily(_family)
    }

    const fetchCategories = async () => {
        const _categories = await DataStore.query(Category)
        setCategories(_categories.filter(c => c.family.id === id))
    }

    useEffect(()=>{
        if(id) {
            fetchFamily()
            fetchCategories()
        }
    }, [id])

    const [error, setError] = useState(false);

    useEffect(()=>{
        if(categories && categories.length > 0) {
            setError(true)
        }
        else {
            setError(false)
        }
    }, [categories])

    const handleDelete = () => {
        try {
            if(family) {
                DataStore.delete(family)
            }
            if(onClose)onClose()
        }
        catch (e) {
            console.log(e)
        }

    }
    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>{t("family.delete-family")}</DialogTitle>
            <DialogContent>
                {t("family.delete-info")} {family && tdb(family.title)}
                {error && <Alert severity="error" square>{t("family.delete-error")}</Alert> }
            </DialogContent>
            <DialogActions>
                <Button startIcon={<Cancel />} variant="contained"onClick={onClose} color="secondary">{t("generic.Cancel")}</Button>
                <Button disabled={error} startIcon={<WarningOutlined />} onClick={handleDelete} variant="contained" color="warning">{t("generic.del")}</Button>
            </DialogActions>
        </Dialog>
    )
}