import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Delete } from "@mui/icons-material";
import { useDraftContent } from "../../../hooks/useDraftContent";

export default function BookDraftContentDelete({ onClose, contentId }) {
  const { t, i18n } = useTranslation();
  const { draftContent: contentDeleted, deleteDraftContent } =
    useDraftContent(contentId);

  const handleDelete = async () => {
    try {
      await deleteDraftContent();
      if (onClose) onClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <DialogTitle> {t("book.content.operations.delete-content")}</DialogTitle>
      <DialogContent>
        {t("book.content.operations.delete-button")}: {contentDeleted.label}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={"secondary"} variant={"contained"}>
          {t("generic.cancel")}
        </Button>
        <Button
          startIcon={<Delete />}
          variant={"contained"}
          onClick={handleDelete}
        >
          {t("book.content.operations.delete-button")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
