import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Auth, DataStore, Storage } from "aws-amplify";
import { Draft, DraftContent } from "../../../models";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import FieldSet from "../../Misc/FieldSet";
import { Save, Upload } from "@mui/icons-material";
import prettySize from "../../Services/prettySize";
import { makeStyles, styled } from "@mui/styles";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDraftContent } from "../../../hooks/useDraftContent";

export function BookDraftContentsEdit({ draftId, contentId, onClose }) {
  const { t } = useTranslation();
  const { draftContent: content, updateDraftContent } =
    useDraftContent(contentId);

  const [title, setTitle] = useState("");
  const [label, setLabel] = useState("");
  const [text, setText] = useState("");
  const [freeText, setFreeText] = useState("");
  const [order, setOrder] = useState(0);

  const handleChange = (event, editor) => {
    const data = editor.getData();
    setText(data);
  };

  const handleFreeChange = (event, editor) => {
    const data = editor.getData();
    setFreeText(data);
  };

  const [draft, setDraft] = useState();
  const fetchDraft = async () =>
    setDraft(await DataStore.query(Draft, draftId));

  useEffect(() => {
    if (draftId && contentId) {
      fetchDraft();
    }
  }, [draftId, contentId]);

  useEffect(() => {
    if (content) {
      setTitle(content.title);
      setLabel(content.label);
      setText(content.text ? content.text : "");
      setOrder(content.order);
    }
  }, [content]);

  const handleSave = async () => {
    await updateDraftContent({ title, order, label, text, freeText });
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth={"md"} fullWidth>
      <DialogTitle sx={{ backgroundColor: "primary.main" }}>
        {t("book.content.operations.update-dialog")}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={8}>
            <TextField
              variant={"outlined"}
              label={t("book.content.fields.label")}
              value={label}
              onChange={(event) => setLabel(event.target.value)}
              helperText={t("book.content.fields.label-helper")}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              variant={"outlined"}
              label={t("book.content.fields.order")}
              type={"number"}
              value={order}
              onChange={(event) => setOrder(event.target.value)}
              helperText={t("book.content.fields.order-helper")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant={"outlined"}
              label={t("book.content.fields.title")}
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              helperText={t("book.content.fields.title-helper")}
            />
          </Grid>
          <Grid item xs={12}>
            <CKEditor
              editor={ClassicEditor}
              data={text}
              config={{
                toolbar: ["bold", "italic", "undo", "redo"],
              }}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log("Editor is ready to use!", editor);
              }}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>{t("book.content.fields.freeContent")}</Typography>
            <CKEditor
              editor={ClassicEditor}
              data={freeText}
              config={{
                toolbar: ["bold", "italic", "undo", "redo"],
              }}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
              }}
              onChange={handleFreeChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={"secondary"} variant={"contained"}>
          {t("generic.cancel")}
        </Button>
        <Button
          startIcon={<Save />}
          variant={"contained"}
          disabled={!title && !label}
          onClick={handleSave}
        >
          {t("book.content.operations.update-perform")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const Input = styled("input")({
  display: "none",
});

const useStyles = makeStyles((theme) => ({
  rightAlign: {
    marginLeft: "auto",
  },
  subCardHeader: {
    height: 66,
  },
  subCardContent: {
    height: 120,
  },
}));
