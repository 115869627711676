import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Book,
  Category,
  Edit,
  MenuBook,
  OpenInNew,
  Save,
  Upload,
  UploadFileOutlined,
} from "@mui/icons-material";
import { hasUnreliableEmptyValue } from "@testing-library/user-event/dist/utils";
import { DataStore, Storage } from "aws-amplify";
import { Draft } from "../../../models";
import FieldSet from "../../Misc/FieldSet";
import prettySize from "../../Services/prettySize";
import { makeStyles, styled } from "@mui/styles";
import { v4 as uuidv4 } from "uuid";
import awsExports from "../../../aws-exports";
import { tdb } from "../../Services/translateJSON";
import moment from "moment";

export default function BookDraftEpub({ draftId, editable }) {
  const { t } = useTranslation();

  const [draft, setDraft] = useState();
  const fetchDraft = async () =>
    setDraft(await DataStore.query(Draft, draftId));
  useEffect(() => {
    if (draftId) {
      fetchDraft();
    }
  }, [draftId]);

  const [commUploadMode, setCommUploadMode] = useState(false);
  const [freeUploadMode, setFreeUploadMode] = useState(false);

  const [commURL, setCommUrl] = useState("");
  const [freeURL, setFreeUrl] = useState("");

  const getFreeUrl = async () =>
    setFreeUrl(await Storage.get(draft.freeEpubData.key, { level: "public" }));
  const getCommUrl = async () =>
    setCommUrl(await Storage.get(draft.epubData.key, { level: "private" }));

  useEffect(() => {
    if (draft && draft.freeEpub) getFreeUrl();
    if (draft && draft.epub) getCommUrl();
  }, [draft]);

  return (
    <Card elevation={4}>
      <CardHeader title={t("book.content.fields.epub")} />
      <CardContent>
        <EpubLine
          label={t("book.content.fields.myEpub")}
          epub={draft && draft.epubData}
          link={commURL}
          action={() => setCommUploadMode(true)}
          actionLabel={t("book.content.operations.create-epub")}
          editable={editable}
        />
        <EpubLine
          label={t("book.content.fields.myFreeEpub")}
          epub={draft && draft.freeEpubData}
          link={freeURL}
          action={() => setFreeUploadMode(true)}
          actionLabel={t("book.content.operations.createFree")}
          editable={editable}
        />
        {commUploadMode && (
          <UploadFile
            draftId={draftId}
            onClose={() => setCommUploadMode(false)}
          />
        )}
        {freeUploadMode && (
          <UploadFile
            draftId={draftId}
            onClose={() => setFreeUploadMode(false)}
            isFree={true}
          />
        )}
      </CardContent>
    </Card>
  );
}

function EpubLine({
  label,
  epub,
  link,
  action,
  actionLabel,
  deleteAction,
  editable,
}) {
  return (
    <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
      <Box flex={2}>
        <Typography variant={"body2"}>{label}</Typography>
      </Box>
      <Box flex={3}>
        {epub && epub.name && (
          <Chip
            color={"secondary"}
            icon={<MenuBook />}
            label={epub.name}
            style={{ marginBottom: 0 }}
          />
        )}
      </Box>
      <Box width={150}>
        {epub && !!epub.uploaded && (
          <Typography variant={"body2"}>
            {moment(epub.uploaded).format("DD/MM/YYYY HH:mm")}
          </Typography>
        )}
      </Box>
      <Box width={100}>
        {epub && !!epub.type && (
          <Typography variant={"body2"}>
            {epub.type.replace("application/", "")}
          </Typography>
        )}
      </Box>
      <Box width={60}>
        {epub && !!epub.size && (
          <Typography variant={"body2"}>{prettySize(epub.size)}</Typography>
        )}
      </Box>
      <Box width={100} textAlign={"right"}>
        {epub && (
          <Tooltip title={"generic.openFile"}>
            <IconButton component={Link} href={link} target={"_blank"}>
              <OpenInNew />
            </IconButton>
          </Tooltip>
        )}
        {deleteAction && (
          <Tooltip title={"generic.delete"}>
            <IconButton
              target={"_blank"}
              onClick={deleteAction}
              disabled={!editable}
            >
              <OpenInNew />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={actionLabel}>
          <IconButton
            variant={"contained"}
            onClick={action}
            disabled={!editable}
          >
            {epub ? <Edit /> : <Upload />}
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}

function UploadFile({ draftId, onClose, isFree }) {
  const { t, i18n } = useTranslation();

  const [draft, setDraft] = useState("");
  const fetchDraft = async () =>
    setDraft(await DataStore.query(Draft, draftId));
  useEffect(() => {
    if (draftId) {
      fetchDraft();
    }
  }, [draftId]);

  const [epubFile, setEpubFile] = useState("");
  const handleEpubFile = async (e) => setEpubFile(e.target.files[0]);

  const handleSave = async () => {
    if (epubFile) {
      const uuid = uuidv4();
      try {
        if (isFree) {
          const file = await Storage.put(uuid, epubFile, {
            level: "public",
            contentType: epubFile.type,
          });
          await DataStore.save(
            Draft.copyOf(draft, (updater) => {
              updater.freeEpub = JSON.stringify({
                default: epubFile.name,
                name: epubFile.name,
                key: file.key,
                size: epubFile.size,
                type: epubFile.type,
                object: file,
              });
              updater.epubFile = {
                region: awsExports.aws_user_files_s3_bucket_region,
                bucket: awsExports.aws_user_files_s3_bucket,
                key: file.key,
              };
            })
          );
        } else {
          const file = await Storage.put(uuid, epubFile, {
            level: "private",
            contentType: epubFile.type,
          });
          await DataStore.save(
            Draft.copyOf(draft, (updater) => {
              updater.epub = JSON.stringify({
                default: epubFile.name,
                name: epubFile.name,
                key: file.key,
                size: epubFile.size,
                type: epubFile.type,
                object: file,
              });
              updater.epubFile = {
                region: awsExports.aws_user_files_s3_bucket_region,
                bucket: awsExports.aws_user_files_s3_bucket,
                key: file.key,
              };
            })
          );
        }
        if (onClose) onClose();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const deleteEpub = async () => {
    const _delete = await Storage.remove(epubFile.key);
    await DataStore.save(
      Draft.copyOf(draft, (updater) => {
        updater.freeEpub = null;
        updater.epubFile = null;
      })
    );
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth={"md"} fullWidth>
      <DialogTitle sx={{ backgroundColor: "primary.main" }}>
        {t("book.content.operations.update-dialog")}
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <FieldSet title={t("book.content.fields.epub-file")}>
            <Typography variant={"body1"}>
              {isFree
                ? t("book.content.fields.freeEpub-file-helper")
                : t("book.content.fields.epub-file-helper")}
            </Typography>
            <Grid container flexDirection={"row"} mt={1}>
              <Grid>
                <label htmlFor="contained-button-file">
                  <Input
                    accept=".epub, .pdf"
                    id="contained-button-file"
                    type="file"
                    onChange={handleEpubFile}
                  />
                  <Button
                    variant="contained"
                    color={"secondary"}
                    component="span"
                    startIcon={<Upload />}
                    fullWidth
                  >
                    {epubFile
                      ? t("book.content.operations.file-change")
                      : t("book.content.operations.file-choose")}
                  </Button>
                </label>
              </Grid>
              {epubFile && (
                <Grid item flexGrow={1} p={1}>
                  {epubFile.name}
                </Grid>
              )}
              {epubFile && (
                <Grid item p={1}>
                  {prettySize(epubFile.size)}
                </Grid>
              )}
            </Grid>
          </FieldSet>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color={"secondary"} variant={"contained"}>
          {t("generic.cancel")}
        </Button>
        {isFree && (
          <Button color={"warning"} onClick={deleteEpub}>
            {t("book.content.operations.delete")}
          </Button>
        )}
        <Button startIcon={<Save />} variant={"contained"} onClick={handleSave}>
          {t("book.content.operations.upload")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const Input = styled("input")({
  display: "none",
});

const useStyles = makeStyles((theme) => ({
  btn: {
    marginRight: 10,
  },
}));
