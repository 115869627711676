import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DataStore} from "aws-amplify";
import {Category, Family, SubCategory} from "../../models";
import {tdb} from "../Services/translateJSON";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    FormControlLabel,
    Checkbox,
    MenuItem
} from "@mui/material";
import {Cancel, Save} from "@mui/icons-material";

export default function SubCategoryEdit({onClose, subCatId}) {
    const {t, in18} = useTranslation();
    const [subCategory, setSubCategory] = useState([]);
    const [families, setFamilies] = useState([])
    const [categories, setCategories] = useState([]);

    //FETCH
    const fetchSubCategory = async () => setSubCategory(await DataStore.query(SubCategory, subCatId))
    const fetchCategories = async () => setCategories(await DataStore.query(Category))
    const fetchFamilies = async () => setFamilies(await DataStore.query(Family))
    useEffect(()=>{
        fetchFamilies()
        fetchCategories()
        fetchSubCategory()
        const obsSubCategory = DataStore.observe(SubCategory, subCatId).subscribe(()=>fetchSubCategory())
        const obsCategories = DataStore.observe(Category).subscribe(()=>fetchCategories())
        const obsFamilies = DataStore.observe(Category).subscribe(()=>fetchFamilies())
        return () => {
            obsCategories.unsubscribe();
            obsFamilies.unsubscribe();
            obsSubCategory.unsubscribe()
        }
    }, [subCatId])

    //FORM
    const [form, setForm] = useState({
        title: "",
        name: "",
        description: "",
        content: "",
        family:"",
        category: "",
        active: false
    })
    useEffect(() => {
        if(subCategory) {
            setForm({
                name: subCategory.name,
                title: subCategory.title && tdb(subCategory.title.default),
                description: subCategory.description && tdb(subCategory.description.default),
                content: subCategory.content && tdb(subCategory.content.default),
                active: subCategory.active,
                family: subCategory.family && subCategory.family.id,
                category: subCategory.category && subCategory.category.id
            })
        }
    },[subCategory])

    //UPDATE
    const handleUpdate = async () => {
        try {
            const _subCategoryUpdate = SubCategory.copyOf(subCategory, updated => {
                    updated.name = (form.name)
                    updated.title = JSON.stringify({default: form.title})
                    updated.description = JSON.stringify({default: form.description})
                    updated.content = JSON.stringify({default: form.content})
                    updated.active = (form.active)
                    updated.familyID = form.family
                    updated.categoryID = form.category
            })
            await DataStore.save(_subCategoryUpdate)
            if (onClose) onClose()
        } catch (e) {
            console.log(e);
        }
    }

    return <Dialog open={true} onClose={onClose} fullWidth>
        <DialogTitle>{t("generic.Edit")}</DialogTitle>
        <DialogContent>
            {subCategory && form && <Grid container spacing={2}
                               component="form"
                               noValidate
                               autoComplete="off"
                               mt={1}
            >
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox
                            checked={!!form && form.active}
                            value={form && form.active}
                            onChange={event => setForm(state => ({...state, active: event.target.checked}))}
                        />}
                        label={t("generic.Activate")}
                    />
                </Grid>
                <Grid item xs={6}>
                    {!!families.find(f=>f.id===form.family) && <TextField
                        select
                        variant={"outlined"}
                        label={t('category.Family')}
                        fullWidth
                        value={form.family}
                        onChange={event => setForm(state => ({...state, family: event.target.value}))}
                    >
                        {families.map(f=>(<MenuItem key={f.id} value={f.id}>{tdb(f.title.default)}</MenuItem> ))}
                    </TextField>}
                </Grid>
                <Grid item xs={6}>
                    {categories.find(c=>c.id===form.category) && <TextField
                        select
                        variant={"outlined"}
                        label={t('category.Category')}
                        fullWidth
                        value={form && form.category}
                        onChange={event => setForm(state => ({...state, category: event.target.value}))}
                    >
                        {categories
                            .filter(c=>(form.family ? c.family.id === form.family : true))
                            .map(f=>(<MenuItem key={f.id} value={f.id}>{tdb(f.title.default)}</MenuItem> ))}
                    </TextField>}
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        value={form && form.title || ""}
                        label={t('generic.Title')}
                        placeholder={t('generic.Title')}
                        variant="outlined"
                        onChange={event => setForm(state => ({...state, title: event.target.value}))}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={t('generic.Name')}
                        variant="outlined"
                        value={form && form.name || ""}
                        onChange={event => setForm(state => ({...state, name: event.target.value}))}
                        InputProps={{pattern: "[a-z]{3,*}"}}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={t('family.Description')}
                        helperText={t('family.HelpDescription')}
                        variant="outlined"
                        multiline
                        rows={6}
                        value={form && form.description || ""}
                        onChange={event => setForm(state => ({...state, description: event.target.value}))}
                    />
    
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={t('family.Content')}
                        helperText={t('family.HelpContent')}
                        variant="outlined"
                        multiline
                        rows={6}
                        value={form && form.content || ""}
                        onChange={event => setForm(state => ({...state, content: event.target.value}))}
                    />
                </Grid>
            </Grid>}
        </DialogContent>
        <DialogActions>
            <Button startIcon={<Cancel />} variant="contained"onClick={onClose} color="secondary">{t("generic.Cancel")}</Button>
            <Button startIcon={<Save />} onClick={handleUpdate} variant="contained" color="primary">{t("generic.Update")}</Button>
        </DialogActions>
    </Dialog>
}