import {Avatar, Card, CardActions, CardContent, CardHeader, Grid, Typography} from "@mui/material";
import {DataStore} from "aws-amplify";
import {Purchase, ReaderUser, Refill} from "../../models";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import PurchaseList from "../Purchase/PurchaseList";
import {DataGrid} from "@mui/x-data-grid";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import {CreditCard, Delete, Edit, Money} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import ReaderUserEdit from "./ReaderUserEdit";
import LikeList from "../Like/LikeList";
import RatingList from "../Rating/RatingList";
import FollowList from "../Follow/FollowList";
import RefillList from "./ReaderRefill";

export default function ReaderUserDetail() {
    const {readerId} = useParams()
    const {t, i18n} = useTranslation();
    
    // reader
    const [reader, setReader] = useState();
    const fetchReader = async () => setReader(await DataStore.query(ReaderUser, readerId))
    
    useEffect(()=>{if (readerId) {
        fetchReader()
        const obsRU = DataStore.observe(ReaderUser, readerId).subscribe(msg=>fetchReader())
        return ()=>{
            obsRU.unsubscribe();
        }
    }}, [readerId])
    
    const [editMode, setEditMode] = useState();
    
    if (!reader)return <Typography>{readerId}</Typography>
    
    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <Card>
                <CardHeader
                    title={`${reader.firstName} ${reader.lastName}`}
                    action={<IconButton onClick={()=>setEditMode(true)}><Edit /></IconButton>}
                />
                <CardContent>
                    {editMode && <ReaderUserEdit readerId={readerId} onSuccess={()=>fetchReader()} onClose={()=>setEditMode(false)} />}
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <Grid container>
                                <DataLine label={t('reader.form.email')} value={reader && reader.email} />
                                <DataLine label={t('reader.form.gender')} value={reader && reader.gender} />
                                <DataLine label={t('reader.form.firstName')} value={reader && reader.firstName} />
                                <DataLine label={t('reader.form.lastName')} value={reader && reader.lastName} />
                                <DataLine label={t('reader.form.birthday')} value={reader && reader.birthday} />
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container>
                                <DataLine label={t('reader.form.phone')} value={reader && reader.phone} />
                                <DataLine label={t('reader.form.address')} value={reader && reader.address} />
                                <DataLine label={t('reader.form.zip')} value={reader && reader.zip} />
                                <DataLine label={t('reader.form.city')} value={reader && reader.city} />
                                <DataLine label={t('reader.form.country')} value={reader && reader.country} />
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container>
                                <DataLine label={t('reader.form.credits')} value={reader && reader.credits} cols={[8,4]} />
                                <DataLine label={t('reader.form.unsubscribe')} value={reader && reader.unsubscribe} cols={[8,4]} boolean={["YES", "NO"]} />
                                <DataLine label={t('reader.form.status')} value={reader && reader.status} cols={[8,4]} />
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12}>
            <PurchaseList readerId={readerId} />
        </Grid>
        <Grid item xs={12}>
            <LikeList readerId={readerId} />
        </Grid>
        <Grid item xs={12}>
            <RatingList readerId={readerId} />
        </Grid>
        <Grid item xs={12}>
            <FollowList readerId={readerId} />
        </Grid>
        <Grid item xs={12}>
            <RefillList readerId={readerId} />
        </Grid>
    </Grid>
}

function DataLine({label, value, cols = [4,8], noBorder=false, boolean=false}) {
    return <>
        <Grid item xs={cols[0]} borderTop={noBorder ? 0 : 1} borderColor={"#e0e0e0"}>
            <Typography fontSize={14} color={"#999"}>
                {label}
            </Typography>
        </Grid>
        <Grid item xs={cols[1]} borderTop={noBorder ? 0 : 1} borderColor={"#e0e0e0"}>
            <Typography variant={"body1"}>
                {boolean && !!value === value
                    ? (value ? boolean[0] : boolean[1])
                    : (value || "-")
                }
            </Typography>
        </Grid>
    </>
}
