import {useEffect, useState} from "react";
import {IconButton, TextField} from "@mui/material";
import {Close, Search} from "@mui/icons-material";

let timeout = false;
export default function SearchTextArea({className, callBack, placeholder, size="small", variant="outlined", delay=1000, initValue=""}) {
    const [value, setValue] = useState(initValue);
    
    const handleValue = (event) => {
        setValue(event.target.value)
    }
    
    const handleKey = (event) => {
        if (event.keyCode === 27) {
            clearTimeout(timeout)
            setValue("");
        }
    }
    
    useEffect(()=>{
        clearTimeout(timeout)
        timeout = window.setTimeout(()=>callBack(value.toLowerCase()), delay)
    }, [value])
    
    return <TextField
        variant={variant}
        size={size}
        value={value}
        placeholder={placeholder}
        onChange={handleValue}
        onKeyDown={handleKey}
        className={className}
        fullWidth
        InputProps={{
            style: {paddingRight: 0},
            startAdornment: <Search />,
            endAdornment: <IconButton size={"small"} onClick={()=>setValue("")}><Close /></IconButton>
        }}
    />
}