import React, {useEffect, useState} from "react";
import {
    Alert,
    Box, Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid, Link, Snackbar, Switch,
    TextField, Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DataStore, Logger} from "aws-amplify";
import {Family} from "../../models";
import {tdb} from "../Services/translateJSON"
import {Cancel, Save} from "@mui/icons-material";

export default function FamilyEdit({onClose, familyId}) {
    const {t, i18n} = useTranslation();
    const [error, setError] = useState()
    const [snackbarOpen, setSnackbarOpen] = useState(false)

    //FETCH
    const [family, setFamily] = useState([]);
    const fetchFamily = async () => {
        const _family = (await DataStore.query(Family, familyId))
        setFamily(_family)
    }
    useEffect(()=> {
     fetchFamily();
     const familySubscribe = DataStore.observe(Family).subscribe(()=>fetchFamily())
        return () => {
         familySubscribe.unsubscribe()
        }
    }, [familyId])


    //FORM
    const [form, setForm] = useState({
        title: "",
        name: "",
        description: "",
        content: "",
        active: false,
        appVersion: 1,
    })
    useEffect(() => {
        if(familyId) {
            setForm({
                name: family.name && family.name,
                title: family.title && tdb(family.title.default),
                description: family.description && tdb(family.description.default),
                content: family.content && tdb(family.content.default),
                active: family.active && family.active,
                appVersion: family.appVersion
            })
        } else {setForm(null)}
    }, [family, familyId])

    //UPDATE
    const handleUpdate = async () => {
        try {
            const _familyUpdate = Family.copyOf(family, updated => {
                updated.name = (form.name);
                updated.title = JSON.stringify({default:form.title});
                updated.description = JSON.stringify({default:form.description});
                updated.content = JSON.stringify({default:form.content});
                updated.active = (form.active);
                if (enableVersion)updated.appVersion = parseInt(form.appVersion);
            })
            await DataStore.save(_familyUpdate)
            if (onClose)onClose();
        }
        catch (e) {
            console.log(e);
            setError(e)
            setSnackbarOpen(true)
        }

    }
    
    const [enableName, setEnableName] = useState();
    const [enableVersion, setEnableVersion] = useState(false);

    return <Dialog open={true} onClose={onClose} fullWidth>
        <DialogTitle>{t("generic.Edit")}</DialogTitle>
        <DialogContent>
            <Grid container spacing={2}
                  component="form"
                  noValidate
                  autoComplete="off"
            >
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Switch
                            checked={!!form && form.active}
                            value={Boolean(form && form.active)}
                            onChange={event => setForm(state => ({...state, active: event.target.checked}))}
                        />}
                        label={t("generic.Activate")}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        value={form && form.title || ""}
                        label={t('generic.Title')}
                        variant="outlined"
                        onChange={event => setForm(state => ({...state, title: event.target.value}))}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={t('generic.Name')}
                        disabled={!enableName}
                        variant="outlined"
                        value={form && form.name || ""}
                        onChange={event => setForm(state => ({...state, name: event.target.value}))}
                        />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={t('family.Description')}
                        helperText={t('family.HelpDescription')}
                        variant="outlined"
                        multiline
                        rows={6}
                        value={form && form.description || ""}
                        onChange={event => setForm(state => ({...state, description: event.target.value}))}
                    />

                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={t('family.Content')}
                        helperText={t('family.HelpContent')}
                        variant="outlined"
                        multiline
                        rows={6}
                        value={form && form.content || ""}
                        onChange={event => setForm(state => ({...state, content: event.target.value}))}
                    />
                </Grid>
                {family.name === "audio" && <Grid item xs={12} alignItems={"center"} display={"flex"} justifyContent={"space-between"}>
                    <TextField
                        style={{width: 120}}
                        label={t('family.appVersion')}
                        type={"integer"}
                        variant="outlined"
                        value={form && form.appVersion || ""}
                        onChange={event => setForm(state => ({...state, appVersion: event.target.value}))}
                        disabled={!enableVersion}
                    />
                    <Box ml={2}>
                        <Typography variant={"caption"}>
                            {t('family.appVersionHelper')}
                            <Link onClick={()=>setEnableVersion(true)} ml={1}>{t('family.appVersionActivate')}</Link>
                        </Typography>
                    </Box>
                </Grid>}
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button startIcon={<Cancel />} variant="contained"onClick={onClose} color="secondary">{t("generic.Cancel")}</Button>
            <Button startIcon={<Save />} onClick={handleUpdate} variant="contained" color="primary">{t("generic.Update")}</Button>
        </DialogActions>
        <Snackbar open={snackbarOpen} onClose={onClose}>
            <Alert onClose={onClose} severity="error" sx={{ width: '100%' }}>
                {error}
            </Alert>
        </Snackbar>
    </Dialog>
}