import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
import { ReaderUser, Refill } from "../../models";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import { CreditCard, Delete, Visibility } from "@mui/icons-material";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export default function RefillList({}) {
  const { readerId } = useParams();
  const { t, i18n } = useTranslation();

  // reader
  const [reader, setReader] = useState();
  const fetchReader = async () =>
    setReader(await DataStore.query(ReaderUser, readerId));

  // refills
  const [refills, setRefills] = useState([]);
  const fetchRefills = async () => {
    setRefills(
      (await DataStore.query(Refill)).filter(
        (rf) => rf.readerUser.id === readerId
      )
    );
  };

  useEffect(() => {
    if (readerId) {
      // reader
      fetchReader();
      const obsRU = DataStore.observe(ReaderUser, readerId).subscribe((msg) =>
        fetchReader()
      );
      // refills
      fetchRefills();
      const obsRF = DataStore.observe(Refill).subscribe((msg) => {
        if (
          msg.element.readerUserID === readerId ||
          (msg.element.readerUser && msg.element.readerUser.id === readerId)
        )
          fetchRefills();
      });
      // unobs
      return () => {
        obsRU.unsubscribe();
        obsRF.unsubscribe();
      };
    }
  }, [readerId]);

  const [opened, setOpened] = useState();
  const _actions = (params) => {
    return (
      <>
        <IconButton onClick={() => setOpened(params.row.id)}>
          <Visibility />
        </IconButton>
        {[null, "Failed", "Pending"].includes(params.row.status) && (
          <IconButton onClick={() => handleDelete(params.row)}>
            <Delete />
          </IconButton>
        )}
      </>
    );
  };

  const refillCols = [
    { field: "id", headerName: "ID", width: 300 },
    { field: "_version", headerName: "v", width: 40 },
    {
      field: "_deleted",
      headerName: "d",
      width: 40,
      renderCell: (params) => (params.value ? "x" : ""),
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      renderCell: (params) => moment(params.value).format("DD/MM/YYYY HH:mm"),
    },
    { field: "amount", headerName: t("amount"), width: 80 },
    {
      field: "status",
      headerName: t(`refill.status`),
      width: 120,
      renderCell: (params) => t(`refill.statuses.${params.value}`),
    },
    {
      field: "transfered",
      headerName: t("transfered"),
      width: 120,
      renderCell: (params) => (params.value ? "yes" : "no"),
    },
    { field: "action", headerName: " ", width: 100, renderCell: _actions },
  ];

  useEffect(() => console.log("refills", refills), [refills]);

  const handleDelete = async (refill) => {
    await DataStore.delete(refill);
  };

  if (!reader) return <Typography>{readerId}</Typography>;

  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar>
            <CreditCard />
          </Avatar>
        }
        title={<Typography variant={"h6"}>{t("refill.refillList")}</Typography>}
      />
      <CardContent>
        <DataGrid
          columns={refillCols}
          rows={refills}
          autoHeight
          hideFooter
          density={"compact"}
        />
        {opened && (
          <RefillDetail refillId={opened} onClose={() => setOpened(null)} />
        )}
      </CardContent>
    </Card>
  );
}

function RefillDetail({ refillId, onClose }) {
  const [refill, setRefill] = useState();
  const fetchRefill = async () =>
    setRefill(await DataStore.query(Refill, refillId));
  useEffect(() => {
    if (refillId) {
      fetchRefill();
      const obsRefill = DataStore.observe(Refill, refillId).subscribe(
        (msg) => fetchRefill
      );
      return () => obsRefill.unsubscribe();
    }
  }, [refillId]);
  return (
    <Dialog open={true} onClose={onClose} maxWidth={"md"} fullWidth>
      <DialogTitle>Refill</DialogTitle>
      <DialogContent>
        {
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <strong>ID :</strong> {refill && refill.id}
            </Grid>
            <Grid item xs={6}>
              <strong>Status</strong> : {refill && refill.status}
            </Grid>
            <Grid item xs={6}>
              <strong>Created</strong> :{" "}
              {refill && moment(refill.createdAt).format("llll")}
            </Grid>
            <Grid item xs={6}>
              <strong>Updated</strong> :{" "}
              {refill && moment(refill.updatedAt).format("llll")}
            </Grid>
            <Grid item xs={12}>
              <strong>Payload</strong>
              <pre style={{ fontSize: 10 }}>
                {refill && JSON.stringify(refill.payload, null, 2)}
              </pre>
            </Grid>
            <Grid item xs={12}>
              <strong>Response</strong>
              <pre style={{ fontSize: 10 }}>
                {refill && JSON.stringify(refill.response, null, 2)}
              </pre>
            </Grid>
          </Grid>
        }
      </DialogContent>
    </Dialog>
  );
}
