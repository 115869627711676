import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DataStore} from "aws-amplify";
import {AuthorUser, Book, ReaderUser} from "../../models";
import moment from "moment";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


export default function ChartUser({cumulative = false}) {
    const {t, i18n} = useTranslation();
    
    const [authorUsers, setAuthorUsers] = useState([]);
    const [readerUsers, setReaderUsers] = useState([]);
    const [books, setBooks] = useState();

    const fetchAuthorUsers = async ()=> {setAuthorUsers(await DataStore.query(AuthorUser))}
    const fetchReaderUsers = async ()=> {setReaderUsers(await DataStore.query(ReaderUser))}
    
    useEffect(()=>{
        fetchAuthorUsers();
        fetchReaderUsers()
        const obsAuthors = DataStore.observe(AuthorUser).subscribe(()=>fetchAuthorUsers())
        const obsReaders = DataStore.observe(ReaderUser).subscribe(()=>fetchAuthorUsers())
        return () => {
            obsAuthors.unsubscribe();
            obsReaders.unsubscribe()
        }
    }, [])


    const [labels, setLabels] = useState([]);
    useEffect(()=>{
        const start = moment().subtract(1, 'year');
        const _labels = []
        for (let i=12; i>=0;i--) {
            _labels.push(({
                value: moment().subtract(i, 'months').format('YYYYMM'),
                label: moment().subtract(i, 'months').format('MM/YY')
            }))
        }
        
        setLabels(_labels);
        
    }, [])

    return (
        <Line
            type={"line"}
            options={{
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top'
                    },
                    title: {
                        display: true,
                        text: cumulative
                            ? `${t('chart.userPerMonthCumulative')}`
                            : `${t('chart.userPerMonth')}`
                        ,
                    },
                },
            }}
            data={{
                labels : labels.map(l=>l.label),
                datasets: [
                    {
                        label: 'Auteurs',
                        data: labels.map(l => authorUsers
                            .filter(user => cumulative
                                ? moment(user.createdAt).format('YYYYMM') <= l.value
                                : moment(user.createdAt).format('YYYYMM') === l.value
                            )
                            .length
                        ),
                        borderColor: 'rgb(99,112,255,1)',
                        backgroundColor: 'rgba(99,112,255,0.5)',
                    },
                    {
                        label: 'Readers',
                        data: labels.map(l => readerUsers
                            .filter(user => cumulative
                                ? moment(user.createdAt).format('YYYYMM') <= l.value
                                : moment(user.createdAt).format('YYYYMM') === l.value
                            )
                            .length
                        ),
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    }]
            }}
        />
    )
}