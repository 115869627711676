import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataStore } from "aws-amplify";
import { Book, Category, Family } from "../../models";
import { DataGrid } from "@mui/x-data-grid";
import {
  AddCircle,
  DeleteForever,
  Edit,
  VisibilitySharp,
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CategoryCreate from "./CategoryCreate";
import { tdb } from "../Services/translateJSON";
import CategoryEdit from "./CategoryEdit";
import CategoryDelete from "./CategoryDelete";

export default function CategoryList() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  //MODE
  const [createMode, setCreateMode] = useState(false);
  const handleCreate = () => setCreateMode(true);
  const handleCancelCreate = () => setCreateMode(false);

  const [editMode, setEditMode] = useState(false);
  const [editCat, setEditCat] = useState("");
  const handleEdit = (id) => {
    setEditCat(id);
    setEditMode(true);
  };
  const handleCancelEdit = () => {
    setEditCat(null);
    setEditMode(false);
  };

  const [deleteCat, setDeleteCat] = useState(false);
  const [catId, setCatId] = useState("");

  const handleDelete = (id) => {
    setCatId(id);
    setDeleteCat(true);
  };

  // BOOKS
  const [books, setBooks] = useState([]);
  const fetchBooks = async () => setBooks(await DataStore.query(Book));
  // FAMILIES
  const [families, setFamilies] = useState([]);
  const fetchFamilies = async () => setFamilies(await DataStore.query(Family));
  //CATEGORIES
  const [categories, setCategories] = useState([]);
  const fetchCategories = async () =>
    setCategories(await DataStore.query(Category));
  useEffect(() => {
    fetchBooks();
    fetchFamilies();
    fetchCategories();
    const categoriesSubscribes = DataStore.observe(Category).subscribe(() =>
      fetchCategories()
    );
    return () => {
      categoriesSubscribes.unsubscribe();
    };
  }, []);

  //GRID PARAMS
  const _renderActions = (params) => {
    return (
      (1 || params.row === 0) && (
        <>
          <IconButton size={"small"} onClick={() => handleEdit(params.row.id)}>
            <Edit />
          </IconButton>
          <IconButton
            size={"small"}
            onClick={() => handleDelete(params.row.id)}
          >
            <DeleteForever />
          </IconButton>
        </>
      )
    );
  };

  const _renderBooks = (params) => {
    return (
      books.filter((book) => book.category.id === params.row.id).length || ""
    );
  };

  const [family, setFamily] = useState("none");
  useEffect(() => {
    setRows(
      categories.filter((c) =>
        family !== "none" ? c.family.id === family : true
      )
    );
  }, [categories, family]);

  const columns = [
    { field: "id", headerName: "id", flex: 1, hide: true },
    {
      field: "title",
      headerName: "titre",
      flex: 1,
      renderCell: (params) => tdb(params.value.default),
    },
    {
      field: "description",
      headerName: "description",
      flex: 1,
      hide: true,
      renderCell: (params) => tdb(params.value),
    },
    {
      field: "family",
      headerName: "famille",
      flex: 1,
      renderCell: (params) => tdb(params.row.family.title),
    },
    {
      field: "order",
      headerName: "order",
      width: 70,
      editable: family !== "none",
    },
    {
      field: "books",
      headerName: "Books",
      width: 70,
      renderCell: _renderBooks,
    },
    {
      field: "active",
      headerName: "Activé",
      width: 70,
      renderCell: (params) =>
        params.row.active === true
          ? `${t("generic.Yes")}`
          : `${t("generic.No")}`,
    },
    {
      field: "action",
      headerName: "Actions",
      width: 100,
      align: "right",
      renderCell: _renderActions,
      disableColumnMenu: true,
    },
  ];

  const [rows, setRows] = useState([]);

  const _editOrder = async (params, event) => {
    await DataStore.save(
      Category.copyOf(params.row, (updater) => {
        updater[params.field] = parseInt(event.target.value);
      })
    );
  };

  return (
    <Card>
      <CardHeader
        title={<Typography variant={"h5"}>{t("category.List")}</Typography>}
        action={
          <TextField
            variant={"outlined"}
            label={"famille"}
            size={"small"}
            value={family}
            onChange={(event) => setFamily(event.target.value)}
            select
          >
            <MenuItem value={"none"}>{t("category.AllFamilies")}</MenuItem>
            {families.map((family) => (
              <MenuItem key={family.id} value={family.id}>
                {tdb(family.title)}
              </MenuItem>
            ))}
          </TextField>
        }
      />
      <CardContent>
        <DataGrid
          density={"compact"}
          autoHeight
          rows={rows}
          columns={columns}
          onCellEditStop={_editOrder}
        />
      </CardContent>
      <CardActions>
        <Button
          startIcon={<AddCircle />}
          className={classes.mt}
          variant="contained"
          onClick={() => setCreateMode(true)}
        >
          {t("generic.Add")}{" "}
        </Button>
        {createMode && <CategoryCreate onClose={() => setCreateMode(false)} />}

        <CategoryEdit
          open={editMode}
          onClose={handleCancelEdit}
          catId={editCat}
        />
        <CategoryDelete
          open={deleteCat}
          onClose={() => setDeleteCat(false)}
          id={catId}
        />
      </CardActions>
    </Card>
  );
}

const useStyles = makeStyles(() => ({
  mt: {
    marginTop: 10,
  },
}));
